import { motion } from 'framer-motion';
import styled from 'styled-components';

import { screenBackgroundCss } from 'theme/css';

const Background = styled(motion.div)`
  ${screenBackgroundCss}
  display: flex;
  position: relative;
`;

export default Background;
