import styled from 'styled-components';
import { TranslationKey } from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
`;

interface Props {
  titleTranslationKey?: TranslationKey;
  messageTranslationKey?: TranslationKey;
}

export default function ConnectWalletHeader({
  titleTranslationKey = 'wallet.connect.title',
  messageTranslationKey = 'wallet.connect.description',
}: Props): JSX.Element {
  return (
    <Header>
      <SmallModal.Title translationKey={titleTranslationKey} />
      <SmallModal.Message translationKey={messageTranslationKey} />
    </Header>
  );
}
