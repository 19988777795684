import { useSetAtom } from 'jotai';
import styled from 'styled-components';
import Button from 'components/Button';
import Header from 'components/Header';
import { coinImage, bloomTealGreenBigImage, starImage } from 'components/images';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { DisclaimerSection } from 'components/wallet';
import { NAVIGATION_TAB_BAR_HEIGHT } from 'constants/dimension';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import NavigationTabBar from '../navigation/NavigationTabBar';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { color } }) => color.pageBackground};
  position: relative;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Main = styled.main`
  position: relative;
  flex: 1;
  padding-bottom: calc(${NAVIGATION_TAB_BAR_HEIGHT}px + var(--sab));
  color: ${({ theme: { color, key } }) => (key === 'default' ? color.onPage : color.onPage)};
`;

const AuthenticationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  padding-top: 172px;
  position: relative;
  overflow: hidden;
`;

const AuthenticateCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 88px;
  padding-bottom: 36px;
`;

const LoginButton = styled(Button).attrs({ variant: 'secondary' })`
  margin-top: 32px;
`;

const BigBloomImg = styled.img.attrs({ src: bloomTealGreenBigImage })`
  position: absolute;
  width: 690px;
  height: 690px;
  top: 12px;
  clip: rect(0, 690px, 345px, 0);
`;

const SmallBloomImg = styled(BigBloomImg)`
  transform: scale(0.75);
`;

const CoinImg = styled.img.attrs({ src: coinImage })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
`;

const BigStarImg = styled.img.attrs({ src: starImage })`
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(60px);
  right: 0;
`;

const SmallStarImg = styled.img.attrs({ src: starImage })`
  position: absolute;
  top: 176px;
  left: 50%;
  transform: translateX(-120px);
  height: 24px;
  width: 24px;
`;

export interface Props {
  children: React.ReactNode;
  hasAuthenticationBlocker?: boolean;
  noHeader?: boolean;
  noScanner?: boolean;
}

export default function Page(props: Props): JSX.Element {
  const { children, hasAuthenticationBlocker, noHeader, noScanner } = props;
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const { isAuthenticated } = useIsAuthenticated();

  return (
    <PageContainer>
      {!noHeader && <Header noScanner={noScanner} />}
      <Main>
        {!isAuthenticated && hasAuthenticationBlocker ? (
          <AuthenticationContainer>
            <SmallBloomImg />
            <BigBloomImg />
            <AuthenticateCard>
              <LocalizedText category="h1" variant="secondary" translationKey="wallet.connect.title" />
              <LocalizedText category="body2" color="tinted" translationKey="wallet.connect.description" />
              <LoginButton onClick={() => setIsConnectWalletModalOpen(true)}>
                <LocalizedText category="body1" translationKey="wallet.connect.button-login" />
              </LoginButton>
            </AuthenticateCard>
            <SmallStarImg />
            <BigStarImg />
            <CoinImg />
            <DisclaimerSection />
          </AuthenticationContainer>
        ) : (
          children
        )}
      </Main>
      <NavigationTabBar />
    </PageContainer>
  );
}
