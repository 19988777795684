import { CoffeeDao } from '@adam-vault/adam-sdk-coffeedao';
import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

export const coffeeShopsAtom = loadable(
  atom(async () => {
    const coffeeShops = await CoffeeDao.getJoinedShops(process.env.REACT_APP_ENV, 32, 0);
    return coffeeShops.merchants;
  })
);
