import Button from 'components/Button';
import { TokenAmountCard } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import useWelcomeRewardDetail from 'hooks/useWelcomeRewardDetail';
import { gaClassName } from 'utils/gtmEventUtils';

interface Props {
  onComplete: () => void;
}

export default function SuccessBlock({ onComplete }: Props): JSX.Element {
  const { welcomeRewardAmount } = useWelcomeRewardDetail();
  return (
    <ModalCard image="success" align="center">
      <ModalCard.SuccessTitle translationKey="welcome-reward.success.title" />
      <TokenAmountCard amount={welcomeRewardAmount} translationKey="welcome-reward.success.subtitle" />
      <ModalCard.Message translationKey="welcome-reward.success.description" />
      <ModalCard.Action>
        <Button variant="secondary" onClick={onComplete} className={gaClassName('claim-modal-complete')}>
          <LocalizedText category="body1" weight="medium" translationKey="welcome-reward.success.button-escape" />
        </Button>
      </ModalCard.Action>
    </ModalCard>
  );
}
