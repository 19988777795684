import React from 'react';
import styled, { css } from 'styled-components';
import { buttonLikeShadowCss } from 'theme/css';
import Icon from './Icon';

export interface Props extends React.ComponentPropsWithoutRef<'button'> {
  className?: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'text';
  icon?: string;
  iconSize?: string;
  iconColor?: string;
  iconFirst?: boolean;
  hasBoxShadow?: boolean;
  minimize?: boolean;
}

interface ButtonWrapperProps
  extends React.ComponentPropsWithoutRef<'button'>,
    Pick<Props, 'variant' | 'hasBoxShadow' | 'minimize'> {
  className?: string;
}

export const buttonCss = css<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: ${({ theme: { key } }) => (key === 'default' ? 'max-content' : '100%')};
  padding: ${({ theme: { key } }) => (key === 'default' ? '12px 24px' : '16px 24px')};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  column-gap: 8px;

  ${({ minimize }) =>
    minimize &&
    css`
      padding: 12px 16px;
    `}

  ${({ variant, hasBoxShadow, theme: { color, key } }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${key === 'default' ? color.primary : 'transparent'};
          border: 1px solid ${key === 'default' ? color.buttonBorder : color.buttonPrimary};
          color: ${key === 'default' ? color.secondary : color.buttonPrimary};
          border-radius: 40px;

          ${key === 'default' && hasBoxShadow && buttonLikeShadowCss}

          &:disabled {
            opacity: 0.4;
          }
        `;
      case 'secondary':
        return css`
          background-color: ${key === 'default' ? color.secondary : 'transparent'};
          border: 1px solid ${key === 'default' ? color.buttonBorder : color.buttonSecondary};
          color: ${key === 'default' ? color.onSecondary : color.buttonSecondary};
          border-radius: 40px;

          ${key === 'default' && hasBoxShadow && buttonLikeShadowCss}

          &:disabled {
            opacity: 0.4;
          }
        `;
      case 'text':
        return css`
          display: block;
          width: max-content;
          color: ${color.onPage};
          height: min-content;
          padding: 0;

          &:active,
          &:focus {
            color: ${color.fontPrimaryLightest};
          }
        `;
      default:
        return css``;
    }
  }}
`;

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${buttonCss}
`;

export default function Button({
  children,
  type = 'button',
  variant = 'primary',
  disabled,
  icon,
  iconSize,
  iconColor,
  iconFirst,
  hasBoxShadow = true,
  ...otherProps
}: Props): JSX.Element {
  return (
    <ButtonWrapper type={type} variant={variant} hasBoxShadow={hasBoxShadow} {...otherProps} disabled={disabled}>
      {icon && iconFirst && <Icon icon={icon} size={iconSize} color={iconColor} />}
      {children}
      {icon && !iconFirst && <Icon icon={icon} size={iconSize} color={iconColor} />}
    </ButtonWrapper>
  );
}
