import { DefaultTheme } from 'styled-components';

const palette: DefaultTheme['palette'] = {
  // Primary color
  tealGreen: '#1E5663',
  tealGreen01: '#EAF3F5',
  // Secondary color
  floralWhite: '#EAE1DA',
  floral: '#C3AB99',
  floral06: '#6D6056',
  // Tertiary Color
  mustardYellow: '#DAB840',
  lavender: '#6B3381',
  burntOrange: '#B24E3D',
  // Grey
  black: '#1A1A1A',
  pureBlack: '#000000',
  white: '#F1F1F1',
  pureWhite: '#FFFFFF',
  normal: '#565656',
  grey: '#DDD',
  // Other Color
  lightFloralWhite: '#F1ECE8',
  darkFloralWhite: '#DDCEC3',
  lightTealGreen: '#3B6E79',
  lightestTealGreen: '#538591',
  greyWhite: '#FEFEFE',
  grey02: '#F5F5F5',
  grey05: '#9C9C9C',
};

export default palette;
