import { ControlledInput, ControlledInputProps, StyledControlledInput } from '@adam-vault/adam-frontend-shared';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import Icon from 'components/Icon';
import LocalizedText, { TranslationKey } from 'components/LocalizedText';
import { TextVariant } from 'components/Text';

export interface Props<TFieldValues extends FieldValues> extends ControlledInputProps<TFieldValues> {
  label: TranslationKey;
  variant: TextVariant;
}

const LabelContainer = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxDisplay = styled.div<{ variant: TextVariant }>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  flex: 0 0 32px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};
  display: grid;
  place-items: center;
`;

const Checkmark = styled(Icon)`
  opacity: 0;
  transition: opacity 0.15s;
`;

const CheckboxInput = styled(ControlledInput)`
  &:checked + ${CheckboxDisplay} ${Checkmark} {
    opacity: 1;
  }
` as StyledControlledInput;

export default function Checkbox<TFieldValues extends FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { className, label, variant, ...inputProps } = props;

  return (
    <LabelContainer className={className}>
      <CheckboxInput {...inputProps} type="checkbox" hidden />
      <CheckboxDisplay variant={variant}>
        <Checkmark icon="check" size="28px" />
      </CheckboxDisplay>
      <LocalizedText translationKey={label} variant={variant} category="caption" />
      {/* <StyledInput hasError={!!errorMessage} {...inputProps} /> */}
    </LabelContainer>
  );
}
