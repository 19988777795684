import styled from 'styled-components';
import { skeletonCss } from 'components/Skeleton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -40px;
`;

const MainSkeleton = styled.div`
  width: 100%;
  height: 420px;
  border-radius: 24px;
  margin-bottom: 24px;
  ${skeletonCss}
`;

const CoinSkeleton = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100px;
  transform: translateY(70px);
  z-index: 2;
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.1);
  ${skeletonCss}
`;

export default function LoadingBlock(): JSX.Element {
  return (
    <Container>
      <CoinSkeleton />
      <MainSkeleton />
    </Container>
  );
}
