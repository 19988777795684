import styled from 'styled-components';
import { pseudoBackgroundCSS, starImage } from 'components/images';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const WelcomeCardContainer = styled(Card)<{ disabled?: boolean }>`
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  gap: 6px;

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const LoginText = styled(LocalizedText).attrs({ category: 'h1', variant: 'secondary' })`
  letter-spacing: -0.02em;
`;

const SloganText = styled(LocalizedText).attrs({ category: 'body2', variant: 'secondary' })`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &::before,
  &::after {
    content: '';
    ${pseudoBackgroundCSS(starImage, '12px', '12px')}
  }
`;

export default function WelcomeCard({ disabled, onClick }: Props): JSX.Element {
  return (
    <WelcomeCardContainer disabled={disabled} onClick={onClick} className={gaClassName('home-page-login')} as="button">
      <LoginText translationKey="home.welcoming.title" />
      <SloganText translationKey="home.welcoming.description" />
    </WelcomeCardContainer>
  );
}
