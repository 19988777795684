import { TransactionRecord } from '@adam-vault/adam-sdk-coffeedao';
import { format } from 'date-fns';
import React, { useState } from 'react';
import styled from 'styled-components';
import imageNoRecord from 'assets/images/image_no-record.svg';
import LocalizedText from 'components/LocalizedText';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import TransactionDetailModal from './TransactionDetailModal';
import TransactionListItem from './TransactionListItem';

const Container = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ compact }) => (compact ? '4px' : '8px')};
`;

const NoRecordContainer = styled(Container)`
  margin-top: 80px;
  gap: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NoRecordImage = styled.img.attrs({ src: imageNoRecord })`
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
`;

const LoadingSkeleton = styled(Skeleton).attrs({ height: '64px', width: '100%' })`
  border-radius: 24px;
`;

interface Props {
  records: TransactionRecord[];
  isLoading?: boolean;
  isTimeSeparated?: boolean;
}

export default function TransactionList({ isTimeSeparated, records, isLoading }: Props): JSX.Element {
  const [selectedTransactionRecord, setSelectedTransactionRecord] = useState<TransactionRecord>();

  const onSelectTransactionRecord = (record: TransactionRecord): void => {
    setSelectedTransactionRecord(record);
  };

  const onCloseTransactionRecordDetail = (): void => {
    setSelectedTransactionRecord(undefined);
  };

  if (isLoading) {
    return (
      <Container>
        {Array.from({ length: 5 }).map((_, index) => (
          <LoadingSkeleton key={index} />
        ))}
      </Container>
    );
  }

  if (!records.length) {
    return (
      <NoRecordContainer>
        <NoRecordImage />
        <LocalizedText category="h3" weight="medium" color="primary" translationKey="transactions.empty.title" />
        <LocalizedText category="body1" color="primary" translationKey="transactions.empty.description" />
      </NoRecordContainer>
    );
  }

  if (isTimeSeparated) {
    const separatedRecords = records.reduce<{ [key: string]: TransactionRecord[] }>((separated, record) => {
      const key = `${record.createdAt.getFullYear()}-${record.createdAt.getMonth() + 1}`;
      if (!separated[key]) {
        separated[key] = [];
      }
      separated[key].push(record);
      return separated;
    }, {});

    return (
      <Container compact={isTimeSeparated}>
        {Object.entries(separatedRecords).map(([month, monthlyRecords]) => (
          <React.Fragment key={month}>
            <Text category="body1" color="caption" weight="medium">
              {format(new Date(month), 'MMMM yyyy')}
            </Text>
            {monthlyRecords.map((record) => (
              <TransactionListItem
                key={record.transactionHash}
                record={record}
                onClickRecord={() => onSelectTransactionRecord(record)}
              />
            ))}
          </React.Fragment>
        ))}
        {selectedTransactionRecord && (
          <TransactionDetailModal record={selectedTransactionRecord} onCloseModal={onCloseTransactionRecordDetail} />
        )}
      </Container>
    );
  }

  return (
    <Container>
      {records.map((record) => (
        <TransactionListItem
          key={record.transactionHash}
          record={record}
          onClickRecord={() => onSelectTransactionRecord(record)}
        />
      ))}
      {selectedTransactionRecord && (
        <TransactionDetailModal record={selectedTransactionRecord} onCloseModal={onCloseTransactionRecordDetail} />
      )}
    </Container>
  );
}
