// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Navigate, useLocation } from 'react-router-dom';
import { Page } from 'components/layout';
import { isLanguageKey } from 'constants/languages';

export default function NoMatch(): JSX.Element {
  const { pathname } = useLocation();

  // Check for duplicate language key in path
  const pathComponents = pathname.split('/');
  if (pathComponents.filter((s) => isLanguageKey(s)).length > 1) {
    const newPath = pathComponents.filter((s, index, arr) => !isLanguageKey(s) || index === arr.indexOf(s)).join('/');
    return <Navigate to={newPath} replace />;
  }

  return <Page noScanner>No match</Page>;
}
