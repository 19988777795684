import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import CoffeeShopListModal from 'components/CoffeeShopListModal';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import Skeleton from 'components/Skeleton';
import Text from 'components/Text';
import { URL_SPEND_DETAILS } from 'constants/url';
import useCoffeeShops from 'hooks/useCoffeeShopList';
import useLanguage from 'hooks/useLanguage';
import { gaClassName } from 'utils/gtmEventUtils';
import { SectionContainer, SectionTitle, SectionDescription, SectionPadding } from './SectionContainer';

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ShopContainer = styled.div`
  display: flex;
  gap: 8px;
  width: calc(100% + ${SectionPadding} * 2);
  padding: 0 ${SectionPadding};
  margin: 0 -${SectionPadding};
  overflow-x: auto;
  margin-top: 4px;
`;

const ShopCard = styled(Link)`
  width: 80px;
  flex: 1 0 80px;
  text-decoration: none;
  text-align: left;
`;

const ShopImg = styled(Card).attrs<{ src: string }>(({ src }) => ({ children: <img src={src} /> }))<{ src: string }>`
  aspect-ratio: 1;
  padding: 0;
  margin-bottom: 8px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  background-color: ${({ theme: { color } }) => color.modalBackground};

  img {
    width: 80%;
  }
`;

const ShopTitle = styled(Text).attrs({ category: 'caption', weight: 'medium' })``;

const ShopAddress = styled(Text).attrs({ category: 'caption', variant: 'primary' })`
  opacity: 0.3;
`;

const SpendDetailLink = styled(Link)`
  text-align: center;
  margin: 16px auto;
  text-decoration: underline;
  text-underline-position: under;
`;

const SeeAllButton = styled(Button)`
  padding: 8px 24px;
  position: absolute;
  top: 0;
  right: ${SectionPadding};
`;

const ShopSkeletonContainer = styled.div`
  width: 80px;
  flex: 1 0 80px;
  text-decoration: none;
  text-align: left;
`;

function CoffeeShopSkeleton(): JSX.Element {
  return (
    <ShopSkeletonContainer>
      <Skeleton height="80px" width="80px" />
      <Skeleton height="16px" width="80px" />
      <Skeleton height="16px" width="80px" />
    </ShopSkeletonContainer>
  );
}

export default function CoffeeShopsSection(): JSX.Element | null {
  const [isCoffeeShopListModalOpen, setIsCoffeeShopListModalOpen] = useState(false);
  const { isLoading, coffeeShops } = useCoffeeShops();
  const { language } = useLanguage();

  const onClickCoffeeShop =
    (shop: CoffeeShop): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      window.open(shop.href, '_blank');
    };

  return (
    <SectionContainer>
      {!isLoading && (
        <SeeAllButton
          variant="secondary"
          minimize
          onClick={() => setIsCoffeeShopListModalOpen(true)}
          className={gaClassName('home-page-shops-see-all')}
        >
          <LocalizedText category="body1" translationKey="home.shops.button-see-all" />
        </SeeAllButton>
      )}
      <SectionHeader>
        <SectionTitle translationKey="home.shops.title" />
        <SectionDescription translationKey="home.shops.description" />
      </SectionHeader>
      <ShopContainer>
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => <CoffeeShopSkeleton key={`shop-skeleton-${index}`} />)
          : coffeeShops.map((shop, index) => (
              <ShopCard
                key={`${shop.name}-${index}`}
                to={shop.href || ''}
                target="_blank"
                className={gaClassName(`home-page-shops-${shop.name}`)}
              >
                <ShopImg src={shop.logoImageURL} />
                <ShopTitle>{shop.name}</ShopTitle>
                <ShopAddress>{language === 'zh' ? shop.zhAddress : shop.enAddress}</ShopAddress>
              </ShopCard>
            ))}
      </ShopContainer>
      <SpendDetailLink to={URL_SPEND_DETAILS} target="_blank" className={gaClassName('home-page-shops-details')}>
        <LocalizedText category="caption" translationKey="home.shops.button-detail" />
      </SpendDetailLink>
      <CoffeeShopListModal
        isOpen={isCoffeeShopListModalOpen}
        onRequestClose={() => setIsCoffeeShopListModalOpen(false)}
        onClickCoffeeShop={onClickCoffeeShop}
        region="hongKong"
      />
    </SectionContainer>
  );
}
