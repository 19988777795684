import {
  useWindowSize,
  ControlledInputProps,
  ControlledFieldPathValue,
  ControlledInput,
  ControlledRenderProps,
} from '@adam-vault/adam-frontend-shared';
import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover } from '@reach/menu-button';
import { useRect } from '@reach/rect';
import React, { MouseEventHandler, useCallback, useMemo, useRef } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import styled, { keyframes } from 'styled-components';
import Icon from 'components/Icon';

export type DropdownItemType = {
  key: string;
  content: string;
  href?: string;
  title?: string;
  onSelect: () => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

interface Props<TFieldValues extends FieldValues> extends ControlledInputProps<TFieldValues> {
  buttonText?: string;
  marginOfPopover?: number;
  items: ControlledFieldPathValue<TFieldValues>[];
  width?: number;
  renderCustomButtonContent?: (item: ControlledFieldPathValue<TFieldValues> | undefined) => React.ReactNode;
  renderItem?: (item: ControlledFieldPathValue<TFieldValues> | undefined) => React.ReactNode;
}

const BUFFER = 30;

const slideDownKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledMenuButton = styled(MenuButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 44px;
  text-align: left;
  border-radius: 8px;
  white-space: nowrap;
`;

const StyledMenuPopover = styled(MenuPopover)`
  animation: ${slideDownKeyframes} 0.2s ease;
  z-index: ${({ theme }): number | string => theme.zIndex.dropdown};
  box-shadow: ${({ theme }): string => theme.shadow.dropdown};
  border-radius: 8px;
`;

const StyledMenuItems = styled(MenuItems)`
  background-color: ${({ theme: { color } }): string => color.dropdownBackground};
  height: 200px;
  padding: 4px;
  border-radius: 8px;
  overflow-y: auto;
`;

const StyledMenuItem = styled(MenuItem)`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px 8px;
  user-select: none;

  &:hover {
    background-color: ${({ theme: { color } }): string => color.dropdownItemHoverBackground};
  }
`;

export default function Dropdown<TFieldValues extends FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const {
    width,
    name,
    control,
    readOnly,
    renderCustomButtonContent = (item: ControlledFieldPathValue<TFieldValues> | undefined) => item as React.ReactNode,
    renderItem = (item: ControlledFieldPathValue<TFieldValues> | undefined) => item as React.ReactNode,
    marginOfPopover = 8,
    value,
    onChange,
    ...inputProps
  } = props;
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const menuItemsRef = useRef<HTMLDivElement>(null);

  const menuButtonRect = useRect(menuButtonRef);
  const menuItemsRect = useRect(menuItemsRef);
  const { height: windowHeight } = useWindowSize();

  const menuItemsTop = useMemo(() => {
    const isOutOfWindowBound =
      (menuButtonRect?.bottom ?? 0) + marginOfPopover + (menuItemsRect?.height ?? 0) > (windowHeight ?? 0) - BUFFER;
    return isOutOfWindowBound
      ? 0
      : (menuButtonRect?.bottom ?? 0) + window.scrollY - ((menuButtonRect?.height ?? 0) - BUFFER);
  }, [marginOfPopover, menuItemsRect?.height, windowHeight, menuButtonRect]);

  const ControlledRender = useCallback(
    ({ field }: ControlledRenderProps<TFieldValues>): JSX.Element => (
      <Dropdown<TFieldValues>
        {...inputProps}
        width={width}
        readOnly={readOnly}
        renderCustomButtonContent={renderCustomButtonContent}
        renderItem={renderItem}
        marginOfPopover={marginOfPopover}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
      />
    ),
    [inputProps, marginOfPopover, readOnly, renderCustomButtonContent, renderItem, width]
  );

  if (name && control) {
    return <Controller name={name} control={control} render={ControlledRender} />;
  }

  return (
    // Menu is the wrapper component for the other components. No DOM element is rendered.
    <Menu>
      <StyledMenuButton className={props.className} ref={menuButtonRef} disabled={!!readOnly}>
        {renderCustomButtonContent(value)}
        {!readOnly && <Icon icon="down" size="16px" />}
      </StyledMenuButton>
      <StyledMenuPopover
        position={() => ({
          width: width || menuButtonRect?.width || 0,
          marginTop: marginOfPopover,
          top: menuItemsTop,
          left: menuButtonRect?.left || 0,
        })}
      >
        <StyledMenuItems ref={menuItemsRef}>
          {props.items.map((item, index) => (
            <StyledMenuItem onSelect={() => onChange?.(item)} key={`item-${index}`}>
              {renderItem(item)}
            </StyledMenuItem>
          ))}
        </StyledMenuItems>
      </StyledMenuPopover>
      <ControlledInput {...inputProps} control={control} name={name} hidden />
    </Menu>
  );
}
