import styled from 'styled-components';
import Button from 'components/Button';
import { successModalIcon } from 'components/images';
import { TokenAmountCard } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'h1',
})`
  text-align: inherit;
  white-space: pre-line;
`;

const Subtitle = styled(LocalizedText).attrs({ category: 'body1' })`
  color: ${({ theme }) => theme.color.tinted};
  text-align: inherit;
  white-space: pre-line;
`;

const Description = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'body2',
})`
  text-align: inherit;
`;

const SuccessIconImage = styled.img.attrs({ src: successModalIcon })`
  height: 104px;
`;
interface Props {
  onComplete: (isOpenWelcomeRewardModal?: boolean) => void;
  campaignRewardAmount: string;
  isWelcomeRewardClaimed?: boolean;
}

export default function SuccessBlockArbor({
  onComplete,
  campaignRewardAmount,
  isWelcomeRewardClaimed,
}: Props): JSX.Element {
  const onClickComplete = (): void => {
    onComplete();
  };
  const onClickClaimWelcomeReward = (): void => {
    onComplete(true);
  };

  return (
    <Container>
      <Header>
        <Title translationKey={isWelcomeRewardClaimed ? 'campaign.success.title' : 'campaign.success-arbor.title'} />
        {isWelcomeRewardClaimed && <Subtitle translationKey="campaign.success.subtitle" />}
      </Header>
      <SuccessIconImage />
      <TokenAmountCard
        amount={Number(campaignRewardAmount).toFixed(2)}
        translationKey="campaign.success-arbor.amount-label"
      />
      <Description
        translationKey={isWelcomeRewardClaimed ? 'campaign.success.description' : 'campaign.success-arbor.description'}
      />
      {isWelcomeRewardClaimed ? (
        <Button variant="secondary" onClick={onClickComplete} className={gaClassName('campaign-modal-complete')}>
          <LocalizedText category="body1" weight="medium" translationKey="campaign.success.button-escape" />
        </Button>
      ) : (
        <>
          <Button
            variant="secondary"
            onClick={onClickClaimWelcomeReward}
            className={gaClassName('campaign-modal-continue-arbor')}
          >
            <LocalizedText category="body1" weight="medium" translationKey="campaign.success-arbor.button-continue" />
          </Button>
          <Button variant="text" onClick={onClickComplete} className={gaClassName('campaign-modal-complete-arbor')}>
            <LocalizedText
              category="caption"
              variant="secondary"
              weight="medium"
              translationKey="campaign.success-arbor.button-escape"
            />
          </Button>
        </>
      )}
    </Container>
  );
}
