import { InitOptions, Namespace, NormalizeReturn, Resource, TFuncKey, createInstance, i18n } from 'i18next';
import { DetectorOptions } from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en, zh } from 'locales';

export const LanguageKeys = ['en', 'zh'] as const;

export type LanguageKey = Exclude<Namespace<'zh' | 'en'>, Array<'zh' | 'en'>>;

export const isLanguageKey = (key: string): key is LanguageKey => key === 'en' || key === 'zh';
export const DefaultLanguage: LanguageKey = 'zh';

export const LanguageResource: Resource = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
};

export const LanguageDetectorOption: DetectorOptions = {
  // order and from where user language should be detected
  order: ['path', 'cookie'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupFromPathIndex: 1,

  // cache user language on
  caches: ['cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 60 * 24 * 365,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

export const LanguageInitOptions: InitOptions = {
  resources: LanguageResource,
  detection: LanguageDetectorOption,
  fallbackLng: DefaultLanguage,
};

export type TranslationKey = TFuncKey<'zh' | 'en'>;

export type SubTranslationKey<K extends TranslationKey> = NormalizeReturn<typeof en | typeof zh, K>;

export function createI18nInstance(): i18n {
  const i18nInstance = createInstance({
    resources: LanguageResource,
    fallbackLng: DefaultLanguage,
  }).use(initReactI18next);
  i18nInstance.init();
  return i18nInstance;
}
