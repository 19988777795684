export const NAVIGATION_PAGE_CONTAINER_BOTTOM_SPACING = 32;
export const NAVIGATION_TAB_BAR_HEIGHT = 60;
export const DESKTOP_PADDING_HORIZONTAL_PERCENTAGE = 38;
export const HEADER_PADDING = 16;
export const HEADER_ICON_SIZE = 24;
export const HEADER_HEIGHT = HEADER_PADDING * 2 + HEADER_ICON_SIZE;
export const TOAST_CONTAINER_BOTTOM_SPACING = 4;
export const TOAST_CONTAINER_BOTTOM_SPACING_WITH_NAVIGATION_TAB_BAR =
  NAVIGATION_TAB_BAR_HEIGHT + TOAST_CONTAINER_BOTTOM_SPACING;
export const PAGE_MIN_WIDTH = 360;
