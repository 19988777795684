import styled from 'styled-components';
import imageScannerBorder from 'assets/images/image_scanner_border.svg';
import Button from 'components/Button';
import LocalizedText, { TranslationKey } from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ShadowLayer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: ${({ theme: { color } }) => color.scannerBackground};
`;

const ScanFailButton = styled(Button).attrs({ variant: 'text' })`
  position: absolute;
  top: calc(100% + 24px);
`;

const ScanFailLabel = styled(LocalizedText)`
  text-decoration: underline;
`;

interface Props {
  onClickScanFail?: () => void;
  scanFailLabel?: TranslationKey;
}

export default function ScannerBackground({
  onClickScanFail,
  scanFailLabel = 'scanner.scan-fail-label',
}: Props): JSX.Element {
  return (
    <Container>
      <ShadowLayer />
      <Row>
        <ShadowLayer />
        <img src={imageScannerBorder} />
        <ShadowLayer />
        <ScanFailButton onClick={onClickScanFail}>
          <ScanFailLabel
            category="body2"
            variant="secondary"
            translationKey={scanFailLabel}
            className={gaClassName('scanner-cant-scan')}
          />
        </ScanFailButton>
      </Row>
      <ShadowLayer />
    </Container>
  );
}
