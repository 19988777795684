import styled, { css, keyframes } from 'styled-components';

interface Props {
  width: string;
  height: string;
}

const skeletonKeyframes = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const backgroundColor = '#ebebeb';
const foregroundColor = '#f5f5f5';

export const skeletonCss = css`
  background-color: ${backgroundColor};
  position: relative;
  user-select: none;
  overflow: hidden;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, ${backgroundColor}, ${foregroundColor}, ${backgroundColor});
    transform: translateX(-100%);
    animation: 1.5s ${skeletonKeyframes} ease-in-out infinite;
  }
`;

const Skeleton = styled.div<Props>`
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  z-index: 1;

  width: ${({ width = '100%' }) => width};
  height: ${({ height = '16px' }) => height};

  ${skeletonCss}
`;

export default Skeleton;
