import { atom } from 'jotai';
import { loadableAtomWithRefresh } from 'utils/atomUtils';
import { coffeeDaoAtom } from './coffeeDaoAtom';
import { selfInfoAtom } from './selfInfoAtom';

export const referralRewardModalAtom = atom<boolean>(false);

export const referralAtom = loadableAtomWithRefresh(async (get) => {
  const selfInfo = get(selfInfoAtom);
  const coffeeDao = get(coffeeDaoAtom);

  if (!selfInfo || !coffeeDao) {
    return undefined;
  }

  const listReferralResponse = await coffeeDao.getReferrals({
    limit: 50,
    offset: 0,
    referrerId: selfInfo.referralCode,
  });
  return { referralCode: selfInfo.referralCode, referrals: listReferralResponse.referrals };
});
