import styled from 'styled-components';
import Icon from 'components/Icon';
import { shopCardBackground, shopCardBackgroundPolygon } from 'components/images';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import ShopTag from './ShopTag';

const Card = styled.button`
  width: 100%;
  border-radius: 24px 24px 24px 0px;
  aspect-ratio: 358 / 208;
  position: relative;
  z-index: 0;

  /* card gradient border */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px 24px 24px 0px;
    background: linear-gradient(124deg, #835736 29.85%, #bcbcbc 56.76%, #7e614a 67.02%, #695d55 100%);
    z-index: -1;
  }

  /* card gradient background */
  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 21px 21px 21px 0px;
    background: linear-gradient(106deg, #786a60 0%, #907f71 37.42%, #b8a08d 99.78%);
    backdrop-filter: blur(94.5px);
    z-index: -1;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  color: ${({ theme }) => theme.color.onPrimary};
  overflow: hidden;

  /* card background */
  background-image: url(${shopCardBackground});
  background-position-y: 16px;
  background-repeat: no-repeat;
`;

const BackgroundPolygon = styled.img.attrs({ src: shopCardBackgroundPolygon })`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }
`;

const Balance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const QRCodeIcon = styled(Icon)`
  align-self: flex-end;
  justify-self: flex-end;
`;

interface Props {
  balance: string;
  username: string;
  onClick: () => void;
}

export default function ShopCard({ onClick, balance, username }: Props): JSX.Element {
  return (
    <Card onClick={onClick}>
      <BackgroundPolygon />
      <CardContent>
        <Row>
          <ShopTag />
          <Balance>
            <LocalizedText category="body2" weight="medium">
              $COFFEE
            </LocalizedText>
            <Text category="h0" weight="medium">
              {balance}
            </Text>
          </Balance>
        </Row>
        <Row>
          <LocalizedText category="body2">{username}</LocalizedText>
          <QRCodeIcon size="28px" icon="qr-code" />
        </Row>
      </CardContent>
    </Card>
  );
}
