import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import TabBar from 'components/TabBar';
import PATHS from 'constants/path';
import { TransactionFilterType } from 'constants/transaction';
import useCoffeeDaoTransactions from 'hooks/useCoffeeDaoTransactions';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSetIsWelcomeRewardModalOpen from 'hooks/useSetIsWelcomeRewardModalOpen';
import { gaClassName } from 'utils/gtmEventUtils';
import TransactionRecordList from './TransactionRecordList';

const Container = styled.div`
  padding: 16px 20px 32px 20px;
`;

const TransactionsContent = styled.div`
  border: ${({ theme }) => `1px solid ${theme.color.transactionBlockBorder}`};
  border-radius: 16px;
  margin-top: 24px;
`;

const Title = styled(LocalizedText)`
  margin-bottom: 16px;
`;

export default function Transactions(): JSX.Element {
  const setIsWelcomeRewardModalOpen = useSetIsWelcomeRewardModalOpen();
  const navigate = useNavigateWithLang();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const defaultTransactionType: TransactionFilterType =
    state?.defaultTransactionType || searchParams.get('type') || TransactionFilterType.EARN;

  const [selectedTransactionType, setSelectedTransactionType] = useState<TransactionFilterType>(defaultTransactionType);
  const { records, isLoading, reloadRecords } = useCoffeeDaoTransactions(selectedTransactionType);

  const switchTab = (transactionType: TransactionFilterType): void => {
    setSelectedTransactionType(transactionType);
    setSearchParams({ type: transactionType });
    reloadRecords();
  };

  const onClickClaimReward = (): void => {
    setIsWelcomeRewardModalOpen(true);
  };

  const onClickSpendToken = (): void => {
    navigate(`/${PATHS.SCANNER}`);
  };

  return (
    <Page hasAuthenticationBlocker>
      <Container>
        <Title category="h2" translationKey="transactions.title" variant="primary" />
        <TabBar<TransactionFilterType>
          tabs={[
            { value: TransactionFilterType.EARN, label: 'transactions.type.earn' },
            { value: TransactionFilterType.SPEND, label: 'transactions.type.spend' },
            { value: TransactionFilterType.PROCESSING, label: 'transactions.type.processing' },
          ]}
          selectedTab={selectedTransactionType}
          onTabChange={switchTab}
          isLoading={isLoading}
          tabClassName={(tab) => gaClassName(`transactions-tab-${tab}`)}
        />
        <TransactionsContent>
          <TransactionRecordList
            records={records}
            isLoading={isLoading}
            selectedTransactionType={selectedTransactionType}
            onClickClaimReward={onClickClaimReward}
            onClickSpendToken={onClickSpendToken}
          />
        </TransactionsContent>
      </Container>
    </Page>
  );
}
