const zIndex = {
  firstLayer: 1000,
  secondLayer: 2000,
  thirdLayer: 3000,
  fourthLayer: 4000,
  fifthLayer: 5000,
  sixthLayer: 6000,
  seventhLayer: 7000,
  eighthLayer: 8000,
  ninthLayer: 9000,
};

export default zIndex;
