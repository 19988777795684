import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { JWT_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import { sessionAtom } from 'store/sessionAtom';
import useLoadableAtom from './useLoadableAtom';
import useSelfInfo from './useSelfInfo';

export default function useSession(): {
  isSigning: boolean;
  jwtToken: string | undefined;
  jwtBeforeLogin: string | undefined;
  error: Error | undefined;
  getSession: () => void;
  clearSession: () => void;
} {
  const { isLoading: isSigning, data: jwtToken, error } = useLoadableAtom(sessionAtom);
  const getSession = useSetAtom(sessionAtom);
  const { clearSelfInfo } = useSelfInfo();

  const clearSession = useCallback(() => {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    clearSelfInfo();
  }, [clearSelfInfo]);

  return {
    isSigning,
    jwtToken,
    jwtBeforeLogin: jwtToken || localStorage.getItem(JWT_LOCAL_STORAGE_KEY) || undefined,
    error,
    getSession,
    clearSession,
  };
}
