import { createContext, useMemo, useCallback } from 'react';
import { ServerGenericErrorType, ServerGenericError } from 'constants/error/ServerGenericError';
import { web3Connector } from 'store/web3Atom';
import { toast } from 'utils/toastUtils';

export class CoffeeDaoClient {
  private onAuthTokenInvalid: () => void;

  private onServerGenericErrorOccur: (errorType: ServerGenericErrorType) => void;

  constructor(onAuthTokenInvalid: () => void, onServerGenericErrorOccur: (errorType: ServerGenericErrorType) => void) {
    this.onAuthTokenInvalid = onAuthTokenInvalid;
    this.onServerGenericErrorOccur = onServerGenericErrorOccur;
  }

  async request<T>(fn: Promise<T>): Promise<T> {
    try {
      return await fn;
    } catch (err) {
      const error = err as unknown as Error;
      switch (error.message) {
        case ServerGenericErrorType.AUTH_TOKEN_INVALID:
          this.onAuthTokenInvalid();
          break;
        case ServerGenericErrorType.UNSUPPORTED_NETWORK:
        case ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_MERCHANT:
        case ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_CUSTOMER:
          this.onServerGenericErrorOccur(err as ServerGenericErrorType);
          break;
        default:
          break;
      }
      throw error;
    }
  }
}

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export const CoffeeDaoClientContext = createContext<CoffeeDaoClient | undefined>(undefined);

export function CoffeeDaoClientProvider({ children }: Props): JSX.Element {
  const onAuthTokenInvalid = useCallback(() => {
    web3Connector.disconnectEOA();
  }, []);

  const onServerGenericErrorOccur = useCallback((errorType: ServerGenericErrorType) => {
    toast(ServerGenericError[errorType].message);
  }, []);

  const apiClient = useMemo(
    () => new CoffeeDaoClient(onAuthTokenInvalid, onServerGenericErrorOccur),
    [onAuthTokenInvalid, onServerGenericErrorOccur]
  );

  return <CoffeeDaoClientContext.Provider value={apiClient}>{children}</CoffeeDaoClientContext.Provider>;
}
