import styled, { css, keyframes } from 'styled-components';
import iconCoin from 'assets/images/icon_coin.svg';
import QrCodeIcon from 'assets/images/icon_qr_code.svg';
import iconStar from 'assets/images/icon_star.svg';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { Card, Page } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import { HEADER_HEIGHT } from 'constants/dimension';
import PATHS from 'constants/path';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSetIsWalletDetailModalOpen from 'hooks/useSetIsWalletDetailModalOpen';
import useSetIsWelcomeRewardModalOpen from 'hooks/useSetIsWelcomeRewardModalOpen';
import theme from 'theme/default';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  background: url('background.svg') no-repeat center top ${`${28 + HEADER_HEIGHT}px`} fixed;
`;

const WalletContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CoffeeBalanceContainer = styled(Card)`
  flex-direction: column;
  align-items: center;
  margin-top: 172px;
  margin-bottom: 12px;
  text-align: center;
  padding-top: 72px;
`;

const CoffeeBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

const RefreshButton = styled.button`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: ${({ theme: { color } }) => color.tinted};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled(Icon).attrs({ icon: 'switch', size: '20px', color: theme.color.tinted })<{
  isRefreshing?: boolean;
}>`
  width: 20px;
  height: 20px;

  ${({ isRefreshing }) =>
    isRefreshing &&
    css`
      animation: ${rotate} 2s linear infinite;
    `}
`;

const RefreshText = styled(LocalizedText).attrs(() => ({ category: 'caption' }))`
  color: inherit;
`;

const CoinImg = styled.img.attrs({ src: iconCoin })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -140px;
`;

const BigStarImg = styled.img.attrs({ src: iconStar })`
  position: absolute;
  top: 97px;
  margin-left: auto;
  margin-right: auto;
  left: 240px;
  right: 0;
`;

const SmallStarImg = styled.img.attrs({ src: iconStar })`
  position: absolute;
  top: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 210px;
  height: 24px;
  width: 24px;
`;

const QrCodeButton = styled(IconButton).attrs({ variant: 'secondary', buttonSize: '40px' })`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const QrCodeImg = styled.img.attrs({ src: QrCodeIcon })``;

export default function Wallet(): JSX.Element {
  const setIsWelcomeRewardModalOpen = useSetIsWelcomeRewardModalOpen();
  const setIsWalletDetailModalOpen = useSetIsWalletDetailModalOpen();
  const navigate = useNavigateWithLang();
  const { balanceDisplay, balanceBN, isLoading: isBalanceLoading, refreshBalance } = useCoffeeDaoTokenBalance();

  const onClickClaimReward = (): void => {
    setIsWelcomeRewardModalOpen(true);
  };

  const onClickSpendToken = (): void => {
    navigate(`/${PATHS.SCANNER}`);
  };

  const onClickQrCode = (): void => {
    setIsWalletDetailModalOpen(true);
  };

  return (
    <Page hasAuthenticationBlocker>
      <Container>
        <BigStarImg />
        <CoffeeBalanceContainer>
          <CoinImg />
          <SmallStarImg />
          <QrCodeButton onClick={onClickQrCode}>
            <QrCodeImg />
          </QrCodeButton>
          <WalletContent>
            <CoffeeBalance>
              <Text category="h0" variant="secondary">
                {isBalanceLoading ? '-' : balanceDisplay || '0'}
              </Text>
              <LocalizedText category="body2" variant="secondary" translationKey="wallet.balance.balance-label" />
            </CoffeeBalance>
            <ActionButtonContainer>
              <Button onClick={onClickClaimReward} className={gaClassName('wallet-page-claim-flow')}>
                <LocalizedText
                  category="body1"
                  variant="secondary"
                  translationKey="wallet.balance.button-welcome-reward"
                />
              </Button>
              <Button
                variant="secondary"
                disabled={balanceBN?.isZero()}
                onClick={onClickSpendToken}
                className={gaClassName('wallet-page-spend-flow')}
              >
                <LocalizedText category="body1" translationKey="wallet.balance.button-spend-token" />
              </Button>
            </ActionButtonContainer>
            <RefreshButton onClick={refreshBalance}>
              <RefreshIcon isRefreshing={isBalanceLoading} />
              <RefreshText
                translationKey="wallet.balance.button-reload-balance"
                values={{
                  lastUpdatedAt: new Date().toLocaleString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    hourCycle: 'h12',
                  }),
                }}
              />
            </RefreshButton>
          </WalletContent>
        </CoffeeBalanceContainer>
      </Container>
    </Page>
  );
}
