import { QrCode } from '@adam-vault/adam-sdk-coffeedao';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { QR_CODE_IDENTIFIER_PARAM_KEY, SPEND_TO_PARAM_KEY } from 'constants/searchParams';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import { decodeQrCode } from 'utils/qrCodeUtils';

export default function QRCodeRedirect(): JSX.Element | null {
  const [isQrCodeDecoded, setIsQrCodeDecoded] = useState(false);
  const [qrCode, setQrCode] = useState<QrCode | undefined>();
  const {
    params: [qrCodeIdentifier],
  } = useSearchParamKeys(QR_CODE_IDENTIFIER_PARAM_KEY);

  useEffect(() => {
    if (qrCodeIdentifier) {
      decodeQrCode(qrCodeIdentifier, { skipError: true }).then((decodedQrCode) => {
        setQrCode(decodedQrCode);
        setIsQrCodeDecoded(true);
      });
    } else {
      setIsQrCodeDecoded(true);
    }
  }, [qrCodeIdentifier, setQrCode]);

  if (!isQrCodeDecoded) {
    return null;
  }

  if (qrCode) {
    switch (qrCode.usageType) {
      case 'payment': {
        return <Navigate to={`/?${SPEND_TO_PARAM_KEY}=${qrCode.destinationAddress}`} />;
      }
      default: {
        return <Navigate to="/" />;
      }
    }
  }

  return <Navigate to="/" />;
}
