import { SUBSIDY_TRANSACTION_STATUS } from '@adam-vault/adam-sdk-coffeedao';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import LoadingSpinner from 'components/Loading';
import { ModalCard, ModalStep } from 'components/Modal';
import useClaimWelcomeReward from 'hooks/useClaimWelcomeReward';
import { selfInfoAtom } from 'store/selfInfoAtom';

export default function VerifiedBlock(): JSX.Element {
  const setSelfInfo = useSetAtom(selfInfoAtom);
  const [isClaimRewardRequested, setIsClaimRewardRequested] = useState(false);
  const { claimWelcomeReward, isLoading, error } = useClaimWelcomeReward({
    onClaimRewardSuccess: () => {
      setSelfInfo((info) => (info ? { ...info, isWelcomeRewardClaiming: true } : info));
    },
  });

  useEffect(() => {
    if (!isClaimRewardRequested && !isLoading && !error) {
      setIsClaimRewardRequested(true);
      claimWelcomeReward().then((status) => {
        if (status === SUBSIDY_TRANSACTION_STATUS.CANCELED) {
          // if the transaction is failed or canceled, will retry in 2 seconds
          setTimeout(() => {
            setIsClaimRewardRequested(false);
          }, 2000);
        }
      });
    }
  }, [claimWelcomeReward, isLoading, isClaimRewardRequested, error]);

  return (
    <ModalCard image="dripping" align="left">
      <ModalStep label="welcome-reward.title" step={3} totalStep={3} />
      <ModalCard.Heading translationKey="welcome-reward.verified.title" />
      <ModalCard.Message translationKey="welcome-reward.verified.description" />
      <ModalCard.Action>
        <LoadingSpinner />
      </ModalCard.Action>
    </ModalCard>
  );
}
