import { useMemo } from 'react';
import { useLocation, matchRoutes } from 'react-router-dom';
import { guestTabInfoList, coffeeDrinkerTabInfoList, coffeeShopTabInfoList } from 'constants/tabInfos';
import UserType from 'constants/userType';
import { NavigationTabInfo } from 'types/TabInfo';
import { gaClassName } from 'utils/gtmEventUtils';
import useLanguage from './useLanguage';
import useUserType from './useUserType';

export default function useNavigationBarInfos(): NavigationTabInfo[] {
  const userType = useUserType();
  const location = useLocation();
  const { language } = useLanguage();

  const tabInfoArray = useMemo(() => {
    switch (userType) {
      case UserType.GUEST:
        return guestTabInfoList;
      case UserType.COFFEE_DRINKER:
        return coffeeDrinkerTabInfoList;
      case UserType.COFFEE_SHOP:
        return coffeeShopTabInfoList;
    }
  }, [userType]);

  const navigationTabs = useMemo(
    () =>
      tabInfoArray.map((tabInfo) => {
        const path =
          userType === UserType.COFFEE_SHOP ? `/${language}/shop/${tabInfo.path}` : `/${language}/${tabInfo.path}`;
        return {
          key: tabInfo.key,
          iconUrl: matchRoutes([{ path }], location) ? tabInfo.activeIconUrl : tabInfo.defaultIconUrl,
          path: tabInfo.key === 'shops' ? 'https://coffeedao.net/neighborhood/' : path,
          title: userType === UserType.COFFEE_SHOP ? undefined : tabInfo.title,
          className: gaClassName(`navigation-bar-${tabInfo.key}`),
        };
      }),
    [language, location, tabInfoArray, userType]
  );

  return navigationTabs;
}
