import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { ModalCard, ModalStep } from 'components/Modal';
import { gaClassName } from 'utils/gtmEventUtils';

interface Props {
  onComplete: () => void;
}

export default function ProcessingBlock({ onComplete }: Props): JSX.Element {
  return (
    <ModalCard image="dripping" align="left">
      <ModalStep label="welcome-reward.title" step={3} totalStep={3} />
      <ModalCard.Heading translationKey="welcome-reward.processing.title" />
      <ModalCard.Message translationKey="welcome-reward.processing.description" />
      <ModalCard.Action>
        <Button variant="secondary" onClick={onComplete} className={gaClassName('claim-modal-processing')}>
          <LocalizedText category="body1" weight="medium" translationKey="welcome-reward.processing.button-escape" />
        </Button>
      </ModalCard.Action>
    </ModalCard>
  );
}
