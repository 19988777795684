import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';

interface Props {
  isOpen: boolean;
  getSession: () => void;
}

export default function SessionExpireModal({ isOpen, getSession }: Props): JSX.Element {
  return (
    <SmallModal isOpen={isOpen} isImportantModal>
      <SmallModal.Title translationKey="session-expired.title" />
      <SmallModal.Message translationKey="session-expired.description" />
      <SmallModal.Action>
        <Button variant="secondary" onClick={getSession}>
          <LocalizedText category="body1" weight="medium" translationKey="session-expired.button-sign" />
        </Button>
      </SmallModal.Action>
    </SmallModal>
  );
}
