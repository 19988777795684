import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PATHS from 'constants/path';
import UserType from 'constants/userType';
import useLanguage from 'hooks/useLanguage';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useUserType from 'hooks/useUserType';

export default function ShopRedirectManager(): null {
  const userType = useUserType();
  const location = useLocation();
  const navigate = useNavigateWithLang();
  const { language } = useLanguage();

  useEffect(() => {
    if (userType !== UserType.COFFEE_SHOP && location.pathname.includes(`/${PATHS.SHOP}`)) {
      navigate(`/${language}`);
    } else if (userType === UserType.COFFEE_SHOP && !location.pathname.includes(`/${PATHS.SHOP}`)) {
      navigate(`/${language}/${PATHS.SHOP}`);
    }
  }, [location.pathname, navigate, userType, language]);

  return null;
}
