import { Token } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { Atom, WritableAtom, atom } from 'jotai';
import { loadable } from 'jotai/utils';
import { Loadable, loadableAtomWithRefresh } from 'utils/atomUtils';
import { adamAtom } from './adamAtom';
import { metaAtom } from './metaAtom';

export type TokenDetail = {
  name?: string;
  symbol?: string;
  decimals?: number;
};

export const DEFAULT_TOKEN_DETAIL: TokenDetail = {
  name: undefined,
  symbol: undefined,
  decimals: undefined,
};

export function createTokenDetailAtom(
  tokenAtom: Atom<Loadable<Promise<Token | undefined>>>
): WritableAtom<Loadable<TokenDetail>, undefined, void> {
  return loadableAtomWithRefresh(async (get) => {
    const token = get(tokenAtom);
    if (token.state !== 'hasData' || !token.data) {
      return DEFAULT_TOKEN_DETAIL;
    }

    const [name, symbol, decimals] = await Promise.all([token.data.name(), token.data.symbol(), token.data.decimals()]);
    return { name, symbol, decimals };
  });
}

// CoffeeDAO Token
export const coffeeDaoTokenAtom = loadable(
  atom(async (get) => {
    const adam = get(adamAtom);
    const meta = get(metaAtom);

    if (!adam || meta.state !== 'hasData') {
      return undefined;
    }

    const tokenEntity = adam.loadToken(meta.data.UTIL_TOKEN_ADDRESS);
    return tokenEntity;
  })
);
export const coffeeDaoTokenDetailAtom = createTokenDetailAtom(coffeeDaoTokenAtom);
export const coffeeDaoTokenBalanceBNAtom = atom<BigNumber | undefined>(undefined);

// Polygon Token
export const MATIC_TOKEN_ADDRESS = '0x0000000000000000000000000000000000001010';
export const polygonTokenAtom = loadable(
  atom(async (get) => {
    const adam = get(adamAtom);

    if (!adam) {
      return undefined;
    }

    const tokenEntity = adam.loadToken(MATIC_TOKEN_ADDRESS);
    return tokenEntity;
  })
);
export const polygonTokenDetailAtom = createTokenDetailAtom(polygonTokenAtom);
export const polygonTokenBalanceBNAtom = atom<BigNumber | undefined>(undefined);
