import { WalletType, formatUtils } from '@adam-vault/adam-frontend-shared';
import { BigNumber, Transaction } from 'ethers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import FloatingLabelInputField from 'components/form/FloatingLabelInputField';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';
import { SignatureRequestRetryCard, SignatureRequiredModal } from 'components/signature';
import Text from 'components/Text';
import PATHS from 'constants/path';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import { useCoffeeDaoToken, usePolygonToken } from 'hooks/useToken';
import useTransferToken from 'hooks/useTransferToken';
import useUserName from 'hooks/useUserName';
import useWeb3 from 'hooks/useWeb3';
import ConfirmAmountBlock from './ConfirmAmountBlock';
import EditAmountBlock from './EditAmountBlock';
import SuccessBlock from './SuccessBlock';
import TransferHeader from './TransferHeader';

export enum TransferStep {
  INPUT_TRANSFER_ADDRESS,
  EDIT_TRANSFER_AMOUNT,
  CONFIRM_TRANSFER_AMOUNT,
  COMPLETED,
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  overflow-y: auto;
`;

const SuccessContainer = styled(Container)`
  background: linear-gradient(
    180deg,
    #1ebac5 40px,
    #1ebac5 40px,
    ${({ theme }) => theme.color.modalBackground} 200px,
    ${({ theme }) => theme.color.modalBackground} 100%
  );
  gap: 24px;
`;

const ChangeAddressForm = styled.div`
  margin: 80px 0;
`;

const AddressContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
`;

const ContinueButton = styled(Button)`
  margin-top: auto;
`;

const DEFAULT_TRANSFER_AMOUNT = '0.1';

interface Props {
  transferAddress: string;
  onUpdateTransferAddress: (address: string) => void;
  onRequestCloseModal: () => void;
  onComplete: () => void;
}

// const closeButtonClassName = gaClassName('spend-modal-close');
export default function TransferView(props: Props): JSX.Element | null {
  const { transferAddress, onUpdateTransferAddress, onRequestCloseModal, onComplete } = props;
  const web3 = useWeb3();
  const navigate = useNavigateWithLang();
  const { toDisplay } = usePolygonToken();
  const { toBN } = useCoffeeDaoToken();

  const [transferAmountBNForRetry, setTransferAmountBNForRetry] = useState<BigNumber | null>(null);
  const [transferAmount, setTransferAmount] = useState(DEFAULT_TRANSFER_AMOUNT);
  const [gasFee, setGasFee] = useState<string>();
  const [step, setStep] = useState<TransferStep>(
    transferAddress.length > 0 ? TransferStep.EDIT_TRANSFER_AMOUNT : TransferStep.INPUT_TRANSFER_ADDRESS
  );

  const { username } = useUserName(transferAddress);
  const { balanceDisplay, refreshBalance } = useCoffeeDaoTokenBalance();
  const { calculateEstimateGasFee, transferToken, estimatedGasFee, isTransferring, isCalculating } = useTransferToken();

  const isOwnAddress = transferAddress === web3.address;

  useEffect(() => {
    refreshBalance();
  }, [refreshBalance]);

  const onChangeTransferAddress = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onUpdateTransferAddress(e.currentTarget.value);
  };

  const onConfirmTransferAddress = (): void => {
    setStep(TransferStep.EDIT_TRANSFER_AMOUNT);
  };

  const onEditAmount = (): void => {
    setStep(TransferStep.EDIT_TRANSFER_AMOUNT);
  };

  const onEditComplete = async (): Promise<void> => {
    setStep(TransferStep.CONFIRM_TRANSFER_AMOUNT);
    const transferAmountBN = toBN(transferAmount || '');
    if (!transferAddress || !transferAmountBN) {
      return undefined;
    }
    await calculateEstimateGasFee(transferAddress, transferAmountBN);
  };

  const onAnotherTransfer = (): void => {
    navigate(`/${PATHS.SHOP}/${PATHS.SCANNER}`);
  };

  const onConfirmTransferToken = async (): Promise<void> => {
    const transferAmountBN = toBN(transferAmount || '');
    setTransferAmountBNForRetry(transferAmountBN);
    if (!transferAddress || !transferAmountBN) {
      return undefined;
    }
    const transaction = await transferToken(transferAddress, transferAmountBN);
    if (transaction) {
      setGasFee(toDisplay(transaction.gasPrice, { maxDecimals: 5 }));
      setStep(TransferStep.COMPLETED);
    }
  };

  const onRetryTransferToken = async (): Promise<Transaction | undefined> => {
    if (!transferAddress || !transferAmountBNForRetry) {
      return undefined;
    }
    const transaction = await transferToken(transferAddress, transferAmountBNForRetry);
    return transaction;
  };

  switch (step) {
    case TransferStep.INPUT_TRANSFER_ADDRESS:
      return (
        <Container>
          <TransferHeader onClose={onRequestCloseModal} />
          <ChangeAddressForm>
            <FloatingLabelInputField
              name="transferAddress"
              label="transfer.input-address-label"
              autoComplete="off"
              onChange={onChangeTransferAddress}
            />
            {isOwnAddress && <ErrorMessage errorMessage="You are entering your own address" />}
          </ChangeAddressForm>
          <ContinueButton onClick={onConfirmTransferAddress} disabled={isOwnAddress}>
            <LocalizedText category="body1" translationKey="transfer.button-next" />
          </ContinueButton>
        </Container>
      );
    case TransferStep.EDIT_TRANSFER_AMOUNT:
      return (
        <Container>
          <TransferHeader onClose={onRequestCloseModal} />
          <AddressContent>
            <LocalizedText category="body1" color="caption" translationKey="transfer.transfer-to-label" />
            <Text category="h1" color="primary">
              {formatUtils.toEllipsisAddress(transferAddress)}
            </Text>
            {username && (
              <Text category="body1" color="primary">
                {username}
              </Text>
            )}
            {isOwnAddress && <ErrorMessage errorMessage="You are transferring to your own address" />}
          </AddressContent>
          <EditAmountBlock
            amount={transferAmount}
            onUpdateAmount={setTransferAmount}
            balance={balanceDisplay}
            disabled={isOwnAddress}
          />
          <ContinueButton onClick={onEditComplete} disabled={isOwnAddress}>
            <LocalizedText category="body1" translationKey="transfer.button-next" />
          </ContinueButton>
        </Container>
      );
    case TransferStep.CONFIRM_TRANSFER_AMOUNT:
      return (
        <Container>
          <TransferHeader onClose={onRequestCloseModal} />
          <AddressContent>
            <LocalizedText category="body1" color="caption" translationKey="transfer.transfer-to-label" />
            <Text category="h3" color="primary">
              {transferAddress}
            </Text>
          </AddressContent>
          <ConfirmAmountBlock
            amount={transferAmount}
            onEditAmount={onEditAmount}
            estimatedGasFee={toDisplay(estimatedGasFee, { maxDecimals: 5 })}
            isLoadingEstimatedGasFee={isCalculating}
          />
          <ContinueButton onClick={onConfirmTransferToken}>
            {isTransferring ? (
              <LoadingSpinner width={24} height={24} variant="primary" />
            ) : (
              <LocalizedText category="body1" translationKey="transfer.button-confirm" />
            )}
          </ContinueButton>
          <SignatureRequiredModal
            isOpen={isTransferring && web3.walletType !== WalletType.WEB3AUTH}
            description="transfer.signature.description"
          >
            <SmallModal.Action>
              <SignatureRequestRetryCard onRetry={onRetryTransferToken} />
            </SmallModal.Action>
          </SignatureRequiredModal>
        </Container>
      );
    case TransferStep.COMPLETED:
      return (
        <SuccessContainer>
          <TransferHeader variant="secondary" onClose={onComplete} />
          <SuccessBlock to={formatUtils.toEllipsisAddress(transferAddress)} amount={transferAmount} gasFee={gasFee} />
          <ContinueButton onClick={onAnotherTransfer}>
            <LocalizedText category="body1" translationKey="transfer.button-another-transfer" />
          </ContinueButton>
        </SuccessContainer>
      );
    default:
      return null;
  }
}
