import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubTranslationKey } from 'constants/languages';
import useCoffeeShops from 'hooks/useCoffeeShopList';
import useLanguage from 'hooks/useLanguage';
import { gaClassName } from 'utils/gtmEventUtils';
import Icon from './Icon';
import LoadingSpinner from './Loading';
import LocalizedText, { TranslationKey } from './LocalizedText';
import ClaimHeader from './managers/WelcomeRewardManager/ClaimHeader';
import { Modal } from './Modal';
import TabBar from './TabBar';
import Text from './Text';

const Container = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(LocalizedText).attrs({ category: 'h2', variant: 'primary' })`
  margin-bottom: 8px;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
`;

const Count = styled(Text).attrs({ category: 'body1' })`
  opacity: 0.4;
`;

const CoffeeShopList = styled.div<{ isLoading: boolean }>`
  flex: 1;
  width: 100%;
  margin: 8px 0;
  min-height: ${({ isLoading }) => (isLoading ? '200px' : 'auto')};
  overflow: auto;
`;

const CoffeeShopListItemContainer = styled.button`
  width: 100%;
  min-height: 72px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid ${({ theme }) => theme.color.border};
  border-left: 1px solid ${({ theme }) => theme.color.border};
  border-right: 1px solid ${({ theme }) => theme.color.border};

  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const CoffeeShopImg = styled.img`
  height: 48px;
  width: 48px;
`;

const CoffeeShopDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const CoffeeShopName = styled(Text).attrs({ category: 'body1', variant: 'primary' })`
  line-height: 20px;
  margin-bottom: 4px;
`;

const CoffeeShopAddress = styled(Text).attrs({ category: 'body1' })`
  opacity: 0.4;
  margin-top: -4px;
`;

type Region = keyof SubTranslationKey<'coffee-shop.list.region'>;

interface CoffeeShopListItemProps {
  shop: CoffeeShop;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function CoffeeShopListItem({ shop, onClick }: CoffeeShopListItemProps): JSX.Element {
  const { language } = useLanguage();

  return (
    <CoffeeShopListItemContainer onClick={onClick}>
      <CoffeeShopImg src={shop.logoImageURL} />
      <CoffeeShopDetail>
        <CoffeeShopName>{shop.name}</CoffeeShopName>
        <CoffeeShopAddress>{language === 'zh' ? shop.zhAddress : shop.enAddress}</CoffeeShopAddress>
      </CoffeeShopDetail>
      <Icon icon="next" size="24px" />
    </CoffeeShopListItemContainer>
  );
}

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onClickCoffeeShop?: (shop: CoffeeShop) => React.MouseEventHandler<HTMLButtonElement>;
  region: Region;
}

export default function CoffeeShopListModal(props: Props): JSX.Element {
  const { isOpen, onRequestClose, onClickCoffeeShop = () => () => {}, region } = props;

  const { isLoading, coffeeShops } = useCoffeeShops();

  const coffeeShopsInRegion = useMemo(
    () => coffeeShops.filter((shop) => shop.region === region),
    [coffeeShops, region]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} align="left">
      <ClaimHeader onClose={onRequestClose} />
      <Container>
        <Title translationKey="coffee-shop.list.title" />
        <AddressContainer>
          <Icon icon="map" color="primary" />
          <LocalizedText category="body1" translationKey={`coffee-shop.list.region.${region}.name`} />
          <Count>{coffeeShopsInRegion?.length || ''}</Count>
        </AddressContainer>
        <CoffeeShopList isLoading={isLoading}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            coffeeShopsInRegion.map((shop) => (
              <CoffeeShopListItem key={shop.name} shop={shop} onClick={onClickCoffeeShop(shop)} />
            ))
          )}
        </CoffeeShopList>
      </Container>
    </Modal>
  );
}

export function CoffeeShopListModalWithRegion(props: Props): JSX.Element {
  const { isOpen, onRequestClose, onClickCoffeeShop = () => () => {} } = props;

  const { t } = useTranslation();
  const regionTranslations = useMemo(() => t('coffee-shop.list.region', { returnObjects: true }), [t]);

  const [selectedRegion, setSelectedRegion] = useState<Region>('hongKong');
  const { isLoading, coffeeShops } = useCoffeeShops();

  const coffeeShopsInRegion = useMemo(
    () =>
      coffeeShops.reduce(
        (shopsInRegion, shop) => ({
          ...shopsInRegion,
          [shop.region as Region]: [...(shopsInRegion[shop.region as Region] || []), shop],
        }),
        {} as Record<keyof typeof regionTranslations, typeof coffeeShops>
      ),
    [coffeeShops]
  );

  const coffeeShopRegionTabs = useMemo(
    () =>
      (Object.keys(regionTranslations) as Region[])
        .filter((region) => coffeeShopsInRegion[region]?.length > 0)
        .map((districtCode) => ({
          value: districtCode,
          label: `coffee-shop.list.region.${districtCode}.code` satisfies TranslationKey,
        })),
    [coffeeShopsInRegion, regionTranslations]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} align="left">
      <ClaimHeader onClose={onRequestClose} />
      <Container>
        <Title translationKey="coffee-shop.list.title" />
        <TabBar<Region>
          tabs={coffeeShopRegionTabs}
          selectedTab={selectedRegion}
          onTabChange={setSelectedRegion}
          tabClassName={(region) => gaClassName(`shopList-area-tab-${region}`)}
        />
        <AddressContainer>
          <Icon icon="map" color="primary" />
          <LocalizedText category="body1" translationKey={`coffee-shop.list.region.${selectedRegion}.name`} />
          <Count>{coffeeShopsInRegion[selectedRegion]?.length || ''}</Count>
        </AddressContainer>
        <CoffeeShopList isLoading={isLoading}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            coffeeShopsInRegion[selectedRegion].map((shop) => (
              <CoffeeShopListItem key={shop.name} shop={shop} onClick={onClickCoffeeShop(shop)} />
            ))
          )}
        </CoffeeShopList>
      </Container>
    </Modal>
  );
}
