import { WalletType } from '@adam-vault/adam-frontend-shared';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import metamaskLogo from 'assets/images/logo_metamask.svg';
import Button from 'components/Button';
import DebugArea from 'components/DebugArea';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import { Modal } from 'components/Modal';
import { DisclaimerSection, WarningSection } from 'components/wallet';
import useWeb3 from 'hooks/useWeb3';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { web3Connector } from 'store/web3Atom';
import { buttonLikeShadowCss } from 'theme/css';
import { sendGTMEvent, GTMCustomEvent, gaClassName } from 'utils/gtmEventUtils';
import {
  setWeb3AuthRedirectRoute,
  saveWeb3AuthLoginProvider,
  getWeb3AuthLoginProvider,
  LoginProvider,
  LoginProviderIcons,
  LoginProviders,
} from 'utils/web3authUtil';

const ModalContent = styled.div`
  min-height: 236px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  padding: 40px 24px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CloseButton = styled.button.attrs({ children: <Icon icon="close" color="primary" /> })``;

const ConnectWalletTitle = styled(LocalizedText).attrs({ category: 'h1', variant: 'primary' })``;

const ConnectingWalletTitle = styled(LocalizedText).attrs({ category: 'h3', variant: 'primary' })`
  margin: 0;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const ConnectButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ConnectButton = styled.button`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme: { color } }) => color.primary};
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ConnectButtonLogoLogoContainer = styled.div`
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.color.buttonBorder};
  ${buttonLikeShadowCss}
`;

const ConnectButtonLogoLogo = styled.img`
  width: 24px;
`;

const ConnectButtonText = styled(LocalizedText).attrs({ category: 'body1', variant: 'primary' })``;

const ReturnButton = styled(Button).attrs({ variant: 'text' })`
  margin-top: 12px;
  text-decoration: underline;
  opacity: 0.4;
`;

export default function ConnectWalletManager(): JSX.Element {
  const [isConnectWalletModalOpen, setIsConnectWalletModalOpen] = useAtom(isConnectWalletModalOpenAtom);
  const { isConnecting, isConnected, walletType } = useWeb3();
  const [isLoginRequested, setIsLoginRequested] = useState(false);
  const [selectedLoginProvider, setSelectedLoginProvider] = useState<LoginProvider | null>(
    getWeb3AuthLoginProvider() as LoginProvider | null
  );

  useEffect(() => {
    if (!isConnectWalletModalOpen) {
      setIsLoginRequested(false);
    }
  }, [isConnectWalletModalOpen]);

  useEffect(() => {
    if (isConnected) {
      setIsConnectWalletModalOpen(false);
    } else if (isConnecting) {
      setIsLoginRequested(true);
      setIsConnectWalletModalOpen(true);
    }
  }, [isConnected, isConnecting, setIsConnectWalletModalOpen]);

  const connectMetamask = async (): Promise<void> => {
    await web3Connector.connectEOA(WalletType.METAMASK);
  };

  const connectWeb3Auth = (loginProvider: LoginProvider) => async (): Promise<void> => {
    setWeb3AuthRedirectRoute();
    saveWeb3AuthLoginProvider(loginProvider);
    setSelectedLoginProvider(loginProvider);
    sendGTMEvent(GTMCustomEvent.SocialLoginRequested, 'loginProvider', loginProvider);
    setIsLoginRequested(true);
    await web3Connector.connectEOA(WalletType.WEB3AUTH, {
      loginProvider,
      redirectUrl: window.location.origin,
    });
  };

  const connectWalletConnect = async (): Promise<void> => {
    setIsLoginRequested(true);
    await web3Connector.connectEOA(WalletType.WALLET_CONNECT);
  };

  const onClickReturn = (): void => {
    setIsLoginRequested(false);

    if (isConnecting) {
      web3Connector.disconnectEOA();
    }
  };

  const onClose = (): void => {
    setIsConnectWalletModalOpen(false);
  };

  return (
    <Modal size="small" isOpen={isConnectWalletModalOpen} isImportantModal>
      {isLoginRequested || isConnecting ? (
        <ModalContent>
          {selectedLoginProvider && walletType === WalletType.WEB3AUTH ? (
            <>
              <ConnectButtonLogoLogoContainer>
                <ConnectButtonLogoLogo src={LoginProviderIcons['google']} />
              </ConnectButtonLogoLogoContainer>
              <ConnectingWalletTitle
                translationKey="wallet.connecting.social-login-title"
                values={{ loginProvider: selectedLoginProvider }}
              />
            </>
          ) : (
            <ConnectingWalletTitle translationKey="wallet.connecting.self-custody-title" />
          )}
          <LoadingSpinner width={32} height={32} variant="secondary" />
          <ReturnButton onClick={onClickReturn}>
            <LocalizedText category="caption" translationKey="wallet.connecting.button-return" />
          </ReturnButton>
        </ModalContent>
      ) : (
        <ModalContent>
          <Header>
            <ConnectWalletTitle translationKey="wallet.connect.title" />
            <CloseButton onClick={isLoginRequested || isConnecting ? undefined : onClose} />
          </Header>
          <ConnectButtonsWrapper>
            {LoginProviders.map((loginProvider) => (
              <ConnectButton
                key={loginProvider}
                onClick={connectWeb3Auth(loginProvider)}
                disabled={isConnecting}
                className={gaClassName(`login-web3auth-${loginProvider}`)}
              >
                <ConnectButtonLogoLogo src={LoginProviderIcons[loginProvider]} />
                <ConnectButtonText
                  translationKey="wallet.connect.button-social-login"
                  values={{ wallet: loginProvider[0].toUpperCase() + loginProvider.substring(1) }}
                />
              </ConnectButton>
            ))}
            <ConnectButton
              disabled={isConnecting}
              onClick={connectWalletConnect}
              className={gaClassName('login-wallet-connect')}
            >
              <Icon icon="wallet" size="24px" />
              <ConnectButtonText translationKey="wallet.connect.button-connect" values={{ wallet: 'Wallet' }} />
            </ConnectButton>
            <DebugArea>
              <ConnectButton
                disabled={isConnecting}
                onClick={connectMetamask}
                className={gaClassName('login-metamask')}
              >
                <ConnectButtonLogoLogo src={metamaskLogo} />
                <ConnectButtonText
                  translationKey="wallet.connect.button-social-login"
                  values={{ wallet: 'Metamask' }}
                />
              </ConnectButton>
            </DebugArea>
          </ConnectButtonsWrapper>
          <WarningSection />
          <DisclaimerSection />
        </ModalContent>
      )}
    </Modal>
  );
}
