import { useSetAtom } from 'jotai';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { gaClassName } from 'utils/gtmEventUtils';

export default function AuthenticateBlock(): JSX.Element {
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);

  return (
    <ModalCard image="coin">
      <ModalCard.Title translationKey="referral.authenticate.title" />
      <ModalCard.Subtitle translationKey="referral.authenticate.description" />
      <ModalCard.Action>
        <Button
          variant="secondary"
          onClick={() => setIsConnectWalletModalOpen(true)}
          className={gaClassName('referral-modal-login')}
        >
          <LocalizedText category="body1" translationKey="referral.authenticate.button-login" />
        </Button>
      </ModalCard.Action>
    </ModalCard>
  );
}
