import styled from 'styled-components';
import ActionListItem from 'components/ActionListItem';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import { gaClassName } from 'utils/gtmEventUtils';

const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  position: relative;
`;

const EditButton = styled(IconButton).attrs({
  variant: 'secondary',
  icon: 'edit',
  buttonSize: '40px',
  iconSize: '24px',
})`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

interface Props {
  isSpending: boolean;
  spendAmount: string;
  coffeeShopName: string;
  onSpendToken: () => Promise<void>;
  onEditAmount: () => void;
}

export default function SpendTokenBlock(props: Props): JSX.Element {
  const { isSpending, spendAmount, coffeeShopName, onSpendToken, onEditAmount } = props;

  return (
    <ModalCard image="coin" align="center">
      <ModalCard.Heading translationKey="spend-token.title" />
      <ModalCard.Description translationKey="spend-token.description" />
      <FieldContainer>
        <ActionListItem>
          <ActionListItem.Label translationKey="spend-token.shop-label" />
          <ActionListItem.Value>{coffeeShopName}</ActionListItem.Value>
        </ActionListItem>
        <ActionListItem
          action={<EditButton onClick={onEditAmount} className={gaClassName('spend-modal-edit-amount-attempt')} />}
        >
          <ActionListItem.Label translationKey="spend-token.spend-amount-label" />
          <ActionListItem.Value>{spendAmount}</ActionListItem.Value>
        </ActionListItem>
      </FieldContainer>
      <ModalCard.Action>
        <Button
          variant="secondary"
          onClick={onSpendToken}
          disabled={isSpending}
          className={gaClassName('spend-modal-confirm')}
        >
          <LocalizedText category="body1" weight="medium" translationKey="spend-token.button-confirm" />
        </Button>
      </ModalCard.Action>
    </ModalCard>
  );
}
