import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WELCOME_REWARD_PARAM_KEY } from 'constants/searchParams';
import useSelfInfo from './useSelfInfo';

export default function useSetIsWelcomeRewardModalOpen(): (isOpen: boolean, qrCodeIdentifier?: string) => void {
  const { isLoading: isLoadingSelfInfo, selfInfo, reloadSelfInfo } = useSelfInfo();
  const [, setSearchParams] = useSearchParams();

  const setIsModalOpen = useCallback(
    (isOpen: boolean, qrCodeIdentifier?: string) => {
      if (isOpen && !isLoadingSelfInfo && selfInfo?.isWelcomeRewardClaiming) {
        reloadSelfInfo();
      }

      setSearchParams((prev) => {
        if (isOpen) {
          prev.set(WELCOME_REWARD_PARAM_KEY, qrCodeIdentifier || 'open');
        } else {
          prev.delete(WELCOME_REWARD_PARAM_KEY);
        }
        return prev;
      });
    },
    [isLoadingSelfInfo, selfInfo?.isWelcomeRewardClaiming, setSearchParams, reloadSelfInfo]
  );

  return setIsModalOpen;
}
