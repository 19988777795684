import styled from 'styled-components';
import LocalizedText, { TranslationKey } from 'components/LocalizedText';

const Container = styled.div``;

export interface Props {
  message: TranslationKey | string;
}

export default function Toast(props: Props): JSX.Element {
  const { message } = props;
  return (
    <Container>
      <LocalizedText category="body1" variant="secondary">
        {message}
      </LocalizedText>
    </Container>
  );
}
