import { WelcomeRewardStep } from './formStep';
import LoadingBlock from './LoadingBlock';
import ProcessingBlock from './ProcessingBlock';
import SuccessBlock from './SuccessBlock';
import VerifiedBlock from './VerifiedBlock';
import VerifyMobileBlock from './VerifyMobileBlock';

interface Props {
  formStep: WelcomeRewardStep;
  onCloseModal: () => void;
}

export default function WelcomeRewardContent(props: Props): JSX.Element {
  const { formStep, onCloseModal } = props;

  switch (formStep) {
    case WelcomeRewardStep.LOADING:
      return <LoadingBlock />;
    case WelcomeRewardStep.VERIFY_MOBILE:
      return <VerifyMobileBlock />; // include verifying OTP
    case WelcomeRewardStep.MOBILE_VERIFIED:
      return <VerifiedBlock />;
    case WelcomeRewardStep.CLAIMING_REWARD:
      return <ProcessingBlock onComplete={onCloseModal} />;
    case WelcomeRewardStep.COMPLETE:
      return <SuccessBlock onComplete={onCloseModal} />;
  }
}
