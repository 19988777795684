import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import { URL_ADAM_VAULT } from 'constants/url';
import { gaClassName } from 'utils/gtmEventUtils';
import { SectionContainer } from './SectionContainer';

const Footnote = styled(LocalizedText).attrs({ variant: 'primary', category: 'caption' })`
  display: inline-block;
  text-align: center;
`;

const AdamVaultLink = styled.a.attrs({ href: URL_ADAM_VAULT, target: '_blank' })`
  text-decoration: underline;
  color: ${({ theme: { color } }) => color.primary};

  &:hover {
    opacity: 0.5;
  }
`;

export default function FooterDisclaimerSection(): JSX.Element | null {
  return (
    <SectionContainer>
      <Footnote
        translationKey="home.footer.copy-right"
        components={[<AdamVaultLink className={gaClassName('home-page-adam-vault')} />]}
      />
    </SectionContainer>
  );
}
