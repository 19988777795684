import { useForm } from '@adam-vault/adam-frontend-shared';
import { useSetAtom } from 'jotai';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import Button from 'components/Button';
import Checkbox from 'components/form/Checkbox';
import OTPTextInputField from 'components/form/OTPTextInputField';
import PhoneNumberInputField from 'components/form/PhoneNumberInputField';
import TextInputField from 'components/form/TextInputField';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import { ModalCard, ModalStep } from 'components/Modal';
import { CustomError } from 'constants/error/CustomError';
import useTimer from 'hooks/useTimer';
import useVerifyMobile, { MobileVerificationFormData } from 'hooks/useVerifyMobile';
import useWelcomeRewardReferralCode from 'hooks/useWelcomeRewardReferralCode';
import { selfInfoAtom } from 'store/selfInfoAtom';
import { gaClassName } from 'utils/gtmEventUtils';

const COUNTDOWN_SECONDS = 60;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const ReferralCode = styled(LocalizedText).attrs({ category: 'caption' })`
  color: ${({ theme }) => theme.color.tinted};
`;

const ReferralCodeButton = styled.button`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.tinted};
  margin-bottom: 12px;
`;

const ReferralCodeInputField = styled(TextInputField)`
  width: 100%;
`;

const OTPLoadingContainer = styled(ModalCard.Action)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 120px;
`;

const ResendText = styled(LocalizedText).attrs({ category: 'body2' })`
  opacity: 0.4;
  margin-bottom: 8px;
`;

const ResendButton = styled.button`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export default function VerifyMobileBlock(): JSX.Element {
  const setSelfInfo = useSetAtom(selfInfoAtom);
  const [isSubmittedPhoneNumber, setIsSubmittedPhoneNumber] = useState(false);
  const [isShowReferralCodeInput, setIsShowReferralCodeInput] = useState(false);
  const { referralCode, setReferralCode } = useWelcomeRewardReferralCode();
  const { t } = useTranslation();
  const { isLoading, formData, verifyPhoneNumber, verifyOTP, resendOTP } = useVerifyMobile({
    onSubmitPhoneNumber() {
      setIsSubmittedPhoneNumber(true);
    },
    onSubmitOTP() {
      setSelfInfo((info) => (info ? { ...info, isMobileVerified: true } : info));
    },
  });

  const { timeLeft, countDown } = useTimer(COUNTDOWN_SECONDS);

  useEffect(() => {
    if (timeLeft !== COUNTDOWN_SECONDS) {
      return;
    }

    if (isSubmittedPhoneNumber) {
      countDown();
    }
  }, [timeLeft, countDown, isSubmittedPhoneNumber]);

  const schema = yup.object({
    countryCode: yup.string().required(),
    phone: yup.string().when(['countryCode'], (countryCodeString: string) =>
      yup
        .string()
        .required(t(CustomError.INVALID_PHONE_NUMBER.message))
        .test(
          'phone',
          t(CustomError.INVALID_PHONE_NUMBER.message) || '',
          (value) => !!value && isPossiblePhoneNumber(`+${countryCodeString}${value}`)
        )
    ),
    isAllowMarketing: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MobileVerificationFormData>({
    schema,
    mode: 'onSubmit',
    defaultValues: {
      countryCode: '852',
      phone: '',
      isAllowMarketing: false,
    },
  });

  const onSubmitPhoneNumber = handleSubmit(verifyPhoneNumber);
  const onResetPhoneNumber = (): void => reset({ phone: '' });

  const onClickReferralCode = (): void => {
    setIsShowReferralCodeInput(true);
  };

  const onChangeReferralCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReferralCode(e.target.value);
  };

  const onSubmitOTP = (otp: string): Promise<boolean> => verifyOTP(otp, referralCode);

  if (isSubmittedPhoneNumber && formData) {
    const onResendCodeClick = async (): Promise<void> => {
      countDown();
      resendOTP();
    };

    return (
      <>
        <ModalCard align="left">
          <ModalStep label="welcome-reward.title" step={2} totalStep={3} />
          <ModalCard.Heading translationKey="welcome-reward.verify.title" />
          <ModalCard.Message
            translationKey="welcome-reward.verify.otp-message"
            values={{ phone: `+${formData.countryCode} ${formData.phone}` }}
            components={[<u />]}
          />
          <OTPTextInputField onOTPSubmit={onSubmitOTP} disabled={isLoading} />
          <OTPLoadingContainer>{isLoading && <LoadingSpinner />}</OTPLoadingContainer>
        </ModalCard>
        <ResendText
          translationKey="welcome-reward.verify.otp-resend-instruction"
          values={{ seconds: timeLeft.toString() }}
          components={[<ResendButton onClick={onResendCodeClick} disabled={timeLeft !== 0 || isLoading} />]}
        />
      </>
    );
  }

  return (
    <>
      <ModalCard align="left">
        <ModalStep label="welcome-reward.title" step={2} totalStep={3} />
        <ModalCard.Heading translationKey="welcome-reward.verify.title" />
        <ModalCard.Message translationKey="welcome-reward.verify.description" />
        <FormContainer>
          <PhoneNumberInputField
            name="phone"
            countryCodeName="countryCode"
            control={control}
            errorMessage={errors.phone?.message}
            onReset={onResetPhoneNumber}
            placeholder={t('welcome-reward.verify.placeholder-phone-number')}
          />
          {isShowReferralCodeInput ? (
            <ReferralCodeInputField
              value={referralCode || ''}
              onChange={onChangeReferralCode}
              placeholder={t('welcome-reward.verify.placeholder-referral-code')}
            />
          ) : referralCode ? (
            <ReferralCode translationKey="welcome-reward.verify.referral-code" values={{ referralCode }} />
          ) : (
            <ReferralCodeButton onClick={onClickReferralCode}>
              <LocalizedText category="caption" translationKey="welcome-reward.verify.button-referral" />
            </ReferralCodeButton>
          )}
          <Checkbox
            name="isAllowMarketing"
            control={control}
            label="welcome-reward.verify.marketing-agreement"
            variant="secondary"
          />
        </FormContainer>
        <ModalCard.Action>
          <Button
            variant="secondary"
            onClick={onSubmitPhoneNumber}
            disabled={isLoading}
            type="submit"
            className={gaClassName('claim-modal-confirm')}
          >
            <LocalizedText category="body1" weight="medium" translationKey="welcome-reward.verify.button-confirm" />
          </Button>
          {isLoading && <LoadingSpinner />}
        </ModalCard.Action>
      </ModalCard>
      <ModalCard.Footnote translationKey="welcome-reward.verify.disclaimer" />
    </>
  );
}
