import { Env } from '@adam-vault/adam-sdk';
import { UserRole } from '@adam-vault/adam-sdk-coffeedao';
import { useMemo } from 'react';
import UserType from 'constants/userType';
import useCoffeeShops from './useCoffeeShopList';
import useSelfInfo from './useSelfInfo';
import useWeb3 from './useWeb3';

const MOCK_COFFEE_SHOP_EOA_DEV = '0x0D936606eDe25f9746C5127f957bcE12a825c938';
const MOCK_COFFEE_SHOP_EOA_QA = '0xA16b22E0967D10fee7a383f1579957C94bAAd50e';

export default function useUserType(): UserType {
  const { selfInfo } = useSelfInfo();
  const { address } = useWeb3();
  const { coffeeShops } = useCoffeeShops();

  const userType = useMemo(() => {
    if (
      process.env.REACT_APP_ENV === Env.DEV &&
      (address === MOCK_COFFEE_SHOP_EOA_DEV || address === MOCK_COFFEE_SHOP_EOA_QA)
    ) {
      return UserType.COFFEE_SHOP;
    }

    const coffeeShopFound = coffeeShops.find((coffeeShop) => coffeeShop.eoaAddress === address);
    if (coffeeShopFound) {
      return UserType.COFFEE_SHOP;
    }

    if (!selfInfo?.userRole) {
      return UserType.GUEST;
    }

    const parsedUserType = {
      [UserRole.MERCHANT]: UserType.COFFEE_SHOP,
      [UserRole.CUSTOMER]: UserType.COFFEE_DRINKER,
      [UserRole.ADMIN]: UserType.GUEST, // TODO
    }[selfInfo.userRole] as UserType;

    return parsedUserType || UserType.COFFEE_DRINKER;
  }, [address, coffeeShops, selfInfo?.userRole]);

  return userType;
}
