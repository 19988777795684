import { useCallback } from 'react';
import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router-dom';
import useLanguage from './useLanguage';

export default function useNavigateWithLang(): NavigateFunction {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return useCallback(
    (toOrDelta: To | number, options?: NavigateOptions) => {
      if (typeof toOrDelta === 'number') {
        const delta = toOrDelta;
        navigate(delta);
      } else if (typeof toOrDelta === 'object') {
        const to = toOrDelta;
        const { pathname, search, hash } = to;
        if (pathname && pathname.startsWith('/')) {
          navigate({ pathname: `/${language}${pathname}`, search, hash }, options);
        } else {
          navigate(to, options);
        }
      } else if (typeof toOrDelta === 'string') {
        const to = toOrDelta;
        if (to && to.startsWith('/')) {
          navigate(`/${language}${to}`, options);
        } else {
          navigate(to, options);
        }
      }
    },
    [language, navigate]
  );
}
