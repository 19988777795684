import { BigNumber } from 'ethers';
import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { coffeeDaoTokenBalanceBNAtom } from 'store/tokenAtom';
import { toast } from 'utils/toastUtils';
import useCoffeeDaoToken from './useCoffeeDaoToken';
import useWeb3 from './useWeb3';

export interface CoffeeDaoTokenBalance {
  balanceBN: BigNumber | undefined;
  balanceDisplay: string;
}

type Return = CoffeeDaoTokenBalance & {
  decimals: number | undefined;
  refreshBalance: () => void;
  isLoading: boolean;
};

export default function useCoffeeDaoTokenBalance(): Return {
  const [balanceBN, setBalanceBN] = useAtom(coffeeDaoTokenBalanceBNAtom);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const { isConnected, address: eoaAddress } = useWeb3();
  const { token, decimals, toDisplay, isLoading: isLoadingToken } = useCoffeeDaoToken();

  const balanceDisplay = useMemo(() => toDisplay(balanceBN), [balanceBN, toDisplay]);

  const refreshBalance = useCallback(async () => {
    if (!isConnected || !eoaAddress || !token || isLoadingToken) {
      setBalanceBN(undefined);
      return;
    }

    try {
      setIsLoadingBalance(true);
      setBalanceBN(await token.balanceOf(eoaAddress));
    } catch {
      toast(CustomError[CustomErrorType.FETCH_COFFEE_TOKEN_BALANCE_FAIL].message);
    } finally {
      setIsLoadingBalance(false);
    }
  }, [eoaAddress, isConnected, isLoadingToken, setBalanceBN, token]);

  return {
    decimals,
    balanceBN,
    balanceDisplay,
    refreshBalance,
    isLoading: isLoadingToken || isLoadingBalance,
  };
}
