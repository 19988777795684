import { Html5Qrcode } from 'html5-qrcode';
import { Html5QrcodeCameraScanConfig, Html5QrcodeFullConfig } from 'html5-qrcode/esm/html5-qrcode';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { toast } from 'utils/toastUtils';

export const qrCodeConfig: Html5QrcodeFullConfig = {
  verbose: false,
};

export const qrCodeScannerConfig: Html5QrcodeCameraScanConfig = {
  fps: 24,
};

export const handleStartScannerError = (): void => {
  toast(CustomError[CustomErrorType.START_QR_CODE_SCANNER_FAIL].message, {
    toastId: CustomErrorType.START_QR_CODE_SCANNER_FAIL,
  });
};

export const handleStopScannerError = (): void => {
  toast(CustomError[CustomErrorType.STOP_QR_CODE_SCANNER_FAIL].message, {
    toastId: CustomErrorType.STOP_QR_CODE_SCANNER_FAIL,
  });
};

export const handleInvalidQRCodeError = (): void => {
  toast(CustomError[CustomErrorType.INVALID_QR_CODE].message, { toastId: CustomErrorType.INVALID_QR_CODE });
};

export const qrCodeSuccessCallback = (decodedText: string): void => {
  try {
    const url = new URL(decodedText);
    if (!process.env.REACT_APP_HOSTNAME || url.hostname !== process.env.REACT_APP_HOSTNAME) {
      handleInvalidQRCodeError();
      return;
    }
    window.location.href = url.href;
    sendGTMEvent(GTMCustomEvent.ScanQRCodeSuccess, 'link', url.href);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    // eslint-disable-next-line no-console
    console.log(decodedText);
    handleInvalidQRCodeError();
  }
};

export const qrCodeErrorCallback = (): void => undefined;

export type CameraStatus = 'stopped' | 'starting' | 'started';

export type QrCodeCameraStartCallback = (options?: {
  successCallback?: (decodedText: string) => void;
  errorCallback?: () => void;
}) => Promise<void>;

export type QrCodeScannerReturn = {
  startCamera: QrCodeCameraStartCallback;
  stopCamera: () => Promise<void>;
  scanImage: (imageFile: File) => void;
  cameraStatus: CameraStatus;
  QRCodeCameraScanner: React.FC;
  QRCodeImageScanner: React.FC;
};

const CameraScanner = styled.div`
  width: 100%;
`;

const ImageScanner = styled.div`
  position: absolute;
`;

interface Props {
  cameraId: string;
  imageId: string;
}

const defaultStartCameraOption = {
  successCallback: qrCodeSuccessCallback,
  errorCallback: qrCodeErrorCallback,
};

export default function useQrCodeScanner(props: Props): QrCodeScannerReturn {
  const { cameraId, imageId } = props;

  const [cameraStatus, setCameraStatus] = useState<CameraStatus>('stopped');
  const [stopCamera, setStopCamera] = useState<() => Promise<void>>(() => async () => {});

  const QRCodeCameraScanner = useCallback(() => <CameraScanner id={cameraId} />, [cameraId]);
  const QRCodeImageScanner = useCallback(() => <ImageScanner id={imageId} />, [imageId]);

  const startCamera = useCallback<QrCodeCameraStartCallback>(
    async (options = defaultStartCameraOption) => {
      const { successCallback = qrCodeSuccessCallback, errorCallback = qrCodeErrorCallback } = options;
      if (!cameraId) {
        handleStartScannerError();
        return;
      }

      const html5QrCode = new Html5Qrcode(cameraId, qrCodeConfig);

      try {
        setCameraStatus('starting');
        await html5QrCode.start({ facingMode: 'environment' }, qrCodeScannerConfig, successCallback, errorCallback);
        setCameraStatus('started');
        setStopCamera(() => async () => {
          if (html5QrCode.isScanning) {
            await html5QrCode.stop();
          }
          setCameraStatus('stopped');
        });
      } catch {
        handleStartScannerError();
        setCameraStatus('stopped');
      }
    },
    [cameraId]
  );

  const scanImage = useCallback(
    (imageFile: File) => {
      if (!imageId) {
        handleStartScannerError();
        return;
      }
      const html5QrCode = new Html5Qrcode(imageId, false);

      html5QrCode.scanFile(imageFile).then(qrCodeSuccessCallback).catch(handleInvalidQRCodeError);
    },
    [imageId]
  );

  return {
    startCamera,
    stopCamera,
    scanImage,
    cameraStatus,
    QRCodeCameraScanner,
    QRCodeImageScanner,
  };
}
