import { useAtom } from 'jotai';
import { Modal } from 'components/Modal';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { referralRewardModalAtom } from 'store/referralAtom';
import { gaClassName } from 'utils/gtmEventUtils';
import AuthenticateBlock from './AuthenticateBlock';
import ReferralRewardBlock from './ReferralRewardBlock';
import ClaimHeader from '../WelcomeRewardManager/ClaimHeader';

export default function ReferralManager(): JSX.Element {
  const [isReferralTokenModalOpen, setIsReferralRewardModalOpen] = useAtom(referralRewardModalAtom);
  const { isAuthenticated } = useIsAuthenticated();

  const closeReferralModal = (): void => {
    setIsReferralRewardModalOpen(false);
  };

  return (
    <Modal isOpen={isReferralTokenModalOpen} onRequestClose={closeReferralModal}>
      <ClaimHeader onClose={closeReferralModal} closeButtonClassName={gaClassName('referral-modal-close')} />
      {isAuthenticated ? <ReferralRewardBlock /> : <AuthenticateBlock />}
    </Modal>
  );
}
