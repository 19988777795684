import QRCodeStyling from 'qr-code-styling';
import { useEffect, useMemo, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { coffeeDaoWhiteIcon, coffeeDaoFloralIcon } from './images';

const Container = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width + 16}px;
  height: ${({ height }) => height + 16}px;
  padding: 8px;
  border-radius: 16px;
  background-color: ${({ theme: { color } }) => color.modalBackground};
`;

interface Props {
  data: string;
  className?: string;
  width?: number;
  height?: number;
  variant?: 'drinker' | 'shop';
}

export default function QrCode({
  data,
  className,
  width = 128,
  height = 128,
  variant = 'drinker',
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        type: 'svg',
        width,
        height,
        margin: 0,
        image: variant === 'shop' ? coffeeDaoFloralIcon : coffeeDaoWhiteIcon,
        data,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'H',
        },
        dotsOptions: {
          color: theme.color[variant],
          type: 'extra-rounded',
        },
        cornersSquareOptions: {
          color: theme.color[variant],
          type: 'extra-rounded',
        },
        imageOptions: {
          imageSize: 0.4,
          crossOrigin: 'anonymous',
          margin: 2,
        },
      }),
    [data, height, theme.color, variant, width]
  );

  useEffect(() => {
    if (ref.current) {
      if (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
      qrCode.append(ref.current);
    }
  }, [qrCode]);

  return <Container width={width} height={height} className={className} ref={ref} />;
}
