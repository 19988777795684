import styled from 'styled-components';
import IconButton from 'components/IconButton';
import { CoffeeDaoLogo, ArborNewsLogo } from 'components/Logo';

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

interface Props {
  logo?: 'primary' | 'secondary';
  showArborLogo?: boolean;
  onClose?: () => void;
  closeButtonClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

export default function ModalHeader({
  logo = 'primary',
  showArborLogo,
  onClose,
  closeButtonClassName,
  className,
  style,
}: Props): JSX.Element {
  return (
    <Header className={className} style={style}>
      <CoffeeDaoLogo variant={logo} height={showArborLogo ? '26px' : '32px'} />
      {showArborLogo && <ArborNewsLogo height="26px" />}
      {onClose && (
        <CloseButton
          icon="close"
          iconColor={logo}
          buttonColor="transparent"
          onClick={onClose}
          className={closeButtonClassName}
          withoutBorder
        />
      )}
    </Header>
  );
}
