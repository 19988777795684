import { Link } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImg from 'assets/images/image_know-more-card-background.svg';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import useLanguage from 'hooks/useLanguage';
import { gaClassName } from 'utils/gtmEventUtils';
import { SectionContainer, SectionTitle } from './SectionContainer';

const StyledCard = styled(Card)`
  flex-direction: column;
  padding: 24px 18px 28px 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BackgroundImg = styled.img.attrs({ src: backgroundImg })`
  position: absolute;
  top: 0;
  right: 0;
`;

export default function LearnMoreSection(): JSX.Element | null {
  const { language } = useLanguage();

  return (
    <SectionContainer>
      <SectionTitle translationKey="home.learn-more.title" />
      <StyledCard>
        <BackgroundImg />
        <LocalizedText category="h2" variant="secondary" translationKey="home.learn-more.subtitle" />
        <LocalizedText category="body2" variant="secondary" translationKey="home.learn-more.description" />
        <Link to={language === 'zh' ? 'https://coffeedao.net/zh/' : 'https://coffeedao.net/'} target="_blank">
          <Button variant="secondary" className={gaClassName('home-page-learn-more')}>
            <LocalizedText category="body1" translationKey="home.learn-more.button" />
            <Icon icon="arrow_nav" size="16px" />
          </Button>
        </Link>
      </StyledCard>
    </SectionContainer>
  );
}
