import { CoffeeDao } from '@adam-vault/adam-sdk-coffeedao';
import { useCallback } from 'react';

export default function useGetUserName(): (address: string) => Promise<string> {
  const getUserName = useCallback<(address: string) => Promise<string>>(async (address: string) => {
    if (!address) {
      return '';
    }

    return (await CoffeeDao.getUsers(process.env.REACT_APP_ENV, address))?.[0]?.name || '';
  }, []);

  return getUserName;
}
