import { SetStateAction, useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { SelfInfo, selfInfoAtom, loadMyselfAtom, isWelcomeRewardClaimingAtom } from 'store/selfInfoAtom';
import useLoadableAtom from './useLoadableAtom';

type Return = {
  selfInfo: SelfInfo | null | undefined;
  isLoading: boolean;
  error?: Error;
  setSelfInfo: (update?: SetStateAction<SelfInfo | null | undefined>) => void;
  reloadSelfInfo: () => void;
  clearSelfInfo: () => void;
};

export default function useSelfInfo(): Return {
  const [existingSelfInfo, setExistingSelfInfo] = useAtom(selfInfoAtom);
  const reloadSelfInfo = useSetAtom(loadMyselfAtom);
  const { isLoading: isLoadingMyself, data: myself, error: loadMySelfError } = useLoadableAtom(loadMyselfAtom);
  const {
    isLoading: isLoadingWelcomeRewardClaiming,
    data: isWelcomeRewardClaiming,
    error: isWelcomeRewardClaimingError,
  } = useLoadableAtom(isWelcomeRewardClaimingAtom);

  const clearSelfInfo = (): void => {
    setExistingSelfInfo(null);
  };

  useEffect(() => {
    if (loadMySelfError || isWelcomeRewardClaimingError) {
      return;
    }
    if (myself && !isLoadingMyself && !isLoadingWelcomeRewardClaiming) {
      setExistingSelfInfo({ ...myself, isWelcomeRewardClaiming });
    }
  }, [
    loadMySelfError,
    isLoadingMyself,
    myself,
    setExistingSelfInfo,
    isWelcomeRewardClaimingError,
    isLoadingWelcomeRewardClaiming,
    isWelcomeRewardClaiming,
  ]);

  return {
    selfInfo: existingSelfInfo ?? (myself && { ...myself, isWelcomeRewardClaiming }),
    isLoading: isLoadingMyself || isLoadingWelcomeRewardClaiming,
    error: loadMySelfError || isWelcomeRewardClaimingError,
    setSelfInfo: setExistingSelfInfo,
    reloadSelfInfo,
    clearSelfInfo,
  };
}
