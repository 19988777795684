import styled from 'styled-components';
import { Token } from 'types/Token';

export interface Props {
  className?: string;
  hasError?: boolean;
  token?: Token;
  children: React.ReactNode;
}

const getPaddingCss = (token: Props['token']): string =>
  !!token ? 'padding: 10px 24px 10px 14px' : 'padding: 12px 24px';

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};
  border: ${({ theme: { color }, hasError }) => (hasError ? `1px solid ${color.error}` : '')};

  ${({ token }): string => getPaddingCss(token)}
`;

export default function InputWrapper(props: Props): JSX.Element {
  const { className, hasError, children } = props;
  return (
    <Container className={className} hasError={hasError}>
      {children}
    </Container>
  );
}
