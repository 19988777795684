import styled from 'styled-components';
import Icon from 'components/Icon';
import { pseudoBackgroundCSS, starImage } from 'components/images';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

interface Props {
  onClick: () => void;
}

const WelcomeCardContainer = styled(Card)<{ disabled?: boolean }>`
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 20px;
  gap: 6px;
  position: relative;

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const Title = styled(LocalizedText).attrs({ category: 'h3', variant: 'secondary', weight: 'medium' })`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  &::before {
    content: '';
    ${pseudoBackgroundCSS(starImage, '12px', '12px')}
  }
`;

const QrCodeIcon = styled(Icon).attrs({ icon: 'qr-code', size: '48px', color: 'secondary' })`
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
`;

export default function QrCodeCard({ onClick }: Props): JSX.Element {
  return (
    <WelcomeCardContainer onClick={onClick} className={gaClassName('home-page-login')} as="button">
      <Title translationKey="home.welcoming.title-pre-login" />
      <QrCodeIcon />
    </WelcomeCardContainer>
  );
}
