import { useEffect, useState, useCallback } from 'react';

type ReturnType = {
  isCounting: boolean;
  timeLeft: number;
  clearTimer: () => void;
  countDown: () => void;
  resetTimer: () => void;
};

export default function useTimer(countDownSeconds: number): ReturnType {
  const [isCounting, setIsCounting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(countDownSeconds);
  const [timerId, setTimerId] = useState<NodeJS.Timer | undefined>(undefined);

  const clearTimer = useCallback(() => {
    if (!timerId) {
      return;
    }
    clearInterval(timerId);
    setTimerId(undefined);
    setIsCounting(false);
  }, [timerId]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      clearTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      clearTimer();
    }
  }, [clearTimer, timeLeft]);

  const countDown = useCallback(() => {
    if (!!timerId) {
      return;
    }
    setTimeLeft(countDownSeconds);
    const timer = setInterval(() => {
      setTimeLeft((previous) => previous - 1);
    }, 1000);
    setTimerId(timer);
    setIsCounting(true);
  }, [timerId, countDownSeconds]);

  const resetTimer = useCallback(() => {
    clearTimer();
    countDown();
  }, [clearTimer, countDown]);

  return {
    isCounting,
    timeLeft,
    clearTimer,
    countDown,
    resetTimer,
  };
}
