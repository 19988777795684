import styled from 'styled-components';
import imageCoinWithStars from 'assets/images/image_coin_with_stars.svg';
import Button from 'components/Button';
import { TokenAmountCard } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'h1',
})`
  text-align: inherit;
  white-space: pre-line;
`;

const CoinWithStarsImage = styled.img.attrs({ src: imageCoinWithStars })``;

const Description = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'body2',
})`
  text-align: center;
`;

interface Props {
  spendAmount: string;
  coffeeShopName: string;
  onComplete: () => void;
}

export default function SuccessBlock({ spendAmount, coffeeShopName, onComplete }: Props): JSX.Element {
  return (
    <Container>
      <CoinWithStarsImage />
      <Header>
        <Title translationKey="spend-token.success.title" />
      </Header>
      <TokenAmountCard amount={spendAmount} translationKey="spend-token.success.subtitle" values={{ coffeeShopName }} />
      <Description translationKey="spend-token.success.description" />
      <Button variant="secondary" onClick={onComplete} className={gaClassName('claim-modal-complete')}>
        <LocalizedText category="body1" weight="medium" translationKey="spend-token.success.button-escape" />
      </Button>
    </Container>
  );
}
