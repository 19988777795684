import { useState } from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import PATHS from 'constants/path';
import { TransactionFilterType } from 'constants/transaction';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSetIsWelcomeRewardModalOpen from 'hooks/useSetIsWelcomeRewardModalOpen';
import { TransactionRecord } from 'types/TransactionRecord';
import TransactionRecordBlock from './TransactionRecordBlock';
import { TransactionRecordModal } from './TransactionRecordModal';

interface RecordListProps {
  records: TransactionRecord[];
  isLoading: boolean;
  selectedTransactionType: TransactionFilterType;
  onClickClaimReward?: () => void;
  onClickSpendToken?: () => void;
}

const RecordContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoRecordContainer = styled(RecordContainer)`
  padding: 36px;
  text-align: center;
`;

const LoadingContainer = styled(RecordContainer)`
  padding: 36px;
  justify-content: center;
  align-items: center;
`;

const NoRecordLink = styled.button`
  color: inherit;
  text-decoration: underline;
`;

export default function TransactionRecordList(props: RecordListProps): JSX.Element {
  const { records, isLoading, selectedTransactionType } = props;
  const [selectedTransactionRecord, setSelectedTransactionRecord] = useState<TransactionRecord>();
  const [isOpenTransactionRecordModal, setIsOpenTransactionRecordModal] = useState<boolean>(false);
  const { isLoading: isLoadingBalance, balanceBN, decimals } = useCoffeeDaoTokenBalance();
  const setIsWelcomeRewardModalOpen = useSetIsWelcomeRewardModalOpen();
  const navigate = useNavigateWithLang();

  const onClickWelcomeRewards = (): void => {
    setIsWelcomeRewardModalOpen(true);
  };

  const onClickSpendToken = (): void => {
    navigate(`/${PATHS.SCANNER}`);
  };

  if (isLoading || isLoadingBalance || !decimals) {
    return (
      <LoadingContainer>
        <LoadingSpinner variant="secondary" />
      </LoadingContainer>
    );
  }

  if (records.length === 0) {
    switch (selectedTransactionType) {
      case TransactionFilterType.ALL:
        return (
          <NoRecordContainer>
            <LocalizedText category="h2" translationKey="transactions.empty.title" />
          </NoRecordContainer>
        );
      case TransactionFilterType.SPEND:
        return (
          <NoRecordContainer>
            <LocalizedText category="h2" translationKey="transactions.empty.title" />
            {balanceBN?.isZero() && (
              <LocalizedText
                category="body1"
                translationKey="transactions.empty.description-earn"
                components={[<NoRecordLink onClick={onClickWelcomeRewards} />]}
              />
            )}
            {!balanceBN?.isZero() && (
              <LocalizedText
                category="body1"
                translationKey="transactions.empty.description-spend"
                components={[<NoRecordLink onClick={onClickSpendToken} />]}
              />
            )}
          </NoRecordContainer>
        );
      case TransactionFilterType.EARN:
        return (
          <NoRecordContainer>
            <LocalizedText category="h2" translationKey="transactions.empty.title" />
            <LocalizedText
              category="body1"
              translationKey="transactions.empty.description-earn"
              components={[<NoRecordLink onClick={onClickWelcomeRewards} />]}
            />
          </NoRecordContainer>
        );
      case TransactionFilterType.PROCESSING:
        return (
          <NoRecordContainer>
            <LocalizedText category="h2" translationKey="transactions.empty.title" />
            {!balanceBN?.isZero() && (
              <LocalizedText
                category="body1"
                translationKey="transactions.empty.description-spend"
                components={[<NoRecordLink onClick={onClickSpendToken} />]}
              />
            )}
          </NoRecordContainer>
        );
    }
  }

  const onClickRecordBlock = (transactionRecord: TransactionRecord): void => {
    if (selectedTransactionType !== TransactionFilterType.PROCESSING) {
      setSelectedTransactionRecord(transactionRecord);
      setIsOpenTransactionRecordModal(true);
    }
  };

  const onTransactionRecordModalRequestClose = (): void => {
    setSelectedTransactionRecord(undefined);
    setIsOpenTransactionRecordModal(false);
  };

  return (
    <>
      <RecordContainer>
        {records.map((record: TransactionRecord, index) => (
          <TransactionRecordBlock
            key={`${record.transactionHash}-${index}`}
            transactionRecord={record}
            onClickRecordBlock={onClickRecordBlock}
          />
        ))}
      </RecordContainer>
      {selectedTransactionRecord && (
        <TransactionRecordModal
          isOpenTransactionRecordModal={
            isOpenTransactionRecordModal && selectedTransactionType !== TransactionFilterType.PROCESSING
          }
          onTransactionRecordModalRequestClose={onTransactionRecordModalRequestClose}
          transactionRecord={selectedTransactionRecord}
        />
      )}
    </>
  );
}
