import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { CardNew, ShopPage } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { CoffeeTokenLogo } from 'components/Logo';
import { TransferTokenManager } from 'components/managers';
import { ShopCard, ShopWalletModal } from 'components/shop';
import TransactionList from 'components/transaction/TransactionList';
import PATHS from 'constants/path';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import useCoffeeDaoTokenTransactions from 'hooks/useCoffeeDaoTokenTransactions';
import useSelfInfo from 'hooks/useSelfInfo';
import useUserName from 'hooks/useUserName';

const TransferCard = styled(CardNew)`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const TransferText = styled(LocalizedText)`
  margin-right: auto;
`;

const ScanIcon = styled(Icon).attrs({ icon: 'scan', size: '24px', color: 'primary' })`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.color.pageBackground};
`;

const TransactionHeader = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function ShopHome(): JSX.Element {
  const { selfInfo } = useSelfInfo();
  const { username } = useUserName(selfInfo?.userAddress);
  const { balanceDisplay, refreshBalance } = useCoffeeDaoTokenBalance();
  const { records, isLoading, reloadRecords } = useCoffeeDaoTokenTransactions();
  const navigate = useNavigate();

  const [isShopWalletModalOpen, setIsShopWalletModalOpen] = useState(false);

  useEffect(() => {
    refreshBalance();
  }, [refreshBalance]);

  const onClickOpenShopWalletModal = (): void => {
    setIsShopWalletModalOpen(true);
  };

  const onClickCloseShopWalletModal = (): void => {
    setIsShopWalletModalOpen(false);
  };

  const onClickTransfer = (): void => {
    navigate(PATHS.SCANNER);
  };

  const onClickViewHistory = (): void => {
    navigate(PATHS.TRANSACTIONS);
  };

  return (
    <ShopPage>
      <ShopCard
        onClick={onClickOpenShopWalletModal}
        balance={balanceDisplay}
        username={username || selfInfo?.name || ''}
      />
      <TransferCard as="button" onClick={onClickTransfer}>
        <CoffeeTokenLogo height="48px" />
        <TransferText category="h3" translationKey="transfer.coffee-token-transfer-label" />
        <ScanIcon />
      </TransferCard>
      <TransactionHeader>
        <LocalizedText category="h2" color="primary" translationKey="transactions.latest-transaction-label" />
        <Button variant="text" onClick={onClickViewHistory}>
          <LocalizedText category="body1" color="primary" translationKey="transactions.button-view-all" />
        </Button>
      </TransactionHeader>
      <TransactionList records={records.slice(0, 10)} isLoading={isLoading} />
      <TransferTokenManager onCloseModal={reloadRecords} />
      <ShopWalletModal isOpen={isShopWalletModalOpen} onCloseModal={onClickCloseShopWalletModal} />
    </ShopPage>
  );
}
