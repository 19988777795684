import { ChainId, Web3, Web3ConnectionStatus, Web3Setter } from '@adam-vault/adam-frontend-shared';
import { useAtom } from 'jotai';
import { web3Atom } from 'store/web3Atom';

export type Web3Params = {
  platformSelectedChainId: ChainId;
  web3: Web3;
  setWeb3: (updatedWeb3: Web3Setter) => Promise<void>;
};

export default function useWeb3(): Web3 &
  Web3ConnectionStatus & { setWeb3: (updatedWeb3: Web3Setter) => Promise<void> } {
  const [web3, setWeb3] = useAtom(web3Atom);

  return { ...web3, setWeb3 };
}
