import { CoffeeShop, QrCode } from '@adam-vault/adam-sdk-coffeedao';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { coffeeShopsAtom } from './coffeeShopsAtom';

export const spendTokenQrCodeAtom = atom<QrCode | undefined>(undefined);

const editedSpendCoffeeShopAtom = atom<CoffeeShop | null>(null);
export const spendCoffeeShopAtom = atom(
  (get) => {
    const editedSpendCoffeeShop = get(editedSpendCoffeeShopAtom);
    if (editedSpendCoffeeShop) {
      return editedSpendCoffeeShop;
    }

    const destinationAddress = get(spendTokenQrCodeAtom)?.destinationAddress;
    const coffeeShops = get(coffeeShopsAtom);

    if (!destinationAddress || coffeeShops.state !== 'hasData' || coffeeShops.data.length === 0) {
      return null;
    }

    return (
      coffeeShops.data.find((member) => member.eoaAddress.toLowerCase() === destinationAddress.toLowerCase()) ?? null
    );
  },
  (_, set, newCoffeeShop: CoffeeShop | null) => {
    set(editedSpendCoffeeShopAtom, newCoffeeShop);
  }
);

export const DEFAULT_SPEND_AMOUNT = '1.00';
export const spendAmountAtom = atomWithReset<string>(DEFAULT_SPEND_AMOUNT);
