import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomError } from 'constants/error/CustomError';
import {
  ServerMobileRegistrationErrorType,
  ServerMobileRegistrationError,
} from 'constants/error/ServerMobileRegistrationError';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { toast } from 'utils/toastUtils';
import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';

export interface MobileVerificationFormData {
  countryCode: string;
  phone: string;
  isAllowMarketing?: boolean;
}

interface Props {
  onSubmitPhoneNumber: () => void;
  onSubmitOTP: () => void;
}

interface Return {
  isLoading: boolean;
  formData: MobileVerificationFormData | undefined;
  verifyPhoneNumber: (data: MobileVerificationFormData) => Promise<void>;
  verifyOTP: (otp: string, referralCode?: string) => Promise<boolean>;
  resendOTP: () => Promise<boolean>;
}

export default function useVerifyMobile(props: Props): Return {
  const { onSubmitPhoneNumber, onSubmitOTP } = props;
  const [formData, setFormData] = useState<MobileVerificationFormData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const coffeeDao = useCoffeeDao();
  const coffeeDaoClient = useCoffeeDaoClient();
  const { t } = useTranslation();

  const verifyPhoneNumber = useCallback(
    async (data: MobileVerificationFormData) => {
      if (!coffeeDao) {
        return;
      }
      try {
        setIsLoading(true);
        await coffeeDaoClient?.request(coffeeDao.requestOTP(`${data.countryCode}${data.phone}`));
        setFormData({ ...data });
        onSubmitPhoneNumber();
      } catch (err) {
        const error = err as unknown as Error;
        switch (error.message) {
          case ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED:
            toast(
              t(
                ServerMobileRegistrationError[ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED].message
              ) as string
            );
            return;
          case ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT:
            toast(
              t(
                ServerMobileRegistrationError[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT]
                  .message
              ) as string
            );
            return;
          default:
            toast(CustomError.GENERIC_ERROR.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [coffeeDao, coffeeDaoClient, onSubmitPhoneNumber, t]
  );

  const verifyOTP = useCallback(
    async (otp: string, referralCode?: string) => {
      if (!coffeeDao || !formData) {
        return false;
      }
      try {
        setIsLoading(true);
        await coffeeDaoClient?.request(
          coffeeDao?.verifyOTP(`${formData.countryCode}${formData.phone}`, otp, formData.isAllowMarketing, referralCode)
        );
        sendGTMEvent(GTMCustomEvent.OTPVerified, 'timestamp', Math.floor(Date.now() / 1000).toString());
        onSubmitOTP();
        return true;
      } catch (err) {
        const error = err as unknown as Error;
        switch (error.message) {
          case ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT:
            toast(ServerMobileRegistrationError[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT].message);
            return false;
          case ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED:
            toast(ServerMobileRegistrationError[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED].message);
            return false;
          default:
            toast(CustomError.GENERIC_ERROR.message);
            return false;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [coffeeDao, coffeeDaoClient, formData, onSubmitOTP]
  );

  const resendOTP = useCallback(async () => {
    if (!coffeeDao || !formData) {
      return false;
    }
    try {
      await coffeeDaoClient?.request(coffeeDao.requestOTP(`${formData.countryCode}${formData.phone}`));
      toast(t('welcome-reward.verify.otp-resent-message'));
      return true;
    } catch (err) {
      const error = err as unknown as Error;
      switch (error.message) {
        case ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED:
          toast(ServerMobileRegistrationError[ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED].message);
          return false;
        case ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT:
          toast(
            ServerMobileRegistrationError[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT]
              .message
          );
          return false;
        default:
          toast(CustomError.GENERIC_ERROR.message);
          return false;
      }
    }
  }, [coffeeDao, coffeeDaoClient, formData, t]);

  return {
    isLoading,
    formData,
    verifyPhoneNumber,
    verifyOTP,
    resendOTP,
  };
}
