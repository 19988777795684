import styled from 'styled-components';
import IconButton from 'components/IconButton';
import { CoffeeDaoLogo } from 'components/Logo';

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
`;

interface Props {
  logo?: 'primary' | 'secondary';
  onClose?: () => void;
  onBackClick?: () => void;
  closeButtonClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

export default function SpendHeader({
  logo = 'primary',
  onClose,
  onBackClick,
  closeButtonClassName,
  className,
  style,
}: Props): JSX.Element {
  return (
    <Header className={className} style={style}>
      {onBackClick ? (
        <IconButton icon="back" variant="secondary" iconSize="16px" buttonSize="40px" onClick={onBackClick} />
      ) : (
        <CoffeeDaoLogo variant={logo} height="32px" />
      )}
      {onClose && (
        <IconButton
          icon="close"
          variant="secondary"
          iconSize="16px"
          buttonSize="40px"
          onClick={onClose}
          className={closeButtonClassName}
        />
      )}
    </Header>
  );
}
