import { useAsyncMemo } from '@adam-vault/adam-frontend-shared';
import { SUBSIDY_TRANSACTION_STATUS, SUBSIDY_TRANSACTION_TYPE } from '@adam-vault/adam-sdk-coffeedao';
import { subDays } from 'date-fns';
import { BigNumber } from 'ethers';
import { useCallback, useMemo } from 'react';
import { CustomError } from 'constants/error/CustomError';
import { TransactionFilterType, TransactionType } from 'constants/transaction';
import { TransactionRecord } from 'types/TransactionRecord';
import { toast } from 'utils/toastUtils';
import useAdam from './useAdam';
import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import { useIsAuthenticated } from './useIsAuthenticated';
import useMeta from './useMeta';
import useWeb3 from './useWeb3';

type Return = {
  records: TransactionRecord[];
  reloadRecords: () => void;
  isLoading: boolean;
};

const MAP_SUBSIDY_TRANSACTION_TYPE_TO_TRANSACTION_TYPE = {
  [SUBSIDY_TRANSACTION_TYPE.REWARD]: TransactionType.REWARD,
  [SUBSIDY_TRANSACTION_TYPE.PAYMENT]: TransactionType.PAYMENT,
  [SUBSIDY_TRANSACTION_TYPE.REFERRAL_REWARD]: TransactionType.REFERRAL_REWARD,
  [SUBSIDY_TRANSACTION_TYPE.CAMPAIGN_GIFT]: TransactionType.CAMPAIGN_GIFT,
};

const RECORDS_DAYS_COUNT = 90;

export default function useCoffeeDaoTransactions(transactionType: TransactionFilterType): Return {
  const coffeeDaoClient = useCoffeeDaoClient();
  const { isAuthenticated } = useIsAuthenticated();
  const { address: eoaAddress } = useWeb3();
  const adam = useAdam();
  const coffeeDao = useCoffeeDao();
  const { meta } = useMeta();

  const [onChainRecords, { isLoading: isLoadingOnChainRecords, refetch: refetchOnChainRecords }] = useAsyncMemo<
    TransactionRecord[]
  >(
    async () => {
      if (!isAuthenticated || !meta || !coffeeDaoClient) {
        return [];
      }

      try {
        const selfClaim = adam.loadSelfClaim(meta.SELFCLAIM_BUDGET_ADDRESS);

        return await coffeeDaoClient.request(
          selfClaim.transactionRecords(meta.UTIL_TOKEN_ADDRESS, subDays(new Date(), RECORDS_DAYS_COUNT), new Date())
        );
      } catch {
        // TODO: error handling

        return [];
      }
    },
    [isAuthenticated, meta, coffeeDaoClient],
    []
  );

  const [processingRecords, { isLoading: isLoadingProcessingRecords, refetch: refetchProcessingRecords }] =
    useAsyncMemo<TransactionRecord[]>(
      async () => {
        if (!isAuthenticated || !coffeeDaoClient || !coffeeDao || !meta || !eoaAddress) {
          return [];
        }

        try {
          const transactions = await coffeeDaoClient.request(coffeeDao.getSubsidyTransactions(99, 0));
          if (!transactions) {
            return [];
          }
          return transactions.subsidyTransactions
            .filter((tx) => tx.status === SUBSIDY_TRANSACTION_STATUS.PENDING)
            .map((tx) => {
              const isEarnRecord = tx.type !== SUBSIDY_TRANSACTION_TYPE.PAYMENT;
              return {
                gasPrice: BigNumber.from(0),
                from: isEarnRecord ? meta.DAO_ADDRESS : eoaAddress || '',
                to: tx.toAddress || '',
                value: tx.amount,
                transactionHash: tx.subsidyTransactionId,
                createdAt: new Date(0),
                fromDisplayName: isEarnRecord ? meta.DAO_ADDRESS : eoaAddress || '',
                toDisplayName: tx.toAddress || '',
                type: MAP_SUBSIDY_TRANSACTION_TYPE_TO_TRANSACTION_TYPE[tx.type],
              } satisfies TransactionRecord;
            });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          toast(CustomError.FETCH_PROCESSING_RECORDS_FAIL.message);
          return [];
        }
      },
      [isAuthenticated, coffeeDao, coffeeDaoClient, meta, eoaAddress],
      []
    );

  const records = useMemo(() => {
    switch (transactionType) {
      case TransactionFilterType.ALL:
        return onChainRecords;
      case TransactionFilterType.EARN:
        return onChainRecords.filter((record) => record.type !== TransactionType.PAYMENT);
      case TransactionFilterType.SPEND:
        return onChainRecords.filter((record) => record.type === TransactionType.PAYMENT);
      case TransactionFilterType.PROCESSING:
        return processingRecords;
    }
  }, [onChainRecords, processingRecords, transactionType]);

  const reloadRecords = useCallback(async () => {
    await Promise.all([refetchOnChainRecords(), refetchProcessingRecords()]);
  }, [refetchOnChainRecords, refetchProcessingRecords]);

  return {
    records,
    reloadRecords,
    isLoading: isLoadingProcessingRecords || isLoadingOnChainRecords,
  };
}
