import styled from 'styled-components';
import { Background } from 'components/layout';
import LoadingSpinner from 'components/Loading';

const LoadingPage = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: ${({ theme: { color } }) => color.pageBackground};
`;

const LoadingContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.color.primary};
`;

export default function SuspenseLoading(): JSX.Element {
  return (
    <Background>
      <LoadingPage>
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </LoadingPage>
    </Background>
  );
}
