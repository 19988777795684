import { TransactionRecord } from '@adam-vault/adam-sdk-coffeedao';
import { useMemo } from 'react';
import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import useTransactionDisplay from 'hooks/useTransactionDisplay';

const TransactionContainer = styled.button`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 16px;
  padding: 8px 16px;
  text-align: left;
  background-color: ${({ theme: { color } }) => color.interactive};
  color: ${({ theme: { color } }) => color.interactiveFont};
`;

const TransactionImage = styled.img``;

const TransactionDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

interface Props {
  record: TransactionRecord;
  onClickRecord?: () => void;
}

export default function TransactionListItem({ record, onClickRecord }: Props): JSX.Element {
  const getDisplay = useTransactionDisplay();
  const { titleText, image, dateTimeText, amountText } = useMemo(() => getDisplay(record), [getDisplay, record]);

  return (
    <TransactionContainer onClick={onClickRecord}>
      <TransactionImage src={image} />
      <TransactionDetail>
        <LocalizedText category="body1">{titleText}</LocalizedText>
        <LocalizedText category="caption" color="caption">
          {dateTimeText}
        </LocalizedText>
      </TransactionDetail>
      <LocalizedText category="h3" weight="medium">
        {amountText}
      </LocalizedText>
    </TransactionContainer>
  );
}
