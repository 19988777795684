import { ethers } from 'ethers';
import { useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import IconButton from 'components/IconButton';
import { ScannerBackground } from 'components/scanner';
import { ID_QR_CODE_CAMERA_SCANNER_CONTAINER, ID_QR_CODE_IMAGE_SCANNER_CONTAINER } from 'constants/cssSelectors';
import PATHS from 'constants/path';
import { TRANSFER_TO_PARAM_KEY } from 'constants/searchParams';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useQrCodeScanner from 'hooks/useQrCodeScanner';
import shopTheme from 'theme/shop';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ButtonRowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
  width: 100%;
  z-index: 10;
`;

const FileInput = styled.input.attrs({ type: 'file', accept: 'image/*' })`
  display: none;
`;

export default function ShopScanner(): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigateWithLang();

  const { startCamera, stopCamera, scanImage, QRCodeCameraScanner, QRCodeImageScanner } = useQrCodeScanner({
    cameraId: ID_QR_CODE_CAMERA_SCANNER_CONTAINER,
    imageId: ID_QR_CODE_IMAGE_SCANNER_CONTAINER,
  });

  useEffect(() => {
    startCamera({
      successCallback(decodedText: string) {
        const possibleAddressIndex = decodedText.search(/0x[0-9a-zA-Z]{40}/);
        const possibleAddress =
          possibleAddressIndex >= 0 ? decodedText.slice(possibleAddressIndex, possibleAddressIndex + 42) : '';
        if (ethers.utils.isAddress(possibleAddress)) {
          const transferAddress = possibleAddress;
          navigate(`/${PATHS.SHOP}/?${TRANSFER_TO_PARAM_KEY}=${transferAddress}`);
        }
      },
    });
  }, [navigate, startCamera]);

  useEffect(
    () => () => {
      stopCamera();
    },
    [stopCamera]
  );

  const onClickBack = (): void => {
    navigate(-1);
  };

  const onClickAlbum = (): void => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleScanQRCodeFromAlbumImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target as HTMLInputElement;
    if (!input.files || input?.files?.length === 0) {
      return;
    }
    scanImage(input.files[0]);
  };

  const onClickScanFail = (): void => {
    navigate(`/${PATHS.SHOP}/?${TRANSFER_TO_PARAM_KEY}=`);
  };

  return (
    <ThemeProvider theme={shopTheme}>
      <Container>
        <ScannerBackground onClickScanFail={onClickScanFail} scanFailLabel="scanner.scan-fail-label" />
        <ButtonRowWrapper>
          <IconButton
            icon="back"
            buttonSize="40px"
            iconSize="24px"
            buttonColor="pageBackground"
            onClick={onClickBack}
            className={gaClassName('scanner-back')}
            withoutBorder
          />
          <IconButton
            icon="image"
            buttonSize="40px"
            iconSize="24px"
            buttonColor="pageBackground"
            onClick={onClickAlbum}
            className={gaClassName('scanner-import')}
            withoutBorder
          />
          <FileInput ref={fileInputRef} onChange={handleScanQRCodeFromAlbumImage} />
        </ButtonRowWrapper>
        <QRCodeCameraScanner />
        <QRCodeImageScanner />
      </Container>
    </ThemeProvider>
  );
}
