import { css } from 'styled-components';
import { PAGE_MIN_WIDTH } from 'constants/dimension';

export const screenBackgroundCss = css`
  width: 100%;
  height: var(--app-height, 100%);
  min-width: ${PAGE_MIN_WIDTH}px;
  overflow: hidden;

  @media only screen and (min-device-width: 1366px) and (orientation: landscape) {
    width: 24%;
    margin: auto;
  }
`;

export const buttonLikeShadowCss = css`
  transform: translateY(0);
  box-shadow: 0px 4px 0px ${({ theme: { color } }) => color.buttonBorder};

  &:hover:enabled {
    box-shadow: 0px 6px 0px ${({ theme: { color } }) => color.buttonBorder};
    transform: translateY(-2px);
  }

  &:active,
  &:focus {
    transform: translateY(2px);
    box-shadow: 0px 2px 0px ${({ theme: { color } }) => color.buttonBorder};
  }
`;
