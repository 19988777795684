import { BigNumber } from 'ethers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import imageEarnRecord from 'assets/images/image_transaction-earn.svg';
import imageSpendRecord from 'assets/images/image_transaction-spend.svg';
import imageTransferRecord from 'assets/images/image_transaction-transfer.svg';
import { TransactionType } from 'constants/transaction';
import UserType from 'constants/userType';
import { TransactionRecord } from 'types/TransactionRecord';
import useCoffeeDaoToken from './useCoffeeDaoToken';
import useCoffeeShops from './useCoffeeShopList';
import useUserType from './useUserType';

type TransactionDisplay = {
  image: string;
  fromDisplay: { address?: string; name?: string };
  toDisplay: { address?: string; name?: string };
  typeText: string;
  titleText: string;
  amountText: string;
  dateTimeText: string;
} & TransactionRecord;

export default function useTransactionDisplay(): (transactionRecord: TransactionRecord) => TransactionDisplay {
  const { t } = useTranslation();
  const { isLoading, coffeeShops, error } = useCoffeeShops();
  const { toDisplay } = useCoffeeDaoToken();
  const userType = useUserType();

  const getCoffeeShopName = useCallback(
    (address: string) => {
      if (isLoading || error) {
        return t('coffee-shop.unknown') as string;
      }
      // TODO: compare with lowercase when the coffee shop list is located in frontend
      const coffeeShop = coffeeShops.find((c) => c.eoaAddress.toLowerCase() === address.toLowerCase());
      return coffeeShop?.name || (t('coffee-shop.unknown') as string);
    },
    [coffeeShops, error, isLoading, t]
  );

  const getDisplay = useCallback(
    (transactionRecord: TransactionRecord): TransactionDisplay => {
      const dateTimeText =
        transactionRecord.createdAt.getTime() === 0
          ? t('transactions.type.processing')
          : transactionRecord.createdAt.toLocaleString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
              hourCycle: 'h12',
            });

      const amountDisplay = toDisplay(transactionRecord.value, { maxDecimals: 1, shouldTrimTrailingZero: false });

      switch (transactionRecord.type) {
        case TransactionType.WELCOME_REWARD: {
          return {
            ...transactionRecord,
            image: imageEarnRecord,
            fromDisplay: { name: t('transactions.detail.welcome-reward-label') },
            toDisplay: { name: t('transactions.detail.my-wallet-label') },
            typeText: t('transactions.detail.welcome-reward-label'),
            titleText: t('transactions.detail.welcome-reward-label'),
            amountText: `+${amountDisplay}`,
            dateTimeText,
          };
        }
        case TransactionType.REWARD: {
          return {
            ...transactionRecord,
            image: imageEarnRecord,
            fromDisplay: { address: transactionRecord.from, name: getCoffeeShopName(transactionRecord.from) },
            toDisplay: { name: t('transactions.detail.my-wallet-label') },
            typeText: t('transactions.detail.receive-token-label'),
            titleText: getCoffeeShopName(transactionRecord.from) || t('transactions.detail.receive-token-label'),
            amountText: `+${amountDisplay}`,
            dateTimeText,
          };
        }
        case TransactionType.REFERRAL_REWARD: {
          return {
            ...transactionRecord,
            image: imageEarnRecord,
            fromDisplay: { address: transactionRecord.from, name: t('transactions.detail.referral-reward-label') },
            toDisplay: { name: t('transactions.detail.my-wallet-label') },
            typeText: t('transactions.detail.referral-reward-label'),
            titleText: t('transactions.detail.referral-reward-label'),
            amountText: `+${amountDisplay}`,
            dateTimeText,
          };
        }
        case TransactionType.CAMPAIGN_GIFT: {
          return {
            ...transactionRecord,
            image: imageEarnRecord,
            fromDisplay: { name: t('transactions.detail.campaign-reward-label') },
            toDisplay: { name: t('transactions.detail.my-wallet-label') },
            typeText: t('transactions.detail.campaign-reward-label'),
            titleText: t('transactions.detail.campaign-reward-label'),
            amountText: `+${amountDisplay}`,
            dateTimeText,
          };
        }
        case TransactionType.PAYMENT: {
          // transfer record for coffee shop;
          if (userType === UserType.COFFEE_SHOP) {
            return {
              ...transactionRecord,
              image: imageTransferRecord,
              fromDisplay: { name: t('transactions.detail.my-wallet-label') },
              toDisplay: { address: transactionRecord.to, name: transactionRecord.to },
              typeText: t('transactions.detail.transfer-token-label'),
              titleText: t('transactions.detail.transfer-token-label'),
              amountText: `-${amountDisplay}`,
              dateTimeText,
            };
          }
          return {
            ...transactionRecord,
            image: imageSpendRecord,
            fromDisplay: { name: t('transactions.detail.my-wallet-label') },
            toDisplay: { address: transactionRecord.to, name: getCoffeeShopName(transactionRecord.to) },
            typeText: t('transactions.detail.spend-token-label'),
            titleText: getCoffeeShopName(transactionRecord.to) || t('transactions.detail.spend-token-label'),
            amountText: `-${amountDisplay}`,
            dateTimeText,
          };
        }
        default: {
          if (BigNumber.from(transactionRecord.from).isZero()) {
            return {
              ...transactionRecord,
              image: imageTransferRecord,
              fromDisplay: { name: t('transactions.detail.mint-address-label') },
              toDisplay: { name: t('transactions.detail.my-wallet-label') },
              typeText: t('transactions.detail.mint-address-label'),
              titleText: t('transactions.detail.mint-address-label'),
              amountText: `+${amountDisplay}`,
              dateTimeText,
            };
          }

          return {
            ...transactionRecord,
            image: imageTransferRecord,
            fromDisplay: { name: 'Unknown' },
            toDisplay: { name: 'Unknown' },
            typeText: 'Unknown',
            titleText: 'Unknown',
            amountText: `${amountDisplay}`,
            dateTimeText,
          };
        }
      }
    },
    [getCoffeeShopName, t, toDisplay, userType]
  );

  return getDisplay;
}
