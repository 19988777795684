import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import Text from 'components/Text';

export interface Props {
  className?: string;
  errorMessage?: string;
  contrast?: boolean;
}

const Message = styled(Text)`
  color: ${({ theme: { color } }): string => color.error};
  margin-left: 4px;
`;

const Container = styled.div<{ contrast?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;

  ${({ contrast }) =>
    contrast &&
    css`
      padding: 4px;
      background-color: ${({ theme }): string => theme.color.error};
      border-radius: 4px;

      ${Message} {
        color: ${({ theme }): string => theme.color.secondary};
      }
    `}
`;

export default function ErrorMessage(props: Props): JSX.Element {
  const { className, errorMessage, contrast } = props;
  return (
    <Container className={className} contrast={contrast}>
      <Icon icon="close" size="16px" color={contrast ? 'secondary' : 'error'} />
      <Message category="body2" weight="regular">
        {errorMessage}
      </Message>
    </Container>
  );
}
