import { TFuncKey } from 'i18next';
import { Trans } from 'react-i18next';
import Text, { TextProps } from 'components/Text';
import { TranslationKey } from 'constants/languages';

export { type TranslationKey };
export interface TranslationProps {
  translationKey?: TranslationKey;
  values?: { [key: string]: string };
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement };
}

export default function LocalizedText(props: TranslationProps & TextProps): JSX.Element {
  const { translationKey, children, values, components, ...baseProps } = props;

  return (
    <Text {...baseProps}>
      <Trans<TFuncKey<'zh' | 'en'>, 'zh' | 'en'> i18nKey={translationKey} values={values} components={components}>
        {children}
      </Trans>
    </Text>
  );
}
