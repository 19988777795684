import styled from 'styled-components';
import logoCoffeeToken from 'assets/images/icon_coin.svg';
import arborNewsLogoSecondary from 'assets/images/logo_arbor_news_secondary.svg';
import logoPrimary from 'assets/images/logo_coffee_dao.svg';
import logoSecondary from 'assets/images/logo_coffee_dao_secondary.svg';

interface CoffeeDaoLogoProps {
  variant: 'primary' | 'secondary';
  height?: string;
}

export const CoffeeDaoLogo = styled.img.attrs<CoffeeDaoLogoProps>(({ variant = 'primary' }) => ({
  src: variant === 'secondary' ? logoSecondary : logoPrimary,
}))<CoffeeDaoLogoProps>`
  height: ${({ height }) => height || '32px'};
`;

export const ArborNewsLogo = styled.img.attrs({ src: arborNewsLogoSecondary })<{ height: string }>`
  height: ${({ height }) => height || '32px'};
`;

export const CoffeeTokenLogo = styled.img.attrs({ src: logoCoffeeToken })<{ height: string }>`
  height: ${({ height }) => height || '32px'};
`;
