import { atom } from 'jotai';
import { adamAtom } from './adamAtom';
import { metaAtom } from './metaAtom';
import { sessionAtom } from './sessionAtom';
import { web3Atom } from './web3Atom';

export const coffeeDaoAtom = atom((get) => {
  const { isConnected } = get(web3Atom);

  if (!isConnected) {
    return undefined;
  }

  const adam = get(adamAtom);
  const meta = get(metaAtom);
  const jwtToken = get(sessionAtom);

  if (jwtToken.state !== 'hasData' || !jwtToken.data || meta.state !== 'hasData') {
    return undefined;
  }

  const selfClaim = adam.loadSelfClaim(meta.data.SELFCLAIM_BUDGET_ADDRESS);
  return selfClaim.getCoffeeDao(jwtToken.data);
});
