import { Referral } from '@adam-vault/adam-sdk-coffeedao';
import { useSetAtom } from 'jotai';
import { referralAtom } from 'store/referralAtom';
import useLoadableAtom from './useLoadableAtom';

export default function useReferral(): {
  isLoading: boolean;
  referralCode: string | undefined;
  referrals: Referral[];
  error: Error | undefined;
  reloadReferrals: () => void;
} {
  const { isLoading, data, error } = useLoadableAtom(referralAtom);
  const reloadReferrals = useSetAtom(referralAtom);
  return { isLoading, referralCode: data?.referralCode, referrals: data?.referrals || [], error, reloadReferrals };
}
