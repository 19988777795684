import { REFERRAL_CODE_PARAM_KEY } from 'constants/searchParams';
import useSearchParamKeys from './useSearchParamKeys';

interface Return {
  referralCode: string | undefined;
  setReferralCode: (newReferralCode: string) => void;
}

export default function useWelcomeRewardReferralCode(): Return {
  const {
    params: [referralCode],
    setParams,
  } = useSearchParamKeys(REFERRAL_CODE_PARAM_KEY);

  return {
    referralCode: referralCode || undefined,
    setReferralCode: (newReferralCode: string) => {
      setParams(REFERRAL_CODE_PARAM_KEY, newReferralCode);
    },
  };
}
