import styled, { DefaultTheme } from 'styled-components';
import { themeColorChecker } from 'utils/themeUtils';

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  icon: string;
  size?: string;
  color?: keyof DefaultTheme['color'] | string;
}

const IconSpan = styled.span<Pick<Props, 'size' | 'color'>>`
  font-size: ${({ size }) => size || 'inherit'};
  color: ${({ color, theme }) => (color ? (themeColorChecker(color) ? theme.color[color] : color) : 'inherit')};
`;

export default function Icon(props: Props): JSX.Element {
  const { className, icon, size = '24px', color, ...rest } = props;

  return (
    <IconSpan
      className={className ? `${className} icon-${icon}` : `icon-${icon}`}
      size={size}
      color={color}
      {...rest}
    />
  );
}
