import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useAtomValue } from 'jotai';
import useCoffeeShopProfile from 'hooks/useCoffeeShopProfile';
import { DEFAULT_SPEND_AMOUNT, spendTokenQrCodeAtom } from 'store/spendAtom';

interface Return {
  coffeeShop: CoffeeShop | null;
  spendAmount: string;
  isLoading: boolean;
}

export default function useSpendDetailFromQrCode(): Return {
  const qrCode = useAtomValue(spendTokenQrCodeAtom);
  const { isLoading, coffeeShop } = useCoffeeShopProfile(qrCode?.destinationAddress || '');

  return {
    coffeeShop,
    spendAmount: DEFAULT_SPEND_AMOUNT,
    isLoading,
  };
}
