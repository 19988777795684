import { useMemo } from 'react';
import useCoffeeDaoToken from './useCoffeeDaoToken';
import useMeta from './useMeta';

const DEFAULT_WELCOME_REWARD_AMOUNT = '4.00';

interface Return {
  welcomeRewardAmount: string;
  isLoading: boolean;
}

export default function useWelcomeRewardDetail(): Return {
  const { toDisplay, isLoading } = useCoffeeDaoToken();
  const { isLoading: isLoadingMeta, meta } = useMeta();

  const welcomeRewardAmount = useMemo(
    () => toDisplay(meta?.WELCOME_REWARD_AMOUNT, { shouldTrimTrailingZero: false }) || DEFAULT_WELCOME_REWARD_AMOUNT,
    [meta?.WELCOME_REWARD_AMOUNT, toDisplay]
  );

  return {
    welcomeRewardAmount,
    isLoading: isLoading || isLoadingMeta,
  };
}
