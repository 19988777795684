import styled, { css } from 'styled-components';
import { skeletonCss } from 'components/Skeleton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BloomSkeleton = styled.div`
  width: 480px;
  height: 480px;
  aspect-ratio: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-40%) translateX(-50%);
  z-index: -1;
  clip-path: url(#bloom);

  ${skeletonCss};
  position: absolute;
`;

const ContentSkeleton = styled.div<{ width: number; height: number; marginBottom?: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ height }) => `${height / 2}px`};

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `};
  ${skeletonCss}
`;

const Content = styled.div`
  margin-top: min(100vw, 360px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export default function LoadingView(): JSX.Element {
  return (
    <Container>
      <svg width="0" height="0">
        <defs>
          <clipPath id="bloom">
            <path d="M466.658 190.328C484.447 220.905 484.447 259.095 466.658 289.672L464.964 292.584C457.672 305.119 453.234 319.195 451.986 333.754L451.696 337.136C448.651 372.651 426.873 403.548 395.045 417.507L392.013 418.837C378.966 424.559 367.349 433.259 358.037 444.281L355.873 446.842C333.158 473.729 297.919 485.53 264.209 477.541L260.999 476.78C247.18 473.505 232.82 473.505 219.001 476.78L215.791 477.541C182.081 485.53 146.842 473.729 124.127 446.842L121.963 444.281C112.651 433.259 101.034 424.559 87.9866 418.837L84.9554 417.507C53.1274 403.548 31.3487 372.651 28.3038 337.136L28.0138 333.754C26.7656 319.195 22.3282 305.119 15.0359 292.584L13.3417 289.672C-4.44722 259.095 -4.44721 220.904 13.3417 190.328L15.0359 187.416C22.3282 174.881 26.7656 160.805 28.0138 146.246L28.3038 142.864C31.3488 107.349 53.1273 76.4522 84.9553 62.4927L87.9866 61.1632C101.034 55.4407 112.651 46.7408 121.963 35.7189L124.127 33.1582C146.842 6.27119 182.081 -5.53046 215.791 2.45919L219.001 3.22013C232.82 6.49536 247.18 6.49536 260.999 3.22013L264.209 2.45919C297.919 -5.53046 333.158 6.27121 355.873 33.1582L358.037 35.7189C367.349 46.7408 378.966 55.4407 392.013 61.1632L395.045 62.4927C426.873 76.4522 448.651 107.349 451.696 142.864L451.986 146.246C453.234 160.805 457.672 174.881 464.964 187.416L466.658 190.328Z" />
          </clipPath>
        </defs>
      </svg>
      <BloomSkeleton />
      <Content>
        <ContentSkeleton width={150} height={24} marginBottom={8} />
        <ContentSkeleton width={324} height={24} />
        <ContentSkeleton width={324} height={24} />
        <ContentSkeleton width={324} height={24} marginBottom={24} />
        <ContentSkeleton width={104} height={52} />
      </Content>
    </Container>
  );
}
