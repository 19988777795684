import { WalletConnectRedirectedError, WalletType } from '@adam-vault/adam-frontend-shared';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import DebugArea from 'components/DebugArea';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';
import { SignatureRequestRetryCard, SignatureRequiredModal } from 'components/signature';
import { DEFAULT_CHAIN_ID } from 'constants/chain';
import { CustomError } from 'constants/error/CustomError';
import useSession from 'hooks/useSession';
import useWeb3 from 'hooks/useWeb3';
import { web3Connector } from 'store/web3Atom';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { isJwtExpired } from 'utils/jwtUtils';
import { toast } from 'utils/toastUtils';
import { sendWeb3AuthLoginProvider } from 'utils/web3authUtil';
import IncorrectNetworkModal from './IncorrectNetworkModal';
import SessionExpireModal from './SessionExpireModal';

export default function SessionManager(): JSX.Element {
  const { isSigning, jwtToken, error: sessionError, getSession } = useSession();
  const { t } = useTranslation();

  const {
    setWeb3,
    chainId,
    isConnected,
    isDisconnected,
    hasReconnectTried,
    walletType,
    address,
    error: walletError,
  } = useWeb3();

  const isIncorrectNetwork = useMemo(
    // TODO: add supported network in environment config
    () => isConnected && chainId !== DEFAULT_CHAIN_ID,
    [chainId, isConnected]
  );

  const isSignatureRequired = useMemo(
    () => isConnected && !jwtToken && !isIncorrectNetwork && (!isSigning || walletType !== WalletType.WEB3AUTH),
    [isConnected, jwtToken, isIncorrectNetwork, isSigning, walletType]
  );

  const isSessionExpired = useMemo(
    () => isConnected && !isSigning && Boolean(jwtToken) && isJwtExpired(jwtToken) && !isIncorrectNetwork,
    [isConnected, isSigning, jwtToken, isIncorrectNetwork]
  );

  useEffect(() => {
    web3Connector.subscribe(setWeb3);
  }, [setWeb3]);

  useEffect(() => {
    if (walletError || sessionError) {
      const error = (walletError || sessionError) as Error;
      if (!(error instanceof WalletConnectRedirectedError)) {
        toast(error.message || (t(CustomError.LOGIN_FAIL.message) as string));
      }
      if (walletType === WalletType.WALLET_CONNECT) {
        localStorage.clear();
      }
      if (!isDisconnected) {
        web3Connector.disconnectEOA();
      }
    }
  }, [walletError, sessionError, isDisconnected, walletType, t]);

  useEffect(() => {
    if (isDisconnected && !hasReconnectTried && !walletError) {
      web3Connector.tryReconnectEOA().catch(() => {
        web3Connector.disconnectEOA();
      });
    }
  }, [isDisconnected, hasReconnectTried, walletError]);

  useEffect(() => {
    if (isConnected && walletType) {
      sendGTMEvent(GTMCustomEvent.WalletConnected, 'walletType', walletType);
      if (walletType === WalletType.WEB3AUTH) {
        sendWeb3AuthLoginProvider();
      }
    }
  }, [isConnected, walletType]);

  useEffect(() => {
    if (isConnected && address) {
      sendGTMEvent(GTMCustomEvent.EOAConnected, 'eoaAddress', address.replace('0x', ''));
    }
  }, [isConnected, address]);

  const onDisconnect = async (): Promise<void> => {
    await web3Connector.disconnectEOA(true);
  };

  return (
    <>
      <SessionExpireModal isOpen={isSessionExpired} getSession={getSession} />
      <SignatureRequiredModal isOpen={isSignatureRequired}>
        <SmallModal.Action>
          <SignatureRequestRetryCard onRetry={getSession} />
          <DebugArea>
            <Button onClick={onDisconnect} variant="text">
              <LocalizedText category="caption" translationKey="switch-network.button-disconnect" />
            </Button>
          </DebugArea>
        </SmallModal.Action>
      </SignatureRequiredModal>
      <IncorrectNetworkModal isOpen={isIncorrectNetwork} onDisconnect={onDisconnect} />
    </>
  );
}
