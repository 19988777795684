import styled, { css, DefaultTheme } from 'styled-components';
import { LanguageKey } from 'constants/languages';
import useLanguage from 'hooks/useLanguage';
import { themeColorChecker } from 'utils/themeUtils';

export type TextVariant = 'primary' | 'onPrimary' | 'secondary';
export type TextCategory = 'h0' | 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'callout' | 'caption';

export interface TextProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  variant?: TextVariant;
  category: TextCategory;
  weight?: 'regular' | 'medium';
  color?: keyof DefaultTheme['color'];
}

export const getVariantCss = (variant: TextProps['variant'], textColor: TextProps['color']): ReturnType<typeof css> => {
  if (textColor && themeColorChecker(textColor)) {
    return css`
      color: ${({ theme: { color } }) => color[textColor]};
    `;
  }

  if (!variant) {
    return css`
      color: inherit;
    `;
  }

  switch (variant) {
    case 'primary':
      return css`
        color: ${({ theme: { color } }) => color.primary};
      `;
    case 'onPrimary':
      return css`
        color: ${({ theme: { color } }) => color.onPrimary};
      `;
    case 'secondary':
      return css`
        color: ${({ theme: { color } }) => color.secondary};
      `;
  }
};

export const getCategoryCss = (category: TextProps['category']): ReturnType<typeof css> => {
  switch (category) {
    case 'h0':
      return css`
        font-size: 40px;
        line-height: 40px;
      `;
    case 'h1':
      return css`
        font-size: 32px;
        line-height: 36px;
      `;
    case 'h2':
      return css`
        font-size: 24px;
        line-height: 32px;
      `;
    case 'h3':
      return css`
        font-size: 20px;
        line-height: 26px;
      `;
    case 'body1':
      return css`
        font-size: 16px;
        line-height: 26px;
      `;
    case 'body2':
      return css`
        font-size: 14px;
        line-height: 22px;
      `;
    case 'callout':
      return css`
        font-size: 14px;
        line-height: 32px;
        letter-spacing: 3.36px;
        text-transform: uppercase;
      `;
    case 'caption':
      return css`
        font-size: 12px;
      `;
  }
};

const getWeightCss = (weight: TextProps['weight']): ReturnType<typeof css> => {
  if (!weight) {
    return css``;
  }

  switch (weight) {
    case 'regular':
      return css`
        font-weight: 400;
      `;
    case 'medium':
      return css`
        font-weight: 500;
      `;
  }
};

const getFontFamilyCss = (category: TextProps['category'], language: LanguageKey): ReturnType<typeof css> => {
  switch (language) {
    case 'zh': {
      switch (category) {
        case 'h0':
        case 'h1':
        case 'h2':
          return css`
            font-family: ${({ theme: { key } }) => (key === 'default' ? 'Homrice' : 'noto-sans-tc')};
          `;
        case 'h3':
        case 'body1':
        case 'body2':
        case 'callout':
        case 'caption':
          return css`
            font-family: ${({ theme: { key } }) => (key === 'default' ? 'made-okine-sans' : 'noto-sans-tc')};
          `;
        default:
          break;
      }
      return css``;
    }
    case 'en': {
      switch (category) {
        case 'h0':
        case 'h1':
        case 'h2':
          return css`
            font-family: ${({ theme: { key } }) => (key === 'default' ? 'Homrice' : 'made-okine-sans')};
          `;
        case 'h3':
        case 'body1':
        case 'body2':
        case 'callout':
        case 'caption':
          return css`
            font-family: made-okine-sans;
          `;
        default:
          break;
      }
      return css``;
    }
    default:
      return css``;
  }
};

const TextContainer = styled.div<TextProps & { language: LanguageKey }>`
  ${({ variant, color }) => getVariantCss(variant, color)}
  ${({ category }): ReturnType<typeof css> => getCategoryCss(category)}
  ${({ weight }): ReturnType<typeof css> => getWeightCss(weight)}
  ${({ category, language }): ReturnType<typeof css> => getFontFamilyCss(category, language)}
`;

export default function Text({
  variant,
  weight = 'regular',
  children,
  category,
  ...otherProps
}: TextProps): JSX.Element {
  const { language } = useLanguage();
  return (
    <TextContainer variant={variant} weight={weight} category={category} language={language} {...otherProps}>
      {children}
    </TextContainer>
  );
}
