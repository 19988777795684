import styled, { css } from 'styled-components';
import LocalizedText from './LocalizedText';
import Text from './Text';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled(LocalizedText).attrs(({ variant = 'secondary' }) => ({ category: 'body2', variant }))``;

const Value = styled(Text).attrs({ category: 'h3' })`
  color: ${({ theme: { color } }) => color.tinted};
`;

const LocalizedValue = styled(LocalizedText).attrs({ category: 'h3' })`
  ${({ theme: { color }, variant }) =>
    !variant &&
    css`
      color: ${color.tinted};
    `};
`;

const ListItemDetail = styled(Content)<{ disabled?: boolean; tinted?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
    `}

  ${({ tinted }) =>
    tinted &&
    css`
      ${Label} {
        color: ${({ theme: { color } }) => color.onTinted};
      }

      ${Value}, ${LocalizedValue} {
        color: ${({ theme: { color } }) => color.primary};
      }
    `}
`;

interface Props {
  className?: string;
  action?: React.ReactNode;
  tinted?: boolean;
  disabled?: boolean;
}

export default function ActionListItem(props: React.PropsWithChildren<Props>): JSX.Element {
  const { className, action, tinted, disabled, children } = props;

  return (
    <ListItem className={className}>
      <ListItemDetail tinted={tinted} disabled={disabled}>
        {children}
      </ListItemDetail>
      {action}
    </ListItem>
  );
}

ActionListItem.Label = Label;
ActionListItem.Value = Value;
ActionListItem.LocalizedValue = LocalizedValue;
