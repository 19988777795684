import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ShopPage } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import TabBar from 'components/TabBar';
import TransactionList from 'components/transaction/TransactionList';
import { TRANSACTION_FILTER_PARAM_KEY } from 'constants/searchParams';
import { TransactionFilterType, TransactionType } from 'constants/transaction';
import useCoffeeDaoTokenTransactions from 'hooks/useCoffeeDaoTokenTransactions';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div``;

const Title = styled(LocalizedText)`
  margin-bottom: 16px;
`;

const Spacer = styled.div`
  height: 24px;
`;

export default function ShopTransactions(): JSX.Element {
  const {
    params: [transactionFilterType],
    clearParams,
    setParams,
  } = useSearchParamKeys(TRANSACTION_FILTER_PARAM_KEY);
  const { state } = useLocation();
  const defaultTransactionType: TransactionFilterType =
    state?.defaultTransactionType || transactionFilterType || TransactionFilterType.ALL;

  const [selectedTransactionType, setSelectedTransactionType] = useState<TransactionFilterType>(defaultTransactionType);
  const { records, isLoading } = useCoffeeDaoTokenTransactions();

  const filteredRecords = useMemo(
    () =>
      records.filter((record) => {
        switch (selectedTransactionType) {
          case TransactionFilterType.SPEND:
            return [TransactionType.PAYMENT].includes(record.type);
          case TransactionFilterType.EARN:
            return [TransactionType.REWARD, TransactionType.REFERRAL_REWARD, TransactionType.WELCOME_REWARD].includes(
              record.type
            );
          default:
            return true;
        }
      }),
    [records, selectedTransactionType]
  );

  const switchTab = (transactionType: TransactionFilterType): void => {
    setSelectedTransactionType(transactionType);
    if (transactionType === TransactionFilterType.ALL) {
      clearParams();
    } else {
      setParams(TRANSACTION_FILTER_PARAM_KEY, transactionType);
    }
  };

  return (
    <ShopPage>
      <Container>
        <Title category="h2" translationKey="transactions.detail.title" color="primary" />
        <TabBar<TransactionFilterType>
          tabs={[
            { value: TransactionFilterType.ALL, label: 'transactions.type.all' },
            { value: TransactionFilterType.SPEND, label: 'transactions.type.transfer' },
            { value: TransactionFilterType.EARN, label: 'transactions.type.earn' },
          ]}
          selectedTab={selectedTransactionType}
          onTabChange={switchTab}
          isLoading={isLoading}
          tabClassName={(tab) => gaClassName(`transactions-tab-${tab}`)}
        />
        <Spacer />
        <TransactionList records={filteredRecords} isLoading={isLoading} isTimeSeparated />
      </Container>
    </ShopPage>
  );
}
