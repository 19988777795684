import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import { NameAndAddressDisplay } from './NameAndAddressDisplay';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FromAddressWrapper = styled.div`
  margin-bottom: 16px;
`;

const ToAddressWrapper = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  fromName?: string;
  fromAddress?: string;
  toName?: string;
  toAddress?: string;
  variant?: Parameters<typeof LocalizedText>[0]['variant'];
}

export default function TransactionPreview(props: Props): JSX.Element {
  const { fromName, fromAddress, toName, toAddress } = props;

  return (
    <Wrapper>
      <FromAddressWrapper>
        <LocalizedText category="body2" translationKey="transactions.detail.from-label" />
        <NameAndAddressDisplay name={fromName} address={fromAddress} />
      </FromAddressWrapper>
      <ToAddressWrapper>
        <LocalizedText category="body2" translationKey="transactions.detail.to-label" />
        <NameAndAddressDisplay name={toName} address={toAddress} />
      </ToAddressWrapper>
    </Wrapper>
  );
}
