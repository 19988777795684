import styled from 'styled-components';
import Button from 'components/Button';
import { successModalIcon } from 'components/images';
import { TokenAmountCard } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'h1',
})`
  text-align: inherit;
  white-space: pre-line;
`;

const Subtitle = styled(LocalizedText).attrs({ category: 'body1' })`
  color: ${({ theme }) => theme.color.tinted};
  text-align: inherit;
  white-space: pre-line;
`;

const Description = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'body2',
})`
  text-align: inherit;
`;

const SuccessIconImage = styled.img.attrs({ src: successModalIcon })`
  height: 104px;
`;
interface Props {
  onComplete: () => void;
  campaignRewardAmount: string;
}

export default function SuccessBlock({ onComplete, campaignRewardAmount }: Props): JSX.Element {
  return (
    <Container>
      <Header>
        <Title translationKey="campaign.success.title" />
        <Subtitle translationKey="campaign.success.subtitle" />
      </Header>
      <SuccessIconImage />
      <TokenAmountCard
        amount={Number(campaignRewardAmount).toFixed(2)}
        translationKey="campaign.success.amount-label"
      />
      <Description translationKey="campaign.success.description" />
      <Button variant="secondary" onClick={onComplete} className={gaClassName('campaign-modal-complete')}>
        <LocalizedText category="body1" weight="medium" translationKey="campaign.success.button-escape" />
      </Button>
    </Container>
  );
}
