import { Env } from '@adam-vault/adam-sdk';
import { SelfClaim } from '@adam-vault/adam-sdk-coffeedao';
import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

export const metaAtom = loadable(
  atom(async () => {
    const coffeeDaoMeta = await SelfClaim.getCoffeeDaoMeta(process.env.REACT_APP_ENV as Env);
    return coffeeDaoMeta;
  })
);
