import { Env } from '@adam-vault/adam-sdk';
import { QrCode, SelfClaim } from '@adam-vault/adam-sdk-coffeedao';
import { CustomError } from 'constants/error/CustomError';
import { QRCodeType } from 'constants/qrCode';
import { toast } from 'utils/toastUtils';

export async function decodeQrCode(
  qrCodeIdentifier: string,
  options: { skipError?: boolean } = { skipError: false }
): Promise<QrCode | undefined> {
  if (!qrCodeIdentifier) {
    return undefined;
  }

  try {
    const coffeeDaoQrCode = await SelfClaim.lookupCoffeeDaoQrCode(process.env.REACT_APP_ENV as Env, qrCodeIdentifier);
    const { usageType } = coffeeDaoQrCode;

    // The type defined in the SDK is incorrect, usageType can return 'reward' as value
    if (usageType !== QRCodeType.PAYMENT && (usageType as QRCodeType) !== QRCodeType.REWARD) {
      throw new Error('Invalid QR code');
    }

    return coffeeDaoQrCode;
  } catch {
    if (!options.skipError) {
      toast(CustomError.INVALID_QR_CODE.message);
    }
  }
}
