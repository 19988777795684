import { TranslationKey } from 'components/LocalizedText';
import LocaleError from './LocaleError';

export enum ServerMobileRegistrationErrorType {
  MOBILE_ALREADY_REGISTERED = 'Mobile already registered',
  ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT = 'One Time Passcode request too frequent',
  ONE_TIME_PASSCODE_EXPIRED = 'One Time Passcode expired.',
  ONE_TIME_PASSCODE_INCORRECT = 'One Time Passcode incorrect.',
}

export const ServerMobileRegistrationErrorTranslationKey: {
  [key in ServerMobileRegistrationErrorType]: TranslationKey;
} = {
  [ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED]: 'welcome-reward.verify.error.mobile-registered',
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT]:
    'welcome-reward.verify.error.request-too-frequent',
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED]: 'welcome-reward.verify.error.otp-expired',
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT]: 'welcome-reward.verify.error.otp-incorrect',
};

export const ServerMobileRegistrationError: { [key in ServerMobileRegistrationErrorType]: LocaleError } = {
  [ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED]: {
    name: ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED,
    message: ServerMobileRegistrationErrorTranslationKey[ServerMobileRegistrationErrorType.MOBILE_ALREADY_REGISTERED],
  },
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT]: {
    name: ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT,
    message:
      ServerMobileRegistrationErrorTranslationKey[
        ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_REQUEST_TOO_FREQUENT
      ],
  },
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED]: {
    name: ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED,
    message: ServerMobileRegistrationErrorTranslationKey[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_EXPIRED],
  },
  [ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT]: {
    name: ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT,
    message: ServerMobileRegistrationErrorTranslationKey[ServerMobileRegistrationErrorType.ONE_TIME_PASSCODE_INCORRECT],
  },
};
