import { useEffect } from 'react';
import { SPEND_QR_CODE_ID_PARAM_KEY, SPEND_TO_PARAM_KEY } from 'constants/searchParams';
import { useSpendDetailFromQrCode } from 'hooks/spendToken';
import useQrCodeAtom from 'hooks/useQrCodeAtom';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import { spendTokenQrCodeAtom } from 'store/spendAtom';

export default function SpendTokenWithQrCodeManager(): null {
  const { isLoading, coffeeShop, spendAmount } = useSpendDetailFromQrCode();
  const {
    params: [qrCodeIdentifier],
    clearParams,
    setParams,
  } = useSearchParamKeys(SPEND_QR_CODE_ID_PARAM_KEY);

  useQrCodeAtom({
    qrCodeAtom: spendTokenQrCodeAtom,
    qrCodeIdentifier: qrCodeIdentifier || undefined,
    skipError: qrCodeIdentifier === 'open',
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (coffeeShop && spendAmount) {
      clearParams(SPEND_QR_CODE_ID_PARAM_KEY);
      setParams(SPEND_TO_PARAM_KEY, coffeeShop.eoaAddress);
    }
  }, [clearParams, coffeeShop, isLoading, setParams, spendAmount]);

  return null;
}
