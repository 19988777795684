import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getWeb3AuthRedirectRoute } from 'utils/web3authUtil';
import RoutesComponent from './RouteComponents';

export default function HashRoute(): JSX.Element {
  const location = useLocation();
  const redirectRoute = useMemo(() => getWeb3AuthRedirectRoute(location.pathname), [location.pathname]);

  if (redirectRoute) {
    window.history.pushState('', document.title, '/');
    return <Navigate to={redirectRoute} replace />;
  }

  const isHashNotFound = location.pathname === '/' && !window.location.hash.startsWith('#'); // isHashNotFound

  if (isHashNotFound) {
    // eslint-disable-next-line no-console
    console.warn("Hash not found, add hash '#/' to url", window.location.href);
    const { pathname, search } = window.location;
    window.history.pushState('', document.title, '/');
    return <Navigate to={`${pathname}${search}`} replace />;
  }

  const isNormalHashFound = /^[^/]/.test(location.pathname) && /^#[^/]/.test(window.location.hash); // isNormalHashFound

  if (isNormalHashFound) {
    // eslint-disable-next-line no-console
    console.warn("Hash found but invalid, change '#' to '#/'", window.location.href);
    const { pathname, search, hash } = window.location;
    window.history.pushState('', document.title, '/');
    return <Navigate to={`/zh${pathname}${search}${hash}`} replace />;
  }

  const isPathnameFoundBeforeHash = /^\/.+/.test(location.pathname) && window.location.pathname !== '/'; // isPathnameFoundBeforeHash

  // Basically under IPFS, this will not happen
  // It is because pathname before hash is invalid (require HashRouter)
  if (isPathnameFoundBeforeHash) {
    // eslint-disable-next-line no-console
    console.warn("Pathname found before hash, move pathname after '#/'", window.location.href);
    const { pathname, search, hash } = window.location;
    window.history.pushState('', document.title, '/');
    return <Navigate to={`${pathname.replace(/\/$/, '')}${hash.replace('#', '')}${search}`} replace />;
  }

  return <RoutesComponent />;
}
