import { CoffeeShop, SUBSIDY_TRANSACTION_STATUS, SubsidyPaymentParams } from '@adam-vault/adam-sdk-coffeedao';
import { BigNumber } from 'ethers';
import { useCallback, useState } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { toast } from 'utils/toastUtils';
import useCoffeeDao from '../useCoffeeDao';
import useCoffeeDaoClient from '../useCoffeeDaoClient';
import useWeb3 from '../useWeb3';

interface Return {
  spendToken: (coffeeShop: CoffeeShop, spendAmountBN: BigNumber) => Promise<SUBSIDY_TRANSACTION_STATUS>;
  isLoading: boolean;
}

export default function useSpendToken(): Return {
  const coffeeDaoClient = useCoffeeDaoClient();
  const coffeeDao = useCoffeeDao();
  const { address: eoaAddress, signer } = useWeb3();
  const [isLoading, setIsLoading] = useState(false);

  const spendToken = useCallback(
    async (coffeeShop: CoffeeShop, spendAmountBN: BigNumber) => {
      if (!coffeeShop || !spendAmountBN || !eoaAddress || !signer || !coffeeDao || !coffeeDaoClient) {
        toast(CustomError[CustomErrorType.GENERIC_ERROR].message);
        return SUBSIDY_TRANSACTION_STATUS.CANCELED;
      }

      if (!spendAmountBN || spendAmountBN.isZero()) {
        toast(CustomError[CustomErrorType.SPEND_AMOUNT_ZERO].message);
        return SUBSIDY_TRANSACTION_STATUS.CANCELED;
      }

      try {
        setIsLoading(true);
        const params: SubsidyPaymentParams = {
          amount: spendAmountBN,
          from: eoaAddress,
          // eslint-disable-next-line no-underscore-dangle
          signFcn: (a, b, c) => signer._signTypedData(a, b, c),
          to: coffeeShop.eoaAddress,
        };
        sendGTMEvent(GTMCustomEvent.SpendTokenProcessing, 'shopName', coffeeShop.name);
        const subsidyTransaction = await coffeeDaoClient.request(coffeeDao.createPaymentTxn(params));
        sendGTMEvent(GTMCustomEvent.SpendTokenCompleted, 'shopName', coffeeShop.name);
        return subsidyTransaction.status;
      } catch (err) {
        const { message } = err as Error;
        // eslint-disable-next-line no-console
        console.error(message);
        toast(CustomError[CustomErrorType.SPEND_TOKEN_FAIL].message);
        return SUBSIDY_TRANSACTION_STATUS.CANCELED;
      } finally {
        setIsLoading(false);
      }
    },
    [coffeeDao, coffeeDaoClient, eoaAddress, signer]
  );

  return {
    spendToken,
    isLoading,
  };
}
