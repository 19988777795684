import copy from 'copy-to-clipboard';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const BROWSER_REGEX = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
  chrome: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
  safari: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
  ie: /IEMobile|MSIEMobile/,
  firefox: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
  linkedIn: /\bLinkedInApp\b/,
};

const MOBILE_REGEX = /(iPad|iPhone|Android|Mobile)/i;
const IOS_REGEX = /(iPhone|iPod|iPad)/gi;
const ANDROID_REGEX = /Android.*(wv)/gi;

export type BROWSER = keyof typeof BROWSER_REGEX | 'other';
export class InApp {
  ua = '';

  constructor(useragent: string) {
    this.ua = useragent;
  }

  get browser(): BROWSER {
    const browserKey = Object.keys(BROWSER_REGEX) as Array<keyof typeof BROWSER_REGEX>;
    return browserKey.find((key) => BROWSER_REGEX[key as keyof typeof BROWSER_REGEX].test(this.ua)) || 'other';
  }

  get os(): 'ios' | 'android' | 'other' {
    if (IOS_REGEX.test(this.ua)) {
      return 'ios';
    }
    if (ANDROID_REGEX.test(this.ua)) {
      return 'android';
    }
    return 'other';
  }

  get isMobile(): boolean {
    return MOBILE_REGEX.test(this.ua) || false;
  }

  get isDesktop(): boolean {
    return !this.isMobile;
  }

  get isInApp(): boolean {
    const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv)'];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return Boolean(this.ua.match(regex));
  }
}

export function checkInApp(): InApp {
  const inApp = new InApp(navigator.userAgent);
  return inApp;
}

export function nativeShareOrCopy({ link, text }: { link?: string; text?: string }): void {
  if (navigator.share) {
    navigator.share({
      title: 'CoffeeDao',
      text,
      url: link,
    });
  } else if (link) {
    copy(link);
    toast(i18next.t('text-copied.link'), { toastId: 'link-copy' });
  } else if (text) {
    copy(text);
    toast(i18next.t('text-copied.general'), { toastId: 'text-copy' });
  }
}
