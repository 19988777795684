import styled from 'styled-components';
import IconButton from 'components/IconButton';
import { CoffeeDaoLogo } from 'components/Logo';

const Header = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

interface Props {
  variant?: 'primary' | 'secondary';
  onClose?: () => void;
  closeButtonClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

export default function TransferHeader({
  variant = 'primary',
  onClose,
  closeButtonClassName,
  className,
  style,
}: Props): JSX.Element {
  return (
    <Header className={className} style={style}>
      <CoffeeDaoLogo variant={variant} height="32px" />
      {onClose && (
        <CloseButton
          icon="close"
          variant={variant}
          buttonColor="transparent"
          onClick={onClose}
          className={closeButtonClassName}
          withoutBorder
        />
      )}
    </Header>
  );
}
