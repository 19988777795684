import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { URL_INSTAGRAM, URL_TWITTER } from 'constants/url';
import useLanguage from 'hooks/useLanguage';
import { gaClassName } from 'utils/gtmEventUtils';
import { SectionContainer } from './SectionContainer';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export default function SocialMediaSection(): JSX.Element | null {
  const { language, updateRouteLanguage } = useLanguage();

  const onClickChangeLanguage: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    updateRouteLanguage(e.currentTarget.value);
  };

  return (
    <SectionContainer>
      <FlexContainer>
        <Button
          icon="language"
          iconFirst
          variant="secondary"
          value={language === 'zh' ? 'en' : 'zh'}
          onClick={onClickChangeLanguage}
          minimize
          className={gaClassName(`home-page-language-${language === 'zh' ? 'en' : 'zh'}`)}
        >
          <LocalizedText category="body1" translationKey="home.footer.language" />
        </Button>
        <Link to={URL_TWITTER} target="_blank" className={gaClassName('home-page-twitter')}>
          <IconButton icon="twitter" variant="secondary" buttonSize="48px" />
        </Link>
        <Link to={URL_INSTAGRAM} target="_blank" className={gaClassName('home-page-instagram')}>
          <IconButton icon="instagram" variant="secondary" buttonSize="48px" />
        </Link>
      </FlexContainer>
    </SectionContainer>
  );
}
