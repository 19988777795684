import { SUBSIDY_TRANSACTION_STATUS, SUBSIDY_TRANSACTION_TYPE } from '@adam-vault/adam-sdk-coffeedao';
import { useCallback, useState } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { toast } from 'utils/toastUtils';
import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import useWelcomeRewardDetail from './useWelcomeRewardDetail';

interface Props {
  onClaimRewardSuccess: () => void;
}

interface Return {
  claimWelcomeReward: () => Promise<SUBSIDY_TRANSACTION_STATUS>;
  isLoading: boolean;
  error: string;
}

export default function useClaimWelcomeReward(props: Props): Return {
  const { onClaimRewardSuccess } = props;
  const coffeeDaoClient = useCoffeeDaoClient();
  const { welcomeRewardAmount } = useWelcomeRewardDetail();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const coffeeDao = useCoffeeDao();

  const claimWelcomeReward = useCallback(async () => {
    if (!coffeeDao || !coffeeDaoClient) {
      setError(CustomError[CustomErrorType.GENERIC_ERROR].message);
      toast(CustomError[CustomErrorType.GENERIC_ERROR].message);
      return SUBSIDY_TRANSACTION_STATUS.CANCELED;
    }
    try {
      setIsLoading(true);
      sendGTMEvent(GTMCustomEvent.WelcomeRewardProcessing, 'amount', welcomeRewardAmount);
      const welcomeRewardTransaction = await coffeeDaoClient.request(coffeeDao.createWelcomeRewardTxn());
      sendGTMEvent(GTMCustomEvent.WelcomeRewardCompleted, 'amount', welcomeRewardAmount);
      setIsLoading(false);
      onClaimRewardSuccess();
      return welcomeRewardTransaction.status;
    } catch (err) {
      const { message } = err as Error;
      // Special handling
      if (message === 'User is already rewarded') {
        const transactionList = await coffeeDaoClient.request(coffeeDao.getSubsidyTransactions(99, 0));
        const rewardTransaction = transactionList?.subsidyTransactions.find(
          (tx) => tx.type === SUBSIDY_TRANSACTION_TYPE.REWARD
        );
        if (
          rewardTransaction &&
          (rewardTransaction.status === SUBSIDY_TRANSACTION_STATUS.PENDING ||
            rewardTransaction.status === SUBSIDY_TRANSACTION_STATUS.COMPLETED)
        ) {
          onClaimRewardSuccess();
          return rewardTransaction.status;
        }
      }
      setError(message);
      toast(message);
      setIsLoading(false);
      return SUBSIDY_TRANSACTION_STATUS.CANCELED;
    }
  }, [coffeeDao, coffeeDaoClient, onClaimRewardSuccess, welcomeRewardAmount]);

  return { claimWelcomeReward, isLoading, error };
}
