import ReactPixel from 'react-facebook-pixel';

function init(facebookPixelId?: string): void {
  if (facebookPixelId) {
    ReactPixel.init(facebookPixelId, undefined, {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');
  }
}

export { init };
