import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import useTimer from 'hooks/useTimer';

const RetryCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 40px 40px 40px 0;
  border: 1px solid ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const RetryButton = styled(Button).attrs({ variant: 'text' })`
  display: flex;
  align-items: center;
  text-decoration: underline;

  &:disabled {
    opacity: 0.4;
  }
`;

interface Props {
  onRetry?: () => void;
}

export default function SignatureRequestRetryCard({ onRetry }: Props): JSX.Element {
  const { isCounting, timeLeft, countDown, clearTimer } = useTimer(5);

  const onClickRetry = (): void => {
    if (!isCounting) {
      countDown();
      onRetry?.();
      clearTimer();
    } else {
      toast('Request too frequent. Please try again later');
    }
  };

  return (
    <RetryCard>
      <LocalizedText category="body2" translationKey="signature-required.retry-label" />
      <RetryButton onClick={onClickRetry}>
        <LocalizedText category="body2" translationKey="signature-required.button-retry" />
        {isCounting && <Text category="body2">({timeLeft})</Text>}
      </RetryButton>
    </RetryCard>
  );
}
