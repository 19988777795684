import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ErrorBlock from 'components/ErrorBlock';
import { successModalBackground } from 'components/images';
import { gaClassName } from 'utils/gtmEventUtils';
import EditAmountBlock from './EditAmountBlock';
import LoadingBlock from './LoadingBlock';
import SpendHeader from './SpendHeader';
import SpendTokenBlock from './SpendTokenBlock';
import SuccessBlock from './SuccessBlock';

export enum SpendTokenStep {
  LOADING,
  EDIT_SPEND_AMOUNT,
  CONFIRM_SPEND_AMOUNT,
  COMPLETED,
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  overflow-y: auto;
`;

const SuccessContainer = styled.div`
  background-image: url(${successModalBackground});
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  isLoadingCoffeeShop: boolean;
  isSpending: boolean;
  isSpendCompleted: boolean;
  spendAmount: string;
  updateSpendAmount: (amount: string) => void;
  coffeeShop: CoffeeShop | null | undefined;
  onSpendToken: () => Promise<void>;
  onCloseModal: () => void;
}

const closeButtonClassName = gaClassName('spend-modal-close');
export default function SpendView(props: Props): JSX.Element | null {
  const {
    isLoadingCoffeeShop,
    isSpending,
    isSpendCompleted,
    spendAmount,
    updateSpendAmount,
    coffeeShop,
    onSpendToken,
    onCloseModal,
  } = props;

  const [step, setStep] = useState<SpendTokenStep>(SpendTokenStep.LOADING);

  useEffect(() => {
    if (isSpendCompleted) {
      setStep(SpendTokenStep.COMPLETED);
    } else if (!isLoadingCoffeeShop) {
      setStep(SpendTokenStep.CONFIRM_SPEND_AMOUNT);
    }
  }, [isLoadingCoffeeShop, isSpendCompleted]);

  const onEditRequest = (): void => {
    setStep(SpendTokenStep.EDIT_SPEND_AMOUNT);
  };

  const onEditConfirm = (amount?: string): void => {
    if (amount) {
      updateSpendAmount(amount);
    }
    setStep(SpendTokenStep.CONFIRM_SPEND_AMOUNT);
  };

  const onEditCancel = (): void => {
    setStep(SpendTokenStep.EDIT_SPEND_AMOUNT);
  };

  if (step === SpendTokenStep.LOADING) {
    return (
      <Container>
        <SpendHeader logo="secondary" />
        <LoadingBlock />
      </Container>
    );
  }

  if (!coffeeShop) {
    return (
      <ErrorContainer>
        <SpendHeader />
        <ErrorBlock onBack={onCloseModal} />
      </ErrorContainer>
    );
  }

  if (step === SpendTokenStep.COMPLETED) {
    return (
      <SuccessContainer>
        <SpendHeader logo="secondary" onClose={onCloseModal} closeButtonClassName={closeButtonClassName} />
        <SuccessBlock spendAmount={spendAmount} coffeeShopName={coffeeShop.name} onComplete={onCloseModal} />
      </SuccessContainer>
    );
  }

  if (step === SpendTokenStep.EDIT_SPEND_AMOUNT) {
    return (
      <Container>
        <SpendHeader onClose={onCloseModal} onBackClick={onEditCancel} closeButtonClassName={closeButtonClassName} />
        <EditAmountBlock spendAmount={spendAmount} onConfirm={onEditConfirm} onCancel={onEditCancel} />
      </Container>
    );
  }

  return (
    <Container>
      <SpendHeader onClose={onCloseModal} closeButtonClassName={closeButtonClassName} />
      <SpendTokenBlock
        spendAmount={spendAmount}
        coffeeShopName={coffeeShop.name}
        isSpending={isSpending}
        onSpendToken={onSpendToken}
        onEditAmount={onEditRequest}
      />
    </Container>
  );
}
