import styled from 'styled-components';
import {
  archFloralWhiteImage,
  bloomFloralWhiteImage,
  doubleStarImage,
  bigStarImage,
  coffeeBubbleImage,
  pseudoBackgroundCSS,
} from 'components/images';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import PATHS from 'constants/path';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSetIsReferralRewardModalOpen from 'hooks/useSetIsReferralRewardModalOpen';
import useSetIsWelcomeRewardModalOpen from 'hooks/useSetIsWelcomeRewardModalOpen';
import { gaClassName } from 'utils/gtmEventUtils';

const CardContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 4px;
`;

const StyledCard = styled(Card)`
  flex: 1;
  height: 128px;
  padding: 16px 12px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

const WelcomeRewardCard = styled(StyledCard)`
  color: ${({ theme: { palette } }) => palette.floralWhite};
  background-color: ${({ theme: { palette } }) => palette.burntOrange};

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    ${pseudoBackgroundCSS(bloomFloralWhiteImage, '130px', '130px')}
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    ${pseudoBackgroundCSS(bigStarImage, '28px', '28px')}
  }
`;

const ReferralCard = styled(StyledCard)`
  color: ${({ theme: { palette } }) => palette.tealGreen};
  background-color: ${({ theme: { palette } }) => palette.mustardYellow};

  &::before {
    content: '';
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    ${pseudoBackgroundCSS(coffeeBubbleImage, '40px', '52px')}
  }
`;

const SpendTokenCard = styled(StyledCard)`
  color: ${({ theme: { palette } }) => palette.floralWhite};
  background-color: ${({ theme: { palette } }) => palette.lavender};

  &::before {
    content: '';
    position: absolute;
    bottom: -82px;
    left: 24px;
    ${pseudoBackgroundCSS(archFloralWhiteImage, '120px', '145px')}
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 18px;
    ${pseudoBackgroundCSS(doubleStarImage, '55px', '50px')}
  }
`;

const CardTitle = styled(LocalizedText).attrs({ category: 'body1' })`
  letter-spacing: -0.02em;
  line-height: 18px;
  font-family: Homrice;
  text-align: left;

  &::after {
    content: ' >';
  }
`;

export default function ActionsCard(): JSX.Element | null {
  const setIsWelcomeRewardModalOpen = useSetIsWelcomeRewardModalOpen();
  const setIsReferralRewardModalOpen = useSetIsReferralRewardModalOpen();
  const navigate = useNavigateWithLang();

  const onClickWelcomeRewards = (): void => {
    setIsWelcomeRewardModalOpen(true);
  };

  const onClickReferralRewards = (): void => {
    setIsReferralRewardModalOpen(true);
  };

  const onClickSpendToken = (): void => {
    navigate(`/${PATHS.SCANNER}`);
  };

  return (
    <CardContainer>
      <WelcomeRewardCard as="button" onClick={onClickWelcomeRewards} className={gaClassName('home-page-claim-flow')}>
        <CardTitle translationKey="home.actions.welcome-reward" />
      </WelcomeRewardCard>
      <ReferralCard as="button" onClick={onClickReferralRewards} className={gaClassName('home-page-referral-flow')}>
        <CardTitle translationKey="home.actions.referral" />
      </ReferralCard>
      <SpendTokenCard as="button" onClick={onClickSpendToken} className={gaClassName('home-page-spend-flow')}>
        <CardTitle translationKey="home.actions.spend-token" />
      </SpendTokenCard>
    </CardContainer>
  );
}
