import { Env } from '@adam-vault/adam-sdk';
import { Suspense, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Modal } from 'components/Modal';
import { createI18nInstance } from 'constants/languages';
import { CAMPAIGN_PARAM_KEY, WELCOME_REWARD_PARAM_KEY } from 'constants/searchParams';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import ClaimView from './ClaimView';
import LoadingView from './LoadingView';

const i18n = createI18nInstance();

const ARBOR_CAMPAIGN: Record<Env, string> = {
  [Env.ALPHA]: '',
  [Env.DEV]: 'a57d15e8-9d09-446a-8fc9-b5d3a9c9479b',
  [Env.QA]: '1604a938-e1c8-49de-a280-14be7c4ccb4c',
  [Env.STG]: '',
  [Env.PROD]: '9f7e9099-86e7-41c7-8718-4d1f372a9e29',
};

export default function CampaignManager(): JSX.Element | null {
  const {
    params: [campaignId],
    clearParams,
    setParams,
  } = useSearchParamKeys(CAMPAIGN_PARAM_KEY, WELCOME_REWARD_PARAM_KEY);

  const isCampaignModalOpen = useMemo(() => Boolean(campaignId), [campaignId]);

  const onModalClose = (isOpenWelcomeRewardModal?: boolean): void => {
    clearParams(CAMPAIGN_PARAM_KEY);
    if (isOpenWelcomeRewardModal === true) {
      setParams(WELCOME_REWARD_PARAM_KEY, 'open');
    }
  };

  const isArborCampaign = ARBOR_CAMPAIGN[process.env.REACT_APP_ENV] === campaignId;

  return (
    <Suspense fallback={<LoadingView />}>
      <I18nextProvider i18n={i18n}>
        <Modal isOpen={isCampaignModalOpen}>
          <ClaimView onCloseModal={onModalClose} isArborCampaign={isArborCampaign} campaignId={campaignId} />
        </Modal>
      </I18nextProvider>
    </Suspense>
  );
}
