import styled from 'styled-components';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import Modal, { ModalProps } from './Modal';

const Content = styled.div`
  width: 100%;
  padding: 24px 16px 48px;
`;

const CloseButton = styled(IconButton).attrs({
  variant: 'secondary',
  iconSize: '16px',
  buttonSize: '40px',
  hasBorder: false,
  icon: 'close',
})`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface SmallModalProps extends Omit<ModalProps, 'size'> {
  closeButtonClassName?: string;
}

/**
 *
 * @example
 * import { SmallModal } from 'components/Modal';
 *
 *
 * <SmallModal isOpen={isModalOpen} onRequestClose={closeModal}>
 *   <SmallModal.Title translationKey="Title" />
 *   <SmallModal.Message translationKey="Message" />
 *   <SmallModal.Action>
 *      <Button onClick={onClick}>Click</Button>
 *   </SmallModal.Action>
 * </SmallModal>
 */
export default function SmallModal(props: SmallModalProps): JSX.Element | null {
  const { closeButtonClassName, onRequestClose, children, ...rest } = props;
  return (
    <Modal size="small" {...rest}>
      <Content>
        {onRequestClose && <CloseButton className={closeButtonClassName} onClick={onRequestClose} />}
        {children}
      </Content>
    </Modal>
  );
}

SmallModal.Title = styled(LocalizedText).attrs({ category: 'h2', variant: 'primary' })`
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
`;

SmallModal.Message = styled(LocalizedText).attrs({ category: 'body2' })`
  text-align: center;
`;

SmallModal.Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 36px;
  margin-top: auto;
`;
