import codes from './codes.json';

const CountryDialCodeMap = codes.reduce((acc, country) => {
  acc[country.dialCode] = country;
  return acc;
}, {} as Record<string, CountryDialCode>);

const PINNED_COUNTRY_DIAL_CODES = ['852', '65'];
const CountryDialCodeList = PINNED_COUNTRY_DIAL_CODES.map((dialCode) => CountryDialCodeMap[dialCode]).concat(
  codes.filter((country) => !PINNED_COUNTRY_DIAL_CODES.includes(country.dialCode))
);

const CountryDialCodes = CountryDialCodeList.map((country) => country.dialCode);

export { CountryDialCodes, CountryDialCodeList, CountryDialCodeMap };
export type CountryDialCode = (typeof codes)[number];

// export './codes.json';

// export interface CountryCallingCode {
//   code: string;
//   country: string;
// }

// export const countryCallingCodes: { [key in string]: CountryCallingCode } = {
//   '852': {
//     code: '852',
//     country: 'Hong Kong',
//   },
// };

// export const countryCallingCodeList = Object.values(countryCallingCodes);
