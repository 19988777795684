import { useSetAtom } from 'jotai';
import styled from 'styled-components';
import Button from 'components/Button';
import { coffeeDaoWhiteIcon } from 'components/images';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import useWelcomeRewardDetail from 'hooks/useWelcomeRewardDetail';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';

const ConnectWalletInstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ConnectWalletInstruction = styled(LocalizedText).attrs({ variant: 'secondary', category: 'body1' })`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  &::before {
    content: '';
    width: 32px;
    height: 32px;
    background: url(${coffeeDaoWhiteIcon});
  }
`;

export default function AuthenticateBlock(): JSX.Element {
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const { welcomeRewardAmount } = useWelcomeRewardDetail();

  return (
    <>
      <ModalCard image="coin">
        <ModalCard.Title translationKey="welcome-reward.authenticate.title" />
        <ModalCard.Subtitle translationKey="welcome-reward.authenticate.description" />
        <ConnectWalletInstructionContainer>
          <ConnectWalletInstruction
            translationKey="welcome-reward.authenticate.benefit-1"
            values={{ rewards: welcomeRewardAmount }}
          />
          <ConnectWalletInstruction translationKey="welcome-reward.authenticate.benefit-2" />
          <ConnectWalletInstruction translationKey="welcome-reward.authenticate.benefit-3" />
          <ConnectWalletInstruction translationKey="welcome-reward.authenticate.benefit-4" />
        </ConnectWalletInstructionContainer>
        <Button variant="secondary" onClick={() => setIsConnectWalletModalOpen(true)}>
          <LocalizedText category="body1" translationKey="welcome-reward.authenticate.button-login" />
        </Button>
      </ModalCard>
      <ModalCard.Footnote translationKey="welcome-reward.authenticate.footnote" />
    </>
  );
}
