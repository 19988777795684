import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultLanguage, LanguageKey, isLanguageKey } from 'constants/languages';
import { toast } from 'utils/toastUtils';

interface Return {
  language: LanguageKey;
  languagePath: LanguageKey;
  updateLanguage: (lang: string) => void;
  updateRouteLanguage: (lang: string) => void;
  toggleDebug: () => void;
}

export default function useLanguage(): Return {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const languagePath = location.pathname.split('/').find((path) => path === 'zh' || path === 'en') as LanguageKey;

  const language = i18n.language === 'cimode' ? DefaultLanguage : (i18n.language as LanguageKey) || 'zh';
  const updateLanguage = useCallback(
    (lang: string): void => {
      if (lang) {
        if (isLanguageKey(lang)) {
          i18n.changeLanguage(lang);
        } else {
          toast('Unknown language');
        }
      }
    },
    [i18n]
  );

  const updateRouteLanguage = useCallback(
    (lang: string) => {
      if (lang === 'en') {
        navigate(location.pathname.replace(/\/(en|zh)/, '/en'));
      } else if (lang === 'zh') {
        navigate(location.pathname.replace(/\/(en|zh)/, '/zh'));
      }
    },
    [location.pathname, navigate]
  );

  const toggleDebug = (): void => {
    i18n.changeLanguage(i18n.language === 'cimode' ? DefaultLanguage : 'cimode');
  };

  return {
    language,
    languagePath,
    updateLanguage,
    updateRouteLanguage,
    toggleDebug,
  };
}
