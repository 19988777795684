import styled from 'styled-components';
import IconCoffeeToken from 'assets/images/icon_coffee_token.svg';
import Text from 'components/Text';
import useTransactionDisplay from 'hooks/useTransactionDisplay';
import { TransactionRecord } from 'types/TransactionRecord';

export interface Props {
  className?: string;
  transactionRecord: TransactionRecord;
  onClickRecordBlock: (recordBlock: TransactionRecord) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.color.transactionBlockBorder};
  }

  &:active {
    opacity: 0.5;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransactionTitleText = styled(Text).attrs(() => ({ category: 'body1', weight: 'medium' }))``;

const TransactionDateText = styled(Text).attrs(() => ({ category: 'body2' }))`
  margin-top: -4px;
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 96px;
`;

const CoffeeTokenIcon = styled.img.attrs({ src: IconCoffeeToken })`
  width: 24px;
  height: 24px;
`;

const CoffeeTokenAmount = styled(Text).attrs(() => ({ category: 'h3', weight: 'medium' }))`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function TransactionRecordBlock(props: Props): JSX.Element | null {
  const { className, transactionRecord, onClickRecordBlock } = props;

  const getDisplay = useTransactionDisplay();
  const transactionDisplay = getDisplay(transactionRecord);

  if (!transactionDisplay) {
    return null;
  }

  const { titleText, amountText, dateTimeText } = transactionDisplay;

  const handleClick = (): void => {
    onClickRecordBlock(transactionRecord);
  };

  return (
    <Container className={className} onClick={handleClick}>
      <DetailContainer>
        <TransactionTitleText>{titleText}</TransactionTitleText>
        <TransactionDateText>{dateTimeText}</TransactionDateText>
      </DetailContainer>
      <AmountContainer>
        <CoffeeTokenIcon />
        <CoffeeTokenAmount>{amountText}</CoffeeTokenAmount>
      </AmountContainer>
    </Container>
  );
}
