import { useParams, useLocation, Navigate, Outlet } from 'react-router-dom';
import {
  CampaignManager,
  ConnectWalletManager,
  InAppWarningManager,
  ReferralManager,
  SessionManager,
  ShopRedirectManager,
  SpendTokenManager,
  SpendTokenWithQrCodeManager,
  ToastManager,
  UTMManager,
  WalletDetailManager,
  WelcomeRewardManager,
} from 'components/managers';
import { LanguageKey, isLanguageKey, DefaultLanguage } from 'constants/languages';
import useLanguage from 'hooks/useLanguage';

export function LangRoute(): JSX.Element {
  const { lang } = useParams<{ lang: LanguageKey | 'cimode' }>();
  const { updateLanguage, language: currentLang } = useLanguage();
  const { pathname, search, hash, state } = useLocation();

  if (!lang || !isLanguageKey(lang)) {
    // eslint-disable-next-line no-console
    console.warn(`Unknown language: ${lang}`);
    return <Navigate to={`/${currentLang || DefaultLanguage}${pathname}${search}${hash}`} replace state={state} />;
  }

  if (lang !== currentLang && isLanguageKey(lang)) {
    // eslint-disable-next-line no-console
    console.warn(`Incorrect language: ${lang}(route) !== ${currentLang}(current)`);
    updateLanguage(lang);
    return <Navigate to={`/${lang}${pathname}${search}${hash}`} replace state={state} />;
  }

  return (
    <>
      <Outlet />
      <UTMManager />
      <WalletDetailManager />
      <CampaignManager />
      <ReferralManager />
      <WelcomeRewardManager />
      <SpendTokenWithQrCodeManager />
      <SpendTokenManager />
      <InAppWarningManager />
      <ConnectWalletManager />
      <SessionManager />
      <ToastManager />
      <ShopRedirectManager />
    </>
  );
}
