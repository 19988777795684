import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

export interface LoadableAtomValue<TAtomType> {
  isLoading: boolean;
  data: TAtomType | undefined;
  error: Error | undefined;
}

export default function useLoadableAtom<TAtomType = unknown>(
  atom: ReturnType<typeof loadable<Promise<TAtomType>>>
): LoadableAtomValue<TAtomType> {
  const loadableValue = useAtomValue(atom);
  switch (loadableValue.state) {
    case 'loading':
      return { isLoading: true, data: undefined, error: undefined };
    case 'hasError':
      return { isLoading: false, data: undefined, error: loadableValue.error as Error };
    case 'hasData':
      return { isLoading: false, data: loadableValue.data, error: undefined };
  }
}
