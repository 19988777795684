import { getNetworkByChainId } from '@adam-vault/adam-frontend-shared';
import { Env } from '@adam-vault/adam-sdk';
import { CoffeeDao } from '@adam-vault/adam-sdk-coffeedao';
import { DEFAULT_CHAIN_ID, DEFAULT_NETWORK } from 'constants/chain';
import { JWT_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import { loadableAtomWithRefresh } from 'utils/atomUtils';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import { checkExp, decodeJwt } from 'utils/jwtUtils';
import { web3Atom } from './web3Atom';

export const sessionAtom = loadableAtomWithRefresh(async (get) => {
  const { isConnected, signer, chainId, address } = get(web3Atom);

  if (!isConnected) {
    // eslint-disable-next-line no-console
    console.log('[debug] wallet not connected. session return undefined');
    return undefined;
  }

  // Remove any signature in local storage if user is connected but no signer or chainId
  if (!chainId || !signer || !address) {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    return undefined;
  }

  const jwt = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
  const decodedJwt = decodeJwt(jwt);
  const network = getNetworkByChainId(chainId);

  // TODO: add supported network in environment config
  // Check for jwt in local storage and if it is expired
  if (!jwt) {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
  } else if (
    chainId !== DEFAULT_CHAIN_ID ||
    network !== DEFAULT_NETWORK ||
    decodedJwt?.senderAddress !== address ||
    checkExp(decodedJwt?.exp)
  ) {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
  } else {
    // Get the jwt from local storage if it exists and check if it is not expired
    sendGTMEvent(GTMCustomEvent.SignatureVerified, 'signature', jwt);
    return jwt;
  }

  sendGTMEvent(GTMCustomEvent.SignatureRequested, 'eoaAddress', (await signer.getAddress()).replace('0x', ''));
  const jwtToken = await CoffeeDao.getSession(signer, network, process.env.REACT_APP_ENV as Env);
  sendGTMEvent(GTMCustomEvent.SignatureSigned, 'signature', jwtToken);
  localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwtToken);
  return jwtToken;
});
