import { TranslationKey } from 'components/LocalizedText';
import LocaleError from './LocaleError';

export enum ServerGenericErrorType {
  AUTH_TOKEN_INVALID = 'Auth Token Invalid',
  UNSUPPORTED_NETWORK = 'Unsupported network',
  UNAUTHORIZED_USER_TYPE_MERCHANT = 'Unauthorized User Type: merchant',
  UNAUTHORIZED_USER_TYPE_CUSTOMER = 'Unauthorized User Type: customer',
}

export const ServerGenericErrorTranslationKey: { [key in ServerGenericErrorType]: TranslationKey } = {
  [ServerGenericErrorType.AUTH_TOKEN_INVALID]: 'error.server.auth-token-invalid',
  [ServerGenericErrorType.UNSUPPORTED_NETWORK]: 'error.server.unsupported-network',
  [ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_MERCHANT]: 'error.server.unauthorized-merchant',
  [ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_CUSTOMER]: 'error.server.unauthorized-customer',
};

export const ServerGenericError: { [key in ServerGenericErrorType]: LocaleError } = {
  [ServerGenericErrorType.AUTH_TOKEN_INVALID]: {
    name: ServerGenericErrorType.AUTH_TOKEN_INVALID,
    message: ServerGenericErrorTranslationKey[ServerGenericErrorType.AUTH_TOKEN_INVALID],
  },
  [ServerGenericErrorType.UNSUPPORTED_NETWORK]: {
    name: ServerGenericErrorType.UNSUPPORTED_NETWORK,
    message: ServerGenericErrorTranslationKey[ServerGenericErrorType.UNSUPPORTED_NETWORK],
  },
  [ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_MERCHANT]: {
    name: ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_MERCHANT,
    message: ServerGenericErrorTranslationKey[ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_MERCHANT],
  },
  [ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_CUSTOMER]: {
    name: ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_CUSTOMER,
    message: ServerGenericErrorTranslationKey[ServerGenericErrorType.UNAUTHORIZED_USER_TYPE_CUSTOMER],
  },
};
