import styled from 'styled-components';
import iconError from 'assets/images/icon_trash.svg';
import Button from './Button';
import LocalizedText from './LocalizedText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  height: 100%;
  text-align: center;
`;

const ErrorImage = styled.img.attrs({ src: iconError })`
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`;

const BackButton = styled(Button)`
  margin-top: 24px;
`;

interface Props {
  onBack: () => void;
}

export default function ErrorBlock({ onBack }: Props): JSX.Element {
  return (
    <Container>
      <ErrorImage />
      <LocalizedText category="h3" variant="primary" weight="medium" translationKey="error.title" />
      <LocalizedText category="body1" variant="primary" translationKey="error.description" />
      <BackButton onClick={onBack}>
        <LocalizedText category="body1" translationKey="error.button-escape" />
      </BackButton>
    </Container>
  );
}
