import { useMemo } from 'react';
import ReactLoading from 'react-loading';
import styled, { useTheme } from 'styled-components';

interface Props {
  height?: number;
  width?: number;
  isCentered?: boolean;
  variant?: 'primary' | 'secondary';
}

const CenteredWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function LoadingSpinner(props: Props): JSX.Element {
  const { height = 48, width = 48, isCentered, variant = 'primary' } = props;
  const theme = useTheme();

  const getColor = (): string => {
    switch (variant) {
      case 'primary':
        return theme.color.loadingPrimary;
      case 'secondary':
        return theme.color.loadingSecondary;
    }
  };

  const color = getColor();

  const loadingElement = useMemo(
    () => <ReactLoading type="spinningBubbles" color={color} height={height} width={width} />,
    [color, height, width]
  );

  return isCentered ? <CenteredWrapper>{loadingElement}</CenteredWrapper> : loadingElement;
}
