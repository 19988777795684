import copy from 'copy-to-clipboard';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { toast } from 'utils/toastUtils';
import { BROWSER, checkInApp } from 'utils/userAgentUtils';

const Container = styled.div`
  padding: 24px;
  border-radius: 24px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme: { color } }) => color.primary};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 16px;
`;

const Title = styled(LocalizedText).attrs({ category: 'h3', variant: 'secondary', weight: 'medium' })`
  grid-column: 1 / span 2;
  grid-row: 1;
`;

const Description = styled(LocalizedText).attrs({ category: 'body1', variant: 'secondary' })`
  grid-column: 1;
  grid-row: 2;
`;

const CopyIcon = styled(IconButton).attrs({
  icon: 'copy',
  buttonSize: '48px',
  iconSize: '24px',
  variant: 'secondary',
})`
  grid-column: 2;
  grid-row: 2;
`;

export default function WarningSection(): JSX.Element | null {
  const { t } = useTranslation();
  const isInAppBrowser = useMemo(() => {
    const inAppChecking = checkInApp();
    const inAppBrowser: BROWSER[] = ['facebook', 'instagram', 'line', 'twitter', 'wechat', 'linkedIn'];
    return inAppChecking.isMobile && inAppBrowser.includes(inAppChecking.browser);
  }, []);

  const onClickCopyLink = (): void => {
    copy(window.location.href);
    toast(t('text-copied.link'));
  };

  if (!isInAppBrowser) {
    return null;
  }

  return (
    <Container>
      <Title translationKey="wallet.connect.warning-title" />
      <Description translationKey="wallet.connect.warning-description" />
      <CopyIcon onClick={onClickCopyLink} />
    </Container>
  );
}
