import { transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

const SCROLLBAR_SIZE = 6;

const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: calc(var(--app-vh, 1vh) * 100);
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
    user-select: none;
  }

  * {
    /* Scrollbar */
    // Firefox styles
    scrollbar-color: ${({ theme }): string => transparentize(0.6, theme.color.scrollbar)};
    scrollbar-width: thin;
  
    // Webkit supported browsers styles
    &::-webkit-scrollbar {
      // For vertical scrollbar
      width: ${SCROLLBAR_SIZE}px;
      // For horizontal scrollbar
      height: ${SCROLLBAR_SIZE}px;
      scroll-behavior: smooth;
    }
  
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }): string => transparentize(0.6, theme.color.scrollbar)};
        border-radius: ${SCROLLBAR_SIZE / 2}px;
      }
    }
  }

  textarea,
  input[type='range'],
  input,
  input:matches([type='password'], [type='search']) {
    padding: 1px 2px; // HOTFIX: override safari user agent stylesheet
  }

  html {
    height: 100%;
    font-family: 'made-okine-sans';
    background-color: #333333;
  }

  body {
    margin: 0;
    font-weight: 400;
    word-wrap: break-word;
    word-break: break-word;
    color: ${({ theme }) => theme.color.onPage};
  }

  #root {
    height: 100%;
    white-space: pre-line;
  }

  button {
    font-weight: 300;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:focus {
      outline: none;
    }

    &:disabled {
      /* background-color: $button-disabled-background-color; */
      cursor: default !important;
    }

    &[type='button']:not(:disabled),
    &[type='reset']:not(:disabled),
    &[type='submit']:not(:disabled),
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  input {
    background-color: ${({ theme: { color } }) => color.textInputBackground};
    color: ${({ theme: { color } }) => color.textInputFont};
    cursor-color: ${({ theme: { color } }) => color.secondary};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default GlobalStyle;
