import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useMemo } from 'react';
import useCoffeeShops from './useCoffeeShopList';

export default function useCoffeeShopProfile(eoaAddress: string): {
  isLoading: boolean;
  coffeeShop: CoffeeShop | null;
} {
  const { isLoading, coffeeShops, error } = useCoffeeShops();

  const coffeeShop = useMemo(() => {
    if (error || isLoading) {
      return null;
    }

    return coffeeShops.find((shop) => shop.eoaAddress === eoaAddress) ?? null;
  }, [coffeeShops, eoaAddress, error, isLoading]);

  return { isLoading, coffeeShop };
}
