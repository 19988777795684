import { ControlledInput, ControlledInputProps } from '@adam-vault/adam-frontend-shared';
import { FieldValues } from 'react-hook-form';
import styled, { css } from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';

export interface Props<TFieldValues extends FieldValues>
  extends ControlledInputProps<TFieldValues>,
    ErrorMessageProps {}

const Container = styled.div<{ hasError?: boolean }>`
  ${({ theme: { color }, hasError }) =>
    hasError &&
    css`
      input {
        border: 2px solid ${color.error};
      }
    `};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-left: 24px;
`;

const StyledInput = styled(ControlledInput)`
  border-radius: 40px;
  width: 100%;
  height: 48px;
  padding: 14px 24px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};

  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${({ theme: { color } }) => color.textInputFont};

  ::placeholder {
    color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  }
` as <TFieldValues extends FieldValues>(
  props: ControlledInputProps<TFieldValues> & React.RefAttributes<HTMLInputElement>
) => React.ReactElement;

export default function TextInputField<TFieldValues extends FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { className, errorMessage, ...inputProps } = props;
  return (
    <Container className={className} hasError>
      <StyledInput {...inputProps} />
      {!!errorMessage && <StyledErrorMessage errorMessage={errorMessage} />}
    </Container>
  );
}
