import {
  ControlledFieldPath,
  ControlledFieldPathValue,
  ControlledInput,
  ControlledInputProps,
} from '@adam-vault/adam-frontend-shared';
import React, { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { CountryDialCodeMap, CountryDialCode, CountryDialCodes } from 'constants/countryDialCodes';
import Dropdown from './Dropdown';

interface DialCodeFieldValues extends FieldValues {
  countryCode: CountryDialCode['dialCode'];
}

interface Props<TFieldValues extends FieldValues = DialCodeFieldValues>
  extends ControlledInputProps<TFieldValues>,
    ErrorMessageProps {
  countryCodeName?: ControlledFieldPath<TFieldValues>;
  onReset?: () => void;
}

interface StyledInputProps {
  hasError?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const InputContainer = styled.div<StyledInputProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 40px;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  gap: 16px;
  background-color: ${({ theme: { color } }) => color.dropdownBackground};
  border: ${({ theme: { color }, hasError }) => (hasError ? `1px solid ${color.error}` : '')};
`;

const StyledInput = styled((props) => <ControlledInput {...props} />)`
  height: 100%;
  padding-right: 24px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${({ theme: { color } }) => color.textInputFont};

  ::placeholder {
    color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  }
` as <TFieldValues extends FieldValues>(
  props: ControlledInputProps<TFieldValues> & React.RefAttributes<HTMLInputElement>
) => React.ReactElement;

const CloseButton = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.secondary};
  display: grid;
  place-items: center;
`;

export default function PhoneNumberInputField<TFieldValues extends DialCodeFieldValues>(
  props: Props<TFieldValues>
): JSX.Element {
  const { errorMessage, countryCodeName, onReset, name, control, ...inputProps } = props;

  const renderCustomButtonContent = useCallback(
    (dialCode: CountryDialCode['dialCode'] | undefined): JSX.Element => (
      <Text category="caption">{dialCode ? `+${dialCode}` : ''}</Text>
    ),
    []
  );

  const renderItem = useCallback((dialCode: CountryDialCode['dialCode'] | undefined): JSX.Element | null => {
    if (!dialCode) {
      return null;
    }
    const item = CountryDialCodeMap[dialCode];
    return <Text category="caption">{`${item.country} (${item.dialCode})`}</Text>;
  }, []);

  return (
    <Container>
      <InputContainer hasError={!!errorMessage}>
        {countryCodeName && (
          <Dropdown<TFieldValues>
            control={control}
            items={CountryDialCodes as ControlledFieldPathValue<TFieldValues>[]}
            name={countryCodeName}
            renderCustomButtonContent={renderCustomButtonContent}
            renderItem={renderItem}
            width={200}
          />
        )}
        <StyledInput control={control} name={name} inputMode="numeric" pattern="[0-9]*" type="text" {...inputProps} />
        <CloseButton onClick={onReset}>
          <Icon icon="close" size="16px" color="primary" />
        </CloseButton>
      </InputContainer>
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} contrast />}
    </Container>
  );
}
