import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useSearchParamKeys(...paramKeys: string[]): {
  params: (string | null)[];
  setParams: (paramKey: string, value: string) => void;
  clearParams: (clearParamKey?: string) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValues = paramKeys.map((paramKey) => searchParams.get(paramKey));

  const setParams = useCallback(
    (paramKey: string, value: string) => {
      setSearchParams((prev) => {
        prev.set(paramKey, value);
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearParams = useCallback(
    (clearParamKey?: string): void => {
      setSearchParams((prev) => {
        if (clearParamKey) {
          prev.delete(clearParamKey);
          return prev;
        }

        paramKeys.forEach((paramKey) => {
          prev.delete(paramKey);
        });
        return prev;
      });
    },
    [paramKeys, setSearchParams]
  );

  return { params: paramValues, setParams, clearParams };
}
