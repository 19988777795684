import { useEffect } from 'react';
import styled from 'styled-components';
import QrCodeIcon from 'assets/images/icon_qr_code.svg';
import IconButton from 'components/IconButton';
import { coffeeDaoWhiteIcon } from 'components/images';
import { Card } from 'components/layout';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import PATHS from 'constants/path';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSetIsWalletDetailModalOpen from 'hooks/useSetIsWalletDetailModalOpen';

const BalanceCardContainer = styled(Card)`
  align-items: center;
  padding: 16px 20px;
  gap: 12px;
`;

const BalanceCardTokenImg = styled.img.attrs({ src: coffeeDaoWhiteIcon })`
  width: 72px;
  height: 72px;
`;

const BalanceTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  text-align: left;
`;

const QrCodeButton = styled(IconButton).attrs({ variant: 'secondary', buttonSize: '40px' })`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const QrCodeImg = styled.img.attrs({ src: QrCodeIcon })``;

export default function BalanceCard(): JSX.Element {
  const { balanceDisplay, isLoading, refreshBalance } = useCoffeeDaoTokenBalance();
  const setIsWalletDetailModalOpen = useSetIsWalletDetailModalOpen();
  const navigate = useNavigateWithLang();

  useEffect(() => {
    refreshBalance();
  }, [refreshBalance]);

  const onClickQrCode = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsWalletDetailModalOpen(true);
  };

  const onClickWalletBalance = (): void => navigate(`/${PATHS.WALLET}`);

  return (
    <BalanceCardContainer onClick={onClickWalletBalance}>
      <BalanceCardTokenImg />
      <BalanceTextContainer>
        <LocalizedText translationKey="wallet.balance.balance-label" category="body2" variant="secondary" />
        {isLoading ? (
          <LoadingSpinner width={52} height={52} />
        ) : (
          <Text category="h0" variant="secondary">
            {balanceDisplay || '-'}
          </Text>
        )}
      </BalanceTextContainer>
      <QrCodeButton onClick={onClickQrCode}>
        <QrCodeImg />
      </QrCodeButton>
    </BalanceCardContainer>
  );
}
