import { TranslationKey } from 'components/LocalizedText';
import LocaleError from './LocaleError';

export enum CustomErrorType {
  GENERIC_ERROR = 'GENERIC_ERROR',
  LOGIN_FAIL = 'LOGIN_FAIL',
  INVALID_QR_CODE = 'INVALID_QR_CODE',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  INVALID_DESTINATION = 'INVALID_DESTINATION',
  INVALID_TOKEN_ADDRESS = 'INVALID_TOKEN_ADDRESS',
  START_QR_CODE_SCANNER_FAIL = 'START_QR_CODE_SCANNER_FAIL',
  STOP_QR_CODE_SCANNER_FAIL = 'STOP_QR_CODE_SCANNER_FAIL',
  FETCH_SELF_INFO_FAIL = 'FETCH_SELF_INFO_FAIL',
  FETCH_COFFEE_TOKEN_FAIL = 'FETCH_COFFEE_TOKEN_FAIL',
  FETCH_COFFEE_TOKEN_BALANCE_FAIL = 'FETCH_COFFEE_TOKEN_BALANCE_FAIL',
  FETCH_COFFEE_SHOPS_FAIL = 'FETCH_COFFEE_SHOPS_FAIL',
  FETCH_EARN_RECORDS_FAIL = 'FETCH_EARN_RECORDS_FAIL',
  FETCH_SPEND_RECORDS_FAIL = 'FETCH_SPEND_RECORDS_FAIL',
  FETCH_PROCESSING_RECORDS_FAIL = 'FETCH_PROCESSING_RECORDS_FAIL',
  FETCH_SUBSIDY_TRANSACTIONS_FAIL = 'FETCH_SUBSIDY_TRANSACTIONS_FAIL',
  NUMBER_VERIFIED = 'NUMBER_VERIFIED',
  SPEND_AMOUNT_ZERO = 'SPEND_AMOUNT_ZERO',
  SPEND_TOKEN_FAIL = 'SPEND_TOKEN_FAIL',
}

export const CustomErrorTranslationKey: { [key in CustomErrorType]: TranslationKey } = {
  [CustomErrorType.GENERIC_ERROR]: 'error.custom.general',
  [CustomErrorType.LOGIN_FAIL]: 'error.custom.login-failed',
  [CustomErrorType.INVALID_QR_CODE]: 'error.custom.invalid-qr-code',
  [CustomErrorType.INVALID_PHONE_NUMBER]: 'error.custom.invalid-phone-number',
  [CustomErrorType.INVALID_DESTINATION]: 'error.custom.invalid-destination',
  [CustomErrorType.INVALID_TOKEN_ADDRESS]: 'error.custom.invalid-token-address',
  [CustomErrorType.START_QR_CODE_SCANNER_FAIL]: 'error.custom.start-scanner-failed',
  [CustomErrorType.STOP_QR_CODE_SCANNER_FAIL]: 'error.custom.stop-scanner-failed',
  [CustomErrorType.FETCH_SELF_INFO_FAIL]: 'error.custom.fetch-self-info-failed',
  [CustomErrorType.FETCH_COFFEE_TOKEN_FAIL]: 'error.custom.fetch-coffee-token-failed',
  [CustomErrorType.FETCH_COFFEE_TOKEN_BALANCE_FAIL]: 'error.custom.fetch-coffee-token-balance-failed',
  [CustomErrorType.FETCH_COFFEE_SHOPS_FAIL]: 'error.custom.fetch-coffee-shops-failed',
  [CustomErrorType.FETCH_EARN_RECORDS_FAIL]: 'error.custom.fetch-earn-records-failed',
  [CustomErrorType.FETCH_SPEND_RECORDS_FAIL]: 'error.custom.fetch-spend-records-failed',
  [CustomErrorType.FETCH_PROCESSING_RECORDS_FAIL]: 'error.custom.fetch-processing-records-failed',
  [CustomErrorType.FETCH_SUBSIDY_TRANSACTIONS_FAIL]: 'error.custom.fetch-subsidy-transactions-failed',
  [CustomErrorType.NUMBER_VERIFIED]: 'error.custom.number-verified',
  [CustomErrorType.SPEND_AMOUNT_ZERO]: 'error.custom.spend-amount-zero',
  [CustomErrorType.SPEND_TOKEN_FAIL]: 'error.custom.spend-token-failed',
};

export const CustomError: { [key in CustomErrorType]: LocaleError } = {
  [CustomErrorType.GENERIC_ERROR]: {
    name: CustomErrorType.GENERIC_ERROR,
    message: CustomErrorTranslationKey.GENERIC_ERROR,
  },
  [CustomErrorType.LOGIN_FAIL]: {
    name: CustomErrorType.LOGIN_FAIL,
    message: CustomErrorTranslationKey.LOGIN_FAIL,
  },
  [CustomErrorType.INVALID_QR_CODE]: {
    name: CustomErrorType.INVALID_QR_CODE,
    message: CustomErrorTranslationKey.INVALID_QR_CODE,
  },
  [CustomErrorType.INVALID_PHONE_NUMBER]: {
    name: CustomErrorType.INVALID_PHONE_NUMBER,
    message: CustomErrorTranslationKey.INVALID_PHONE_NUMBER,
  },
  [CustomErrorType.INVALID_DESTINATION]: {
    name: CustomErrorType.INVALID_DESTINATION,
    message: CustomErrorTranslationKey.INVALID_DESTINATION,
  },
  [CustomErrorType.INVALID_TOKEN_ADDRESS]: {
    name: CustomErrorType.INVALID_TOKEN_ADDRESS,
    message: CustomErrorTranslationKey.INVALID_TOKEN_ADDRESS,
  },
  [CustomErrorType.START_QR_CODE_SCANNER_FAIL]: {
    name: CustomErrorType.START_QR_CODE_SCANNER_FAIL,
    message: CustomErrorTranslationKey.START_QR_CODE_SCANNER_FAIL,
  },
  [CustomErrorType.STOP_QR_CODE_SCANNER_FAIL]: {
    name: CustomErrorType.STOP_QR_CODE_SCANNER_FAIL,
    message: CustomErrorTranslationKey.STOP_QR_CODE_SCANNER_FAIL,
  },
  [CustomErrorType.FETCH_SELF_INFO_FAIL]: {
    name: CustomErrorType.FETCH_SELF_INFO_FAIL,
    message: CustomErrorTranslationKey.FETCH_SELF_INFO_FAIL,
  },
  [CustomErrorType.FETCH_COFFEE_TOKEN_FAIL]: {
    name: CustomErrorType.FETCH_COFFEE_TOKEN_FAIL,
    message: CustomErrorTranslationKey.FETCH_COFFEE_TOKEN_FAIL,
  },
  [CustomErrorType.FETCH_COFFEE_TOKEN_BALANCE_FAIL]: {
    name: CustomErrorType.FETCH_COFFEE_TOKEN_BALANCE_FAIL,
    message: CustomErrorTranslationKey.FETCH_COFFEE_TOKEN_BALANCE_FAIL,
  },
  [CustomErrorType.FETCH_COFFEE_SHOPS_FAIL]: {
    name: CustomErrorType.FETCH_COFFEE_SHOPS_FAIL,
    message: CustomErrorTranslationKey.FETCH_COFFEE_SHOPS_FAIL,
  },
  [CustomErrorType.FETCH_EARN_RECORDS_FAIL]: {
    name: CustomErrorType.FETCH_EARN_RECORDS_FAIL,
    message: CustomErrorTranslationKey.FETCH_EARN_RECORDS_FAIL,
  },
  [CustomErrorType.FETCH_SPEND_RECORDS_FAIL]: {
    name: CustomErrorType.FETCH_SPEND_RECORDS_FAIL,
    message: CustomErrorTranslationKey.FETCH_SPEND_RECORDS_FAIL,
  },
  [CustomErrorType.FETCH_PROCESSING_RECORDS_FAIL]: {
    name: CustomErrorType.FETCH_PROCESSING_RECORDS_FAIL,
    message: CustomErrorTranslationKey.FETCH_PROCESSING_RECORDS_FAIL,
  },
  [CustomErrorType.FETCH_SUBSIDY_TRANSACTIONS_FAIL]: {
    name: CustomErrorType.FETCH_SUBSIDY_TRANSACTIONS_FAIL,
    message: CustomErrorTranslationKey.FETCH_SUBSIDY_TRANSACTIONS_FAIL,
  },
  [CustomErrorType.NUMBER_VERIFIED]: {
    name: CustomErrorType.NUMBER_VERIFIED,
    message: CustomErrorTranslationKey.NUMBER_VERIFIED,
  },
  [CustomErrorType.SPEND_AMOUNT_ZERO]: {
    name: CustomErrorType.SPEND_AMOUNT_ZERO,
    message: CustomErrorTranslationKey.SPEND_AMOUNT_ZERO,
  },
  [CustomErrorType.SPEND_TOKEN_FAIL]: {
    name: CustomErrorType.SPEND_TOKEN_FAIL,
    message: CustomErrorTranslationKey.SPEND_TOKEN_FAIL,
  },
};
