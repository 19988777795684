import styled from 'styled-components';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import { gaClassName } from 'utils/gtmEventUtils';

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ExpiredFlag = styled(LocalizedText).attrs({ category: 'body1' })`
  background-color: ${({ theme }) => theme.color.tinted};
  color: #9a7c13;
  padding: 4px 16px;
  height: 36px;
  border-radius: 18px;
`;

const Title = styled(Text).attrs({ category: 'h3', variant: 'primary', weight: 'medium' })``;

const Description = styled(Text).attrs({ category: 'callout', variant: 'primary' })`
  line-height: 28px;
  letter-spacing: normal;
  text-align: center;
  padding: 0 32px;
`;

const AuthenticateButton = styled(Button)`
  margin-top: 24px;
`;

interface Props {
  title: string;
  description: string;
  isExpired?: boolean;
  onAuthenticate?: () => void;
  isArborCampaign?: boolean;
}

export default function AuthenticateBlock({
  title,
  description,
  isExpired,
  onAuthenticate,
  isArborCampaign,
}: Props): JSX.Element {
  return (
    <DetailContainer>
      {isExpired && <ExpiredFlag translationKey="campaign.expired-tag" />}
      <Title>{title}</Title>
      <Description>{description}</Description>
      {!isExpired && (
        <AuthenticateButton onClick={onAuthenticate} className={gaClassName('campaign-modal-login')}>
          <LocalizedText
            category="body1"
            translationKey={
              isArborCampaign ? 'campaign.authenticate.button-login-arbor' : 'campaign.authenticate.button-login'
            }
          />
        </AuthenticateButton>
      )}
    </DetailContainer>
  );
}
