import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';

const ShopTag = styled(LocalizedText).attrs({ translationKey: 'coffee-shop.tag', category: 'body2', weight: 'medium' })`
  width: max-content;
  background: ${({ theme }) => theme.palette.floral06};
  color: ${({ theme }) => theme.color.onPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-weight: 500;
  height: 28px;
  border-radius: 14px;
`;

export default ShopTag;
