import { WalletType } from '@adam-vault/adam-frontend-shared';
import { SUBSIDY_TRANSACTION_STATUS } from '@adam-vault/adam-sdk-coffeedao';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useState, useEffect, useMemo } from 'react';
import { Modal } from 'components/Modal';
import { SignatureRequiredModal } from 'components/signature';
import { SPEND_CONFIRM_PARAM_KEY, SPEND_TO_PARAM_KEY } from 'constants/searchParams';
import { useSpendToken } from 'hooks/spendToken';
import useCoffeeShopProfile from 'hooks/useCoffeeShopProfile';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import { useCoffeeDaoToken } from 'hooks/useToken';
import useWeb3 from 'hooks/useWeb3';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { spendAmountAtom } from 'store/spendAtom';
import SpendView from './SpendView';

export default function SpendTokenManager(): JSX.Element {
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const web3 = useWeb3();

  const {
    params: [toAddress, spendConfirm],
    clearParams,
    setParams,
  } = useSearchParamKeys(SPEND_TO_PARAM_KEY, SPEND_CONFIRM_PARAM_KEY);
  const { isLoading: isLoadingCoffeeShop, coffeeShop } = useCoffeeShopProfile(toAddress || '');
  const [spendAmount, setSpendAmount] = useAtom(spendAmountAtom);

  const { toBN } = useCoffeeDaoToken();
  const spendAmountBN = useMemo(() => toBN(spendAmount || ''), [spendAmount, toBN]);

  const { spendToken, isLoading: isSpending } = useSpendToken();
  const { isAuthenticated } = useIsAuthenticated();
  const [isSpendCompleted, setIsSpendCompleted] = useState(false);

  useEffect(() => {
    if (isAuthenticated && spendConfirm === 'true' && !isSpending && coffeeShop && spendAmountBN) {
      spendToken(coffeeShop, spendAmountBN).then((status) => {
        if (status === SUBSIDY_TRANSACTION_STATUS.PENDING || status === SUBSIDY_TRANSACTION_STATUS.COMPLETED) {
          setIsSpendCompleted(true);
        }
      });
      clearParams(SPEND_CONFIRM_PARAM_KEY);
    }
  }, [clearParams, coffeeShop, isAuthenticated, spendConfirm, isSpending, spendAmountBN, spendToken]);

  const isSpendTokenModalOpen = Boolean(toAddress);
  const closeModal = (): void => {
    clearParams();
    setIsSpendCompleted(false);
    setSpendAmount(RESET);
  };

  const updateSpendAmount = (newAmount: string): void => {
    setSpendAmount(newAmount);
  };

  const confirmSpendToken = async (): Promise<void> => {
    setParams(SPEND_CONFIRM_PARAM_KEY, 'true');
    if (!isAuthenticated) {
      setIsConnectWalletModalOpen(true);
    }
  };

  return (
    <>
      <Modal isOpen={isSpendTokenModalOpen}>
        <SpendView
          isLoadingCoffeeShop={isLoadingCoffeeShop}
          isSpending={isSpending}
          isSpendCompleted={isSpendCompleted}
          spendAmount={spendAmount || ''}
          updateSpendAmount={updateSpendAmount}
          coffeeShop={coffeeShop}
          onSpendToken={confirmSpendToken}
          onCloseModal={closeModal}
        />
      </Modal>
      <SignatureRequiredModal isOpen={isSpending && web3.walletType !== WalletType.WEB3AUTH} />
    </>
  );
}
