import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import { URL_CONTACT_US, URL_PRIVACY_POLICY, URL_TERMS_AND_CONDITIONALS } from 'constants/url';
import { gaClassName } from 'utils/gtmEventUtils';
import { SectionContainer } from './SectionContainer';

const ExternalLinkWrapper = styled(LocalizedText).attrs({ variant: 'primary', category: 'caption' })`
  text-align: center;
`;

const ExternalLink = styled(Link).attrs(() => ({ target: '_blank' }))`
  color: inherit;
  text-decoration: underline;
`;

// TODO: add external link
export default function FooterLinkSection(): JSX.Element | null {
  return (
    <SectionContainer>
      <ExternalLinkWrapper
        translationKey="home.footer.links"
        components={[
          <ExternalLink to={URL_TERMS_AND_CONDITIONALS} className={gaClassName('home-page-t-and-c')} />,
          <ExternalLink to={URL_PRIVACY_POLICY} className={gaClassName('home-page-privacy-policy')} />,
          <ExternalLink to={URL_CONTACT_US} className={gaClassName('home-page-contact-us')} />,
        ]}
      />
    </SectionContainer>
  );
}
