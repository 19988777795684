import { formatUtils } from '@adam-vault/adam-frontend-shared';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserAvatar } from 'components/UserAvatar';
import { toast } from 'utils/toastUtils';
import Text from '../Text';

interface Props {
  className?: string;
  name?: string;
  address?: string;
}

const Container = styled.div``;

const AddressWrapper = styled.button`
  border: 1px solid ${({ theme }) => theme.color.buttonBorder};
  display: flex;
  column-gap: 6px;
  align-items: center;
  border-radius: 60px;
  padding: 4px 8px;
  width: fit-content;
`;

export function NameAndAddressDisplay(props: Props): JSX.Element {
  const { className, name, address } = props;
  const { t } = useTranslation();

  const onCopyAddress = (): void => {
    if (address) {
      copy(address);
      toast(t('text-copied.address'));
    }
  };

  return (
    <Container className={className}>
      {name && <Text category="body1">{name}</Text>}
      {address && (
        <AddressWrapper onClick={onCopyAddress}>
          <Text category="callout">{formatUtils.toEllipsisAddress(address)}</Text>
          <UserAvatar random />
        </AddressWrapper>
      )}
    </Container>
  );
}
