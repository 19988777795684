import styled from 'styled-components';
import LocalizedText, { TranslationKey } from 'components/LocalizedText';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 32px;
`;

const Step = styled(LocalizedText).attrs({
  category: 'body2',
  variant: 'secondary',
})`
  opacity: 0.4;
`;

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.secondary};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`;

interface Props {
  label: TranslationKey;
  step: number;
  totalStep: number;
}

export default function ModalStep(props: Props): JSX.Element {
  const { label, step, totalStep } = props;
  return (
    <Container>
      <Step translationKey={label} />
      {totalStep > 1 && step <= totalStep && (
        <DotContainer>
          {Array.from({ length: totalStep }, (_, index) => (
            <Dot key={index} active={index + 1 === step} />
          ))}
        </DotContainer>
      )}
    </Container>
  );
}
