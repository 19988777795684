import styled from 'styled-components';

const Card = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${({ theme: { color } }) => color.primary};
  border-radius: 24px;
  padding: 24px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export default Card;
