enum PATHS {
  HOME = '',
  WALLET = 'wallet',
  TRANSACTIONS = 'transactions',
  STORES = 'stores',
  NOTIFICATIONS = 'notifications',
  SCANNER = 'scanner',
  QR_CODE = 'qrcode',
  QR_CODE_EARN = 'qrcode-earn',
  SHOP = 'shop',
  SETTINGS = 'settings',
}

export default PATHS;
