import styled, { css } from 'styled-components';
import IconButton from 'components/IconButton';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';
import { TranslationKey } from 'constants/languages';
import { gaClassName } from 'utils/gtmEventUtils';
import { CampaignStep } from './formStep';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  position: relative;
  padding: 0 16px;
`;

const Title = styled(Text).attrs({ category: 'h3', variant: 'primary', weight: 'medium' })`
  margin-bottom: -16px;
  text-align: center;
`;

const StepContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
    `}
`;

const StepTag = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid ${({ theme: { color } }) => color.primary};
  color: ${({ theme: { color } }) => color.primary};
  display: grid;
  place-items: center;
  font-weight: 500;
  line-height: 1;
`;

const StepButton = styled(IconButton).attrs({
  variant: 'secondary',
  icon: 'next',
  buttonSize: '40px',
  iconSize: '24px',
})`
  margin-left: auto;

  &:disabled {
    opacity: 0.2;
  }
`;

const StepLoading = styled(IconButton).attrs({
  variant: 'secondary',
  buttonSize: '40px',
  children: <LoadingSpinner width={24} height={24} variant="secondary" />,
})`
  margin-left: auto;

  &:disabled {
    opacity: 0.2;
  }
`;

const StepCompletedIcon = styled(IconButton).attrs({
  icon: 'check',
  variant: 'primary',
  buttonSize: '40px',
  iconSize: '24px',
  withoutBorder: true,
})`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto;
`;

interface StepProps {
  step: number;
  description: TranslationKey;
  isCompleted: boolean;
  isLoading: boolean;
  onClaim: () => void;
  className?: string;
  disabled?: boolean;
}

function ClaimStep({
  step,
  description,
  isCompleted,
  onClaim,
  isLoading,
  disabled,
  className,
}: StepProps): JSX.Element {
  return (
    <StepContainer disabled={disabled}>
      <StepTag>{step}</StepTag>
      <LocalizedText category="h3" color="primary" weight="medium" translationKey={description} />
      {isCompleted ? (
        <StepCompletedIcon />
      ) : isLoading ? (
        <StepLoading />
      ) : (
        <StepButton onClick={onClaim} disabled={disabled} className={className} />
      )}
    </StepContainer>
  );
}

interface Props {
  isArborCampaign?: boolean;
  title: string;
  step: CampaignStep;
  onClaimWelcomeReward: () => void;
  onClaimCampaignReward: () => void;
  isMobileRegistrationRequired?: boolean;
  isLoadingWelcomeReward: boolean;
  isLoadingCampaignReward: boolean;
}

export default function ContentBlock(props: Props): JSX.Element | null {
  const {
    isArborCampaign,
    title,
    step,
    isMobileRegistrationRequired,
    isLoadingWelcomeReward,
    isLoadingCampaignReward,
    onClaimWelcomeReward,
    onClaimCampaignReward,
  } = props;

  const isWelcomeRewardClaimed = step > CampaignStep.CLAIM_WELCOME_REWARD;
  const isCampaignRewardClaimed = step > CampaignStep.CLAIM_CAMPAIGN_REWARD;

  return (
    <Container>
      <Title>{title}</Title>
      {isMobileRegistrationRequired && (
        <ClaimStep
          step={1}
          description="campaign.step-welcome-reward"
          isCompleted={isWelcomeRewardClaimed}
          isLoading={isLoadingWelcomeReward}
          onClaim={onClaimWelcomeReward}
          className={gaClassName('campaign-modal-welcome-reward')}
        />
      )}
      <ClaimStep
        step={isMobileRegistrationRequired ? 2 : 1}
        description={isArborCampaign ? 'campaign.step-campaign-reward-arbor' : 'campaign.step-campaign-reward'}
        isCompleted={isCampaignRewardClaimed}
        isLoading={isLoadingCampaignReward}
        onClaim={onClaimCampaignReward}
        className={gaClassName('campaign-modal-register')}
        disabled={step !== CampaignStep.CLAIM_CAMPAIGN_REWARD}
      />
    </Container>
  );
}
