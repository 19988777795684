import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { buttonLikeShadowCss } from 'theme/css';
import { themeColorChecker } from 'utils/themeUtils';
import Icon from './Icon';

export interface Props extends React.ComponentPropsWithoutRef<'button'> {
  className?: string;
  href?: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  withoutBorder?: boolean;
  buttonSize?: string;
  buttonPadding?: string;
  buttonColor?: keyof DefaultTheme['color'] | string;
  icon?: string;
  iconSize?: string;
  iconColor?: keyof DefaultTheme['color'] | string;
}

interface ButtonWrapperProps
  extends React.ComponentPropsWithoutRef<'button'>,
    Pick<Props, 'variant' | 'withoutBorder' | 'buttonSize' | 'buttonPadding' | 'buttonColor'> {
  className?: string;
}

// TODO: Disabled design is not ready yet
// const getDisabledCss = (color: themeType['color'], variant: Props['variant']): FlattenSimpleInterpolation => {
//   if (!variant) {
//     return css``;
//   }
//   switch (variant) {
//     case 'primary':
//       return css``;
//     case 'text':
//       return css``;
//   }
// };

export const buttonCss = css<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;

  ${({ variant, buttonColor, theme }) => {
    if (buttonColor) {
      return css`
        background-color: ${themeColorChecker(buttonColor) ? theme.color[buttonColor] : buttonColor};
      `;
    }
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${theme.color.primary};
          color: ${theme.color.onPrimary};
        `;
      case 'secondary':
        return css`
          background-color: ${theme.color.secondary};
          color: ${theme.color.onSecondary};
        `;
      default:
        return css``;
    }
  }}

  ${({ buttonSize = '32px', buttonPadding = '8px' }) => css`
    height: ${buttonSize};
    width: ${buttonSize};
    padding: ${buttonPadding};
  `}

  ${({ withoutBorder, theme }) =>
    theme.key === 'default' &&
    !withoutBorder &&
    css`
      border: 1px solid ${theme.color.buttonBorder};
      ${buttonLikeShadowCss}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
    `}
`;

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${buttonCss}
`;

export default function IconButton({
  children,
  type = 'button',
  variant = 'primary',
  disabled,
  buttonSize,
  buttonPadding,
  buttonColor,
  icon,
  iconSize,
  iconColor,
  withoutBorder,
  ...otherProps
}: Props): JSX.Element {
  return (
    <ButtonWrapper
      type={type}
      variant={variant}
      withoutBorder={withoutBorder}
      buttonSize={buttonSize}
      buttonPadding={buttonPadding}
      buttonColor={buttonColor}
      {...otherProps}
      disabled={disabled}
    >
      {children}
      {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
    </ButtonWrapper>
  );
}
