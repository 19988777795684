import { ToastContainer as ReactToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  TOAST_CONTAINER_BOTTOM_SPACING,
  TOAST_CONTAINER_BOTTOM_SPACING_WITH_NAVIGATION_TAB_BAR,
} from 'constants/dimension';
import useMatchNavigationBarPath from 'hooks/useMatchNavigationBarPath';

export interface Props {
  className?: string;
}

export interface StyledToastContainerProps {
  isCurrentPathNavigationBarPath: boolean;
}

const StyledToastContainer = styled(ReactToastContainer)<StyledToastContainerProps>`
  &.Toastify__toast-container {
    bottom: ${({ isCurrentPathNavigationBarPath }) =>
      isCurrentPathNavigationBarPath
        ? `${TOAST_CONTAINER_BOTTOM_SPACING_WITH_NAVIGATION_TAB_BAR}px`
        : `${TOAST_CONTAINER_BOTTOM_SPACING}px`};
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 20px;
  }

  .Toastify__toast {
    border-radius: 8px;
    padding: 14px 20px;
    min-height: 0;
    background-color: ${({ theme }) => theme.color.toastBackground};
    color: ${({ theme }) => theme.color.toastFont};

    @media only screen and (max-width: 480px) {
      margin-bottom: 8px;
    }
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }

  .Toastify__progress-bar {
    background: ${({ theme }) => theme.color.toastProgressBarBackground};
  }
`;

export default function ToastManager(props: Props): JSX.Element {
  const { className } = props;
  const isCurrentPathNavigationBarPath = useMatchNavigationBarPath();
  return (
    <StyledToastContainer
      className={className}
      isCurrentPathNavigationBarPath={isCurrentPathNavigationBarPath}
      position={toast.POSITION.BOTTOM_CENTER}
      closeButton={false}
    />
  );
}
