import styled from 'styled-components';
import { successModalBackground2 } from 'components/images';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  align-items: center;
`;

const SuccessImage = styled.img.attrs({ src: successModalBackground2 })`
  width: 80%;
`;

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 16px;
  align-items: flex-end;
`;

const Field = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.border};
  margin: 24px 0;
`;

interface Props {
  to: string;
  amount: string;
  gasFee?: string;
}

export default function SuccessBlock({ to, amount, gasFee }: Props): JSX.Element {
  return (
    <>
      <SuccessContainer>
        <SuccessImage />
        <LocalizedText category="h2" color="primary" translationKey="transfer.success-title" />
        <LocalizedText category="body1" color="primary" translationKey="transfer.success-description" />
      </SuccessContainer>
      <SummaryContainer>
        <Field>
          <LocalizedText category="body1" color="caption" translationKey="transfer.transfer-to-label" />
          <Text category="body1" color="primary">
            {to}
          </Text>
        </Field>
        <Divider />
        <Field>
          <LocalizedText category="body1" color="caption" translationKey="transfer.amount-label" />
          <Text category="body1" color="primary">
            {amount} $COFFEE
          </Text>
        </Field>
        {gasFee && (
          <LocalizedText category="caption" color="caption" translationKey="transfer.gas-fee" values={{ gasFee }} />
        )}
      </SummaryContainer>
    </>
  );
}
