import { useEffect, useRef, useState } from 'react';
import OTPInput from 'react-otp-input';
import styled from 'styled-components';
import { getCategoryCss } from 'components/Text';

const OTP_TEXT_INPUT_MAX_LENGTH = 6;

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  onOTPSubmit: (otp: string) => boolean | Promise<boolean>;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 10;
`;

const OTPInputField = styled.input`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: none;
  text-align: center;
  ${getCategoryCss('body1')}

  &:focus {
    outline: 1px solid ${({ theme: { color } }) => color.secondary};
  }
`;

export default function OTPTextInputField(props: Props): JSX.Element {
  const { onOTPSubmit, disabled } = props;

  const otpContainerRef = useRef<HTMLDivElement>(null);
  const [otpValue, setOtpValue] = useState('');

  useEffect(() => {
    if (otpValue.length === OTP_TEXT_INPUT_MAX_LENGTH) {
      // setFocusIndex(-1);
      Promise.resolve(onOTPSubmit(otpValue))
        .then((result) => {
          if (!result) {
            setOtpValue('');
          }
        })
        .catch(() => {
          setOtpValue('');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpValue]);

  return (
    <Container ref={otpContainerRef}>
      <OTPInput
        value={otpValue}
        onChange={setOtpValue}
        numInputs={6}
        containerStyle={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
        skipDefaultStyles
        renderInput={(inputProps) => (
          <OTPInputField {...inputProps} disabled={disabled} inputMode="numeric" pattern="[0-9]*" type="text" />
        )}
      />
    </Container>
  );
}
