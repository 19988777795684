import Avatar from 'boring-avatars';

interface Props {
  size?: number;
  random?: boolean;
}

const AVATAR_NAMES = [
  'Eunice Kennedy',
  'Amelia Boynton',
  'Grace Hopper',
  'Jane Addams',
  'Wilma Rudolph',
  'Belva Lockwood',
  'Phillis Wheatley',
  'Susan B',
  'Georgia O',
  'Nallie Bly',
];

const AVATAR_COLORS = ['#1E5663', '#DAB840', '#EAE1DA', '#3B6E79', '#B24E3D'];
const DEFAULT_NAME = Math.floor(Math.random() * AVATAR_NAMES.length);

export function UserAvatar({ size = 20, random }: Props): JSX.Element {
  const name = random ? Math.floor(Math.random() * AVATAR_NAMES.length) : DEFAULT_NAME;
  return <Avatar size={size} variant="beam" name={AVATAR_NAMES[name]} colors={AVATAR_COLORS} />;
}
