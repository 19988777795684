import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import externalBrowserImage from 'assets/images/image_external-browser.svg';
import logoSecondary from 'assets/images/logo_coffee_dao_secondary.svg';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { Modal } from 'components/Modal';
import { toast } from 'utils/toastUtils';
import { BROWSER, checkInApp } from 'utils/userAgentUtils';

const Logo = styled.img.attrs({ src: logoSecondary })`
  height: 36px;
`;

const Top = styled.div`
  width: 100%;
  padding: 40px 24px 24px 24px;
  background-color: ${({ theme }) => theme.color.primary};
`;

const TopContent = styled.div`
  padding-top: 16px;
  display: grid;
  grid-template-columns: 1fr 104px;
  grid-template-rows: min-content min-content;
  row-gap: 8px;
  column-gap: 16px;
`;

const Title = styled(LocalizedText).attrs({ category: 'h3', weight: 'medium', variant: 'secondary' })`
  grid-column: 1;
  grid-row: 1;
`;

const Description = styled(LocalizedText).attrs({ category: 'body1', variant: 'secondary' })`
  grid-column: 1;
  grid-row: 2;
`;

const ExternalBrowserImage = styled.img.attrs({ src: externalBrowserImage })`
  grid-column: 2;
  grid-row: 1 / span 2;
  width: 112px;
`;

const Bottom = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const CopyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
`;

const CopyInstruction = styled(LocalizedText).attrs({ category: 'body1', variant: 'primary' })`
  flex: 1;
`;

const CopyButton = styled(IconButton).attrs({ icon: 'copy', buttonSize: '48px', iconSize: '24px' })``;

export default function InAppWarningManager(): JSX.Element {
  const { t } = useTranslation();
  const [isInAppWarningModalOpen, setIsInAppWarningModalOpen] = useState(false);

  useEffect(() => {
    const inAppChecking = checkInApp();
    const inAppBrowser: BROWSER[] = ['facebook', 'instagram', 'line', 'twitter', 'wechat', 'linkedIn'];
    setIsInAppWarningModalOpen(inAppChecking.isMobile && inAppBrowser.includes(inAppChecking.browser));
  }, []);

  const onClickCopyLink = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    copy(window.location.href);
    toast(t('text-copied.link'));
  };

  return (
    <Modal
      size="small"
      isOpen={isInAppWarningModalOpen}
      isImportantModal
      isDismissible
      onRequestClose={() => setIsInAppWarningModalOpen(false)}
    >
      <Top>
        <Logo />
        <TopContent>
          <Title translationKey="browser-warning.title" />
          <Description translationKey="browser-warning.description" />
          <ExternalBrowserImage />
        </TopContent>
      </Top>
      <Bottom>
        <CopyWrapper>
          <CopyInstruction translationKey="browser-warning.copy-instruction" />
          <CopyButton onClick={onClickCopyLink} />
        </CopyWrapper>
        <Button variant="text">
          <LocalizedText category="body1" translationKey="browser-warning.button-escape" />
        </Button>
      </Bottom>
    </Modal>
  );
}
