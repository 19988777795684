import useSession from './useSession';
import useWeb3 from './useWeb3';

type Return = {
  isAuthenticated: boolean;
};

export function useIsAuthenticated(): Return {
  const { isConnected } = useWeb3();
  const { jwtToken } = useSession();

  const isAuthenticated = !!isConnected && !!jwtToken;

  return {
    isAuthenticated,
  };
}
