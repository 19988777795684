import { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import coffeeDaoIcon from 'assets/images/icon_coffee_token.svg';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { ShopPage } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { CoffeeDaoLogo } from 'components/Logo';
import QrCode from 'components/QrCode';
import Text from 'components/Text';
import { INSTALLED_FROM_LOCAL_STORAGE_KEY, JWT_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import { decodeJwt } from 'utils/jwtUtils';
import { nativeShareOrCopy } from 'utils/userAgentUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${({ theme }) => theme.color.primary};
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled(IconButton).attrs({
  icon: 'back',
  buttonSize: '40px',
  iconSize: '24px',
  buttonColor: 'transparent',
  iconColor: 'primary',
  withoutBorder: true,
})``;

const EmptyButton = styled(IconButton).attrs({
  icon: 'back',
  buttonSize: '40px',
  iconSize: '24px',
  buttonColor: 'transparent',
  iconColor: 'transparent',
  withoutBorder: true,
})``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const AddHomeScreenContainer = styled.div`
  width: 100%;
  margin: 0 -16px;
  background-color: ${({ theme }) => theme.color.infoBackground};
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const AddHomeScreenIcon = styled.div`
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.primary};
  background-image: url(${coffeeDaoIcon});
  background-size: 48px 48px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const AddHomeScreenPlusIcon = styled(Icon).attrs({ icon: 'plus', color: 'secondary' })`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.floral06};
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: -10px;
  right: -10px;
  display: grid;
  place-items: center;
`;

const AddHomeScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AddHomeScreenLabel = styled.span`
  font-style: italic;
`;

const QrCodeContainer = styled.div`
  text-align: center;
`;

const AddressContainer = styled.button`
  background-color: ${({ theme }) => theme.color.interactive};
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  text-align: left;
`;

export default function QRCodePreLogin(): JSX.Element {
  const navigate = useNavigateWithLang();
  const { pathname } = useLocation();

  const decodedSignature = useMemo(() => decodeJwt(localStorage.getItem(JWT_LOCAL_STORAGE_KEY)), []);

  useEffect(() => {
    const onAppInstalled = (): void => {
      localStorage.setItem(INSTALLED_FROM_LOCAL_STORAGE_KEY, pathname);
    };

    window.addEventListener('appinstalled', onAppInstalled);
    return () => {
      window.removeEventListener('appinstalled', onAppInstalled);
    };
  }, [pathname]);

  if (!decodedSignature?.senderAddress) {
    return <Navigate to="/404" />;
  }

  const onClickBack = (): void => {
    navigate(`/`);
  };

  const onClickCopy = (): void => {
    const text = `Here is my CoffeeDAO address: \n ${decodedSignature.senderAddress}`;
    nativeShareOrCopy({ text });
  };

  return (
    <ShopPage noHeader noNavigationBar>
      <Container>
        <Header>
          <BackButton onClick={onClickBack} />
          <CoffeeDaoLogo variant="primary" />
          <EmptyButton />
        </Header>
        <Content>
          <AddHomeScreenContainer>
            <AddHomeScreenIcon>
              <AddHomeScreenPlusIcon />
            </AddHomeScreenIcon>
            <AddHomeScreenContent>
              <LocalizedText category="body1" weight="medium" translationKey="add-home-screen.title" />
              <LocalizedText
                category="caption"
                translationKey="add-home-screen.description"
                components={[<AddHomeScreenLabel />]}
              />
            </AddHomeScreenContent>
          </AddHomeScreenContainer>
          <QrCodeContainer>
            <LocalizedText category="body1" weight="medium" translationKey="add-home-screen.qr-code-label" />
            <QrCode data={decodedSignature.senderAddress} width={240} height={240} />
          </QrCodeContainer>
          <AddressContainer onClick={onClickCopy}>
            <Text category="body2">{decodedSignature.senderAddress}</Text>
            <Icon icon="copy_alt" />
          </AddressContainer>
        </Content>
      </Container>
    </ShopPage>
  );
}
