import styled, { css } from 'styled-components';
import LocalizedText, { TranslationKey } from './LocalizedText';

const TabButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const TabButton = styled.button<{ isSelected: boolean; variant: TabVariant }>`
  padding: 8px 16px;
  border: 1px solid ${({ theme: { color } }) => color.transactionBlockBorder};
  border-radius: 40px;

  ${({ isSelected, theme }) => css`
    background-color: ${isSelected ? theme.color.primary : 'transparent'};
    color: ${isSelected ? theme.color.onPrimary : theme.color.primary};
  `}

  &:disabled {
    opacity: 0.6;
  }
`;

type TabVariant = 'primary' | 'secondary';

interface Props<TValue> {
  tabs: {
    value: TValue;
    label: TranslationKey;
  }[];
  selectedTab: TValue;
  onTabChange: (value: TValue) => void;
  variant?: TabVariant;
  isLoading?: boolean;
  tabClassName?: (value: TValue) => string;
}

export default function TabBar<TValue extends string = string>(props: Props<TValue>): JSX.Element {
  const { tabs, selectedTab, onTabChange, variant = 'primary', isLoading, tabClassName = () => '' } = props;

  const onTabClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onTabChange(event.currentTarget.value as TValue);
  };

  return (
    <TabButtonContainer>
      {tabs.map(({ value, label }) => (
        <TabButton
          key={value}
          value={value}
          onClick={onTabClick}
          isSelected={selectedTab === value}
          disabled={isLoading}
          variant={variant}
          className={tabClassName(value)}
        >
          <LocalizedText category="body2" translationKey={label} />
        </TabButton>
      ))}
    </TabButtonContainer>
  );
}
