import { atom, Getter, WritableAtom } from 'jotai';
import { loadable } from 'jotai/utils';

export type Loadable<Value> =
  | {
      state: 'loading';
    }
  | {
      state: 'hasError';
      error: unknown;
    }
  | {
      state: 'hasData';
      data: Awaited<Value>;
    };

export function loadableAtomWithRefresh<T>(
  fn: (get: Getter) => Promise<T>
): WritableAtom<Loadable<T>, undefined, void> {
  const refreshCounterAtom = atom(0);
  const loadableAtom = loadable(
    atom((get) => {
      get(refreshCounterAtom);
      return fn(get);
    })
  );

  return atom(
    (get) => get(loadableAtom),
    (_, set) => set(refreshCounterAtom, (i) => i + 1)
  );
}
