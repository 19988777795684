import { Adam } from '@adam-vault/adam-sdk';
import { atom } from 'jotai';
import { DEFAULT_CHAIN_INFO } from 'constants/chain';
import { DEFAULT_PROVIDER, web3Atom } from './web3Atom';

export const adamAtom = atom<Adam>((get) => {
  const { signer, provider } = get(web3Atom);

  if (!DEFAULT_CHAIN_INFO) {
    // eslint-disable-next-line no-console
    throw new Error(`Does not support ${process.env.REACT_APP_DEFAULT_NETWORK} network!`);
  }
  const { network, envParam } = DEFAULT_CHAIN_INFO;

  return new Adam(
    envParam.adamContractAddress,
    signer ?? provider ?? DEFAULT_PROVIDER,
    network,
    process.env.REACT_APP_ENV
  );
});
