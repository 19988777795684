import styled from 'styled-components';
import CoffeeToken from 'assets/images/icon_coffee_token.svg';
import CardBackground from 'assets/images/image_card-background.svg';
import LocalizedText, { TranslationProps } from 'components/LocalizedText';
import Text from 'components/Text';
import Card from './Card';

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme: { color } }) => color.secondary};
  color: ${({ theme: { color } }) => color.primary};
  border-radius: 16px;
  padding: 24px;
  text-align: left;
  gap: 4px;
`;

const CoffeeTokenImg = styled.img.attrs({ src: CoffeeToken })`
  width: 32px;
  height: 32px;
`;

const CardBackgroundImg = styled.img.attrs({ src: CardBackground })`
  position: absolute;
  top: 0;
  right: 0;
`;

const DetailContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const DetailDescription = styled(LocalizedText).attrs({ category: 'body2', variant: 'primary' })``;

interface Props extends TranslationProps {
  amount: string;
}

export default function TokenAmountCard(props: Props): JSX.Element {
  const { amount, ...translationProps } = props;
  return (
    <StyledCard>
      <CardBackgroundImg />
      <DetailContainer>
        <CoffeeTokenImg />
        <Text category="h1">{amount}</Text>
      </DetailContainer>
      <DetailDescription {...translationProps} />
    </StyledCard>
  );
}
