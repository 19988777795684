import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dividerImg from 'assets/images/image_landing-page-divider.svg';
import { Page } from 'components/layout';
import { INSTALLED_FROM_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import CoffeeShopsSection from './CoffeeShopsSection';
import FooterDisclaimerSection from './FooterDisclaimerSection';
import FooterLinkSection from './FooterLinkSection';
import LearnMoreSection from './LearnMoreSection';
import SocialMediaSection from './SocialMediaSection';
import WelcomeSection from './WelcomeSection';

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding-bottom: 32px;
`;

const Divider = styled.img.attrs({ src: dividerImg })`
  align-self: center;
  width: 330px;
`;

export interface FormData {
  phone: string;
  countryCode: string;
}

export default function Home(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    const installedFrom = localStorage.getItem(INSTALLED_FROM_LOCAL_STORAGE_KEY);
    if (installedFrom) {
      navigate(installedFrom);
    }
  }, [navigate]);

  return (
    <Page>
      <Sections>
        <WelcomeSection />
        <CoffeeShopsSection />
        <LearnMoreSection />
        <Divider />
        <SocialMediaSection />
        <FooterLinkSection />
        <FooterDisclaimerSection />
      </Sections>
    </Page>
  );
}
