import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { ShopPage } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import TabBar from 'components/TabBar';
import Text from 'components/Text';
import { LanguageKey } from 'constants/languages';
import { URL_ADAM_VAULT, URL_CONTACT_US, URL_PRIVACY_POLICY, URL_TERMS_AND_CONDITIONALS } from 'constants/url';
import useLanguage from 'hooks/useLanguage';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSelfInfo from 'hooks/useSelfInfo';
import useSession from 'hooks/useSession';
import { web3Connector } from 'store/web3Atom';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f4f4f4;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
  align-items: center;
`;

const BackButton = styled(IconButton).attrs({
  icon: 'back',
  buttonSize: '40px',
  iconSize: '24px',
  buttonColor: 'transparent',
  iconColor: 'primary',
  withoutBorder: true,
})``;

const Title = styled(LocalizedText).attrs({ category: 'h2', color: 'primary', weight: 'medium' })`
  line-height: 32px;
  height: 32px;
`;

const FieldGroup = styled.div`
  padding: 24px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.pageBackground};
`;

const FieldDivider = styled.div`
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.color.border};
`;

const Field = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabel = styled(LocalizedText).attrs({ category: 'body1', color: 'onPage' })``;

const Name = styled(Text).attrs({ category: 'body1', color: 'primary', weight: 'medium' })`
  margin-left: auto;
`;

const FieldActionButton = styled(IconButton).attrs({
  icon: 'next',
  buttonSize: '24px',
  iconSize: '24px',
  buttonColor: 'transparent',
  iconColor: 'primary',
  withoutBorder: true,
})``;

const Disclaimer = styled(LocalizedText).attrs({ color: 'primary', category: 'body1' })`
  display: inline-block;
  text-align: center;
`;

const AdamVaultLink = styled.a.attrs({ href: URL_ADAM_VAULT, target: '_blank' })`
  text-decoration: underline;
  color: inherit;

  &:hover {
    opacity: 0.5;
  }
`;

export default function ShopSettings(): JSX.Element {
  const { language, updateRouteLanguage } = useLanguage();
  const { clearSession } = useSession();
  const { selfInfo } = useSelfInfo();
  const navigate = useNavigateWithLang();

  const onClickBack = (): void => {
    navigate(-1);
  };

  const onClickLogout = async (): Promise<void> => {
    await web3Connector.disconnectEOA(true);
    clearSession();
  };

  return (
    <ShopPage noHeader noNavigationBar secondary>
      <Container>
        <Header>
          <BackButton onClick={onClickBack} />
          <Title translationKey="settings.title" />
        </Header>
        <FieldGroup>
          <Field>
            <FieldLabel translationKey="settings.name-label" />
            <Name>{selfInfo?.name}</Name>
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Link to={URL_TERMS_AND_CONDITIONALS}>
            <Field>
              <FieldLabel translationKey="settings.terms-and-conditions-label" />
              <FieldActionButton />
            </Field>
          </Link>
          <FieldDivider />
          <Link to={URL_PRIVACY_POLICY}>
            <Field>
              <FieldLabel translationKey="settings.privacy-policy-label" />
              <FieldActionButton />
            </Field>
          </Link>
          <FieldDivider />
          <Link to={URL_CONTACT_US}>
            <Field>
              <FieldLabel translationKey="settings.contact-us-label" />
              <FieldActionButton />
            </Field>
          </Link>
          <FieldDivider />
          <Field>
            <FieldLabel translationKey="settings.language-label" />
            <TabBar<LanguageKey>
              tabs={[
                { value: 'zh', label: 'language-zh' },
                { value: 'en', label: 'language-en' },
              ]}
              selectedTab={language}
              onTabChange={updateRouteLanguage}
              tabClassName={(tab) => gaClassName(`settings-page-language-${tab === 'zh' ? 'en' : 'zh'}`)}
            />
          </Field>
        </FieldGroup>
        <Button onClick={onClickLogout}>
          <LocalizedText category="body1" translationKey="settings.button-logout" />
        </Button>
        <Disclaimer
          translationKey="home.footer.copy-right"
          components={[<AdamVaultLink className={gaClassName('home-page-adam-vault')} />]}
        />
      </Container>
    </ShopPage>
  );
}
