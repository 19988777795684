import { Meta } from '@adam-vault/adam-sdk-coffeedao';
import { metaAtom } from 'store/metaAtom';
import useLoadableAtom from './useLoadableAtom';

type ReturnType = {
  isLoading: boolean;
  meta: Meta | undefined;
  error: Error | undefined;
};

export default function useMeta(): ReturnType {
  const { isLoading, data: meta, error } = useLoadableAtom(metaAtom);

  return { isLoading, meta, error };
}
