import { useState } from 'react';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { Modal, SmallModal } from 'components/Modal';
import { TRANSFER_TO_PARAM_KEY } from 'constants/searchParams';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import TransferView from './TransferView';

interface Props {
  onCloseModal: () => void;
}

export default function TransferTokenManager({ onCloseModal: onCloseModalCallback }: Props): JSX.Element {
  const { isAuthenticated } = useIsAuthenticated();

  const [isCancelTransferAlertModalOpen, setIsCancelTransferAlertModalOpen] = useState(false);

  const {
    params: [transferAddress],
    clearParams,
    setParams,
  } = useSearchParamKeys(TRANSFER_TO_PARAM_KEY);

  const isTransferModalOpen = transferAddress !== null && isAuthenticated;
  const onCloseModal = (): void => {
    setIsCancelTransferAlertModalOpen(false);
    onCloseModalCallback();
    clearParams();
  };

  const onRequestCloseModal = (): void => {
    setIsCancelTransferAlertModalOpen(true);
  };

  const onCloseCancelTransferAlertModal = (): void => {
    setIsCancelTransferAlertModalOpen(false);
  };

  const onUpdateTransferAddress = (address: string): void => {
    setParams(TRANSFER_TO_PARAM_KEY, address);
  };

  return (
    <>
      <Modal isOpen={isTransferModalOpen}>
        <TransferView
          transferAddress={transferAddress || ''}
          onUpdateTransferAddress={onUpdateTransferAddress}
          onRequestCloseModal={onRequestCloseModal}
          onComplete={onCloseModal}
        />
      </Modal>
      <SmallModal isOpen={isCancelTransferAlertModalOpen} onRequestClose={onCloseCancelTransferAlertModal}>
        <SmallModal.Title color="primary" translationKey="transfer.cancel.title" />
        <SmallModal.Message translationKey="transfer.cancel.description" />
        <SmallModal.Action>
          <Button onClick={onCloseModal}>
            <LocalizedText category="body1" translationKey="transfer.cancel.button-confirm" />
          </Button>
        </SmallModal.Action>
      </SmallModal>
    </>
  );
}
