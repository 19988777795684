import { useAtomValue } from 'jotai';
import { lazy, useEffect } from 'react';

import { Routes, Route, Outlet } from 'react-router-dom';
import { Background, Page } from 'components/layout';
import PATHS from 'constants/path';
import {
  ShopHome,
  Home,
  NoMatch,
  QRCodeRedirect,
  Transactions,
  Wallet,
  QRCodePreLogin,
  ShopTransactions,
  ShopSettings,
  Scanner,
  ShopScanner,
} from 'pages';
import { onOpenModalCountAtom } from 'store/modalAtom';
import { calculateWindowHeight, updateScrollLock } from 'utils/scrollUtils';
import { LangRoute } from './LangRoute';

const Notifications = lazy(() => import('pages/Notifications'));
const Stores = lazy(() => import('pages/Stores'));

export default function RoutesComponent(): JSX.Element {
  const onOpenModalCount = useAtomValue(onOpenModalCountAtom);

  useEffect(() => {
    updateScrollLock(onOpenModalCount > 0);
  }, [onOpenModalCount]);

  useEffect(() => {
    calculateWindowHeight();
  }, []);

  return (
    <Background>
      <Routes>
        <Route path=":lang?" element={<LangRoute />}>
          <Route index element={<Home />} />
          <Route path={PATHS.WALLET} element={<Wallet />} />
          <Route path={PATHS.TRANSACTIONS} element={<Transactions />} />
          <Route path={PATHS.QR_CODE_EARN} element={<QRCodePreLogin />} />
          <Route
            path={PATHS.NOTIFICATIONS}
            element={
              <Page>
                <Notifications />
              </Page>
            }
          />
          <Route
            path={PATHS.STORES}
            element={
              <Page noHeader>
                <Stores />
              </Page>
            }
          />
          <Route path={PATHS.SCANNER} element={<Scanner />} />
          <Route path={PATHS.QR_CODE} element={<QRCodeRedirect />} />
          <Route path={PATHS.SHOP} element={<Outlet />}>
            <Route index element={<ShopHome />} />
            <Route path={PATHS.TRANSACTIONS} element={<ShopTransactions />} />
            <Route path={PATHS.SCANNER} element={<ShopScanner />} />
            <Route path={PATHS.SETTINGS} element={<ShopSettings />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Background>
  );
}
