import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';

export const SectionPadding = '20px';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 ${SectionPadding};
  position: relative;
`;

export const SectionTitle = styled(LocalizedText).attrs({ category: 'h2' })`
  font-family: Homrice;
  color: ${({ theme: { color } }) => color.primary};
`;

export const SectionDescription = styled(LocalizedText).attrs({ category: 'body2' })``;
