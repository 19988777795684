import { useLocation, matchRoutes } from 'react-router-dom';
import useNavigationBarInfos from 'hooks/useNavigationBarInfos';

export default function useMatchNavigationBarPath(): boolean {
  const location = useLocation();

  const navigationTabs = useNavigationBarInfos();

  const isCurrentPathNavigationBarPath = navigationTabs.some(
    (tabInfo) => matchRoutes([{ path: tabInfo.path }], location) !== null
  );

  return isCurrentPathNavigationBarPath;
}
