import styled, { ThemeProvider } from 'styled-components';
import Header from 'components/Header';
import Skeleton from 'components/Skeleton';
import { NAVIGATION_TAB_BAR_HEIGHT } from 'constants/dimension';
import useSelfInfo from 'hooks/useSelfInfo';
import useWeb3 from 'hooks/useWeb3';
import shopTheme from 'theme/shop';
import NavigationTabBar from '../navigation/NavigationTabBar';

const PageContainer = styled.div<{ secondary?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { color }, secondary }) =>
    secondary ? color.pageSecondaryBackground : color.pageBackground};
  color: ${({ theme: { color } }) => color.onPage};
  position: relative;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px calc(${NAVIGATION_TAB_BAR_HEIGHT}px + 32px + var(--sab)) 16px;
`;

const BlockSkeleton = styled(Skeleton).attrs({ height: '40px', width: '160px' })`
  border-radius: 20px;
`;

const CardSkeleton = styled(Skeleton)<{ noBottomLeftBorderRadius?: boolean }>`
  border-radius: ${({ noBottomLeftBorderRadius }) => (noBottomLeftBorderRadius ? '24px 24px 24px 0' : '24px')};
`;

interface Props {
  children: React.ReactNode;
  hasAuthenticationBlocker?: boolean;
  noHeader?: boolean;
  noScanner?: boolean;
  noNavigationBar?: boolean;
  secondary?: boolean;
}

export default function ShopPage(props: Props): JSX.Element {
  const { children, noHeader, noNavigationBar, noScanner, secondary } = props;
  const { isLoading, selfInfo } = useSelfInfo();
  const { isConnected } = useWeb3();

  return (
    <ThemeProvider theme={shopTheme}>
      <PageContainer secondary={secondary}>
        {!noHeader && <Header noScanner={noScanner} />}
        <Main>
          {isLoading || (isConnected && !selfInfo) ? (
            <>
              <CardSkeleton height="218px" width="100%" />
              <CardSkeleton height="104px" width="100%" />
              <BlockSkeleton />
              <CardSkeleton height="84px" width="100%" />
              <CardSkeleton height="84px" width="100%" />
              <CardSkeleton height="84px" width="100%" />
              <CardSkeleton height="84px" width="100%" />
              <CardSkeleton height="84px" width="100%" />
            </>
          ) : (
            children
          )}
        </Main>
        {!noNavigationBar && <NavigationTabBar />}
      </PageContainer>
    </ThemeProvider>
  );
}
