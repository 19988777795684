import { QrCode } from '@adam-vault/adam-sdk-coffeedao';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { decodeQrCode } from 'utils/qrCodeUtils';

type QrCodeAtom = ReturnType<typeof atom<QrCode | undefined>>;

export default function useQrCodeAtom({
  qrCodeAtom,
  qrCodeIdentifier,
  skipError,
}: {
  qrCodeAtom: QrCodeAtom;
  qrCodeIdentifier: string | undefined;
  skipError?: boolean;
}): QrCode | undefined {
  const [qrCode, setQrCode] = useAtom(qrCodeAtom);

  useEffect(() => {
    if (qrCodeIdentifier) {
      decodeQrCode(qrCodeIdentifier, { skipError }).then(setQrCode);
    } else {
      setQrCode(undefined);
    }
  }, [qrCodeIdentifier, setQrCode, skipError]);

  return qrCode;
}
