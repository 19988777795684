import { useAsyncMemo } from '@adam-vault/adam-frontend-shared';
import { TransactionRecord } from '@adam-vault/adam-sdk-coffeedao';
import { subDays } from 'date-fns';
import useAdam from './useAdam';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import { useIsAuthenticated } from './useIsAuthenticated';
import useMeta from './useMeta';

type Return = {
  records: TransactionRecord[];
  reloadRecords: () => void;
  isLoading: boolean;
};

export default function useCoffeeDaoTokenTransactions(): Return {
  const coffeeDaoClient = useCoffeeDaoClient();
  const { isAuthenticated } = useIsAuthenticated();
  const adam = useAdam();
  const { isLoading: isLoadingMeta, meta, error: metaError } = useMeta();

  const [records, { isLoading, refetch }] = useAsyncMemo<TransactionRecord[]>(
    async () => {
      if (!isAuthenticated || isLoadingMeta || !meta || metaError || !coffeeDaoClient) {
        return [];
      }

      try {
        const selfClaim = adam.loadSelfClaim(meta.SELFCLAIM_BUDGET_ADDRESS);

        return await coffeeDaoClient.request(
          selfClaim.transactionRecords(meta.UTIL_TOKEN_ADDRESS, subDays(new Date(), 30), new Date())
        );
      } catch {
        // TODO: error handling

        return [];
      }
    },
    [isAuthenticated, meta],
    []
  );

  return {
    records,
    reloadRecords: refetch,
    isLoading,
  };
}
