import { BigNumber, Transaction } from 'ethers';
import { useCallback, useState } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { toast } from 'utils/toastUtils';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import useCoffeeDaoToken from './useCoffeeDaoToken';

interface Return {
  calculateEstimateGasFee: (transferAddress: string, transferAmountBN: BigNumber) => Promise<BigNumber | undefined>;
  transferToken: (transferAddress: string, transferAmountBN: BigNumber) => Promise<Transaction | undefined>;
  estimatedGasFee: BigNumber | null;
  isTransferring: boolean;
  isCalculating: boolean;
}

export default function useTransferToken(): Return {
  const coffeeDaoClient = useCoffeeDaoClient();
  const { token } = useCoffeeDaoToken();
  const [isTransferring, setIsTransferring] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [estimatedGasFee, setEstimatedGasFee] = useState<BigNumber | null>(null);

  const calculateEstimateGasFee = useCallback(
    async (transferAddress: string, transferAmountBN: BigNumber) => {
      if (!token || !coffeeDaoClient) {
        // TODO: error message
        toast(CustomError[CustomErrorType.GENERIC_ERROR].message);
        return undefined;
      }

      if (!transferAddress || !transferAmountBN || transferAmountBN.isZero()) {
        // TODO: error message
        toast(CustomError[CustomErrorType.SPEND_AMOUNT_ZERO].message);
        return undefined;
      }

      try {
        setIsCalculating(true);
        const gasFee = await coffeeDaoClient.request(
          token.transfer({ to: transferAddress, amount: transferAmountBN }, { customData: { estimateGas: true } })
        );
        setEstimatedGasFee(gasFee);
        return gasFee;
      } catch (err) {
        const { message } = err as Error;
        // eslint-disable-next-line no-console
        console.error(message);
        toast(CustomError[CustomErrorType.SPEND_TOKEN_FAIL].message);
        return undefined;
      } finally {
        setIsCalculating(false);
      }
    },
    [coffeeDaoClient, token]
  );

  const transferToken = useCallback(
    async (transferAddress: string, transferAmountBN: BigNumber) => {
      if (!token || !coffeeDaoClient) {
        // TODO: error messageF
        toast(CustomError[CustomErrorType.GENERIC_ERROR].message);
        return undefined;
      }

      if (!transferAddress || !transferAmountBN || transferAmountBN.isZero()) {
        // TODO: error message
        toast(CustomError[CustomErrorType.SPEND_AMOUNT_ZERO].message);
        return undefined;
      }

      try {
        setIsTransferring(true);
        const transaction = await coffeeDaoClient.request(
          token.transfer({ to: transferAddress, amount: transferAmountBN })
        );
        return transaction;
      } catch (err) {
        const { message } = err as Error;
        // eslint-disable-next-line no-console
        console.error(message);
        toast(CustomError[CustomErrorType.SPEND_TOKEN_FAIL].message);
        return undefined;
      } finally {
        setIsTransferring(false);
      }
    },
    [coffeeDaoClient, token]
  );

  return {
    calculateEstimateGasFee,
    transferToken,
    estimatedGasFee,
    isTransferring,
    isCalculating,
  };
}
