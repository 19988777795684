import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import palette from './palette';
import zIndex from './zIndex';

const shopTheme: DefaultTheme = {
  key: 'shop',
  color: {
    // new
    primary: palette.tealGreen,
    primaryLight: palette.lightTealGreen,
    onPrimary: palette.grey02,
    secondary: palette.floralWhite,
    onSecondary: palette.tealGreen,
    tinted: palette.mustardYellow,
    onTinted: palette.black,

    shop: palette.floral06,
    drinker: palette.tealGreen,

    // layout
    pageBackground: palette.greyWhite,
    pageSecondaryBackground: palette.grey02,
    onPage: palette.black,
    headerBackground: palette.greyWhite,
    navigationBarBackground: palette.greyWhite,
    navigationBarDropShadow: transparentize(0.8, palette.black),
    modalBackground: palette.pureWhite,
    infoBackground: palette.tealGreen01,

    // button
    buttonPrimary: palette.tealGreen,
    buttonSecondary: palette.greyWhite,
    buttonTertiary: palette.pureWhite,
    buttonBorder: palette.black,

    interactive: palette.grey02,
    interactiveFont: palette.normal,
    interactiveVariant: palette.grey,
    caption: palette.grey05,
    border: palette.grey,
    // current
    fontPrimaryLightest: palette.lightestTealGreen,
    modalOverlay: transparentize(0.7, palette.pureBlack),
    modalOverlayDimmed: transparentize(0.3, palette.black),
    modalBackgroundHighlighted: palette.tealGreen,
    error: palette.burntOrange,
    textInputBackground: palette.lightFloralWhite,
    textInputFont: palette.black,
    textInputPlaceHolder: transparentize(0.8, palette.black),
    toastBackground: palette.tealGreen,
    toastFont: palette.floralWhite,
    toastProgressBarBackground: palette.mustardYellow,
    loadingPrimary: palette.tealGreen,
    loadingSecondary: palette.floralWhite,
    otpInput: palette.lightFloralWhite,
    dropdownBackground: palette.lightFloralWhite,
    dropdownItemHoverBackground: palette.floralWhite,
    scannerBackground: transparentize(0.7, palette.black),
    transactionBlockBorder: palette.tealGreen,
    divider: palette.grey,
    remark: transparentize(0.6, palette.black),
    address: transparentize(0.7, palette.black),
    scrollbar: palette.grey,
  },
  palette,
  fontFamily: {
    primary: "'Homrice', Lato",
    secondary: "'made-okine-sans', 'Playfair Display'",
  },
  zIndex: {
    header: zIndex.firstLayer,
    modal: zIndex.secondLayer,
    dropdown: zIndex.eighthLayer,
    importantModal: zIndex.ninthLayer,
  },
  shadow: {
    dropdown: `0px 0px 2px ${palette.black}`,
  },
};

export default shopTheme;
