import { AnimatePresence, motion } from 'framer-motion';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { onOpenModalCountAtom } from 'store/modalAtom';
import { screenBackgroundCss } from 'theme/css';

type ModalSize = 'fullscreen' | 'small';
type ModalAlign = 'center' | 'left';
type ModalVariant = 'default' | 'highlighted';

export interface ModalProps {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  lightenBackground?: boolean;
  variant?: ModalVariant;
  size?: ModalSize;
  align?: ModalAlign;
  isImportantModal?: boolean;
  isDismissible?: boolean;
  onRequestClose?: () => void;
}

const Overlay = styled(motion.div)<{
  $isImportantModal: ModalProps['isImportantModal'];
  $lightenBackground: ModalProps['lightenBackground'];
}>`
  ${screenBackgroundCss}
  position: fixed;
  top: var(--sat);
  bottom: var(--sab);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: ${({ $lightenBackground: lightenBackground, theme: { color } }) =>
    lightenBackground ? color.modalOverlay : color.modalOverlayDimmed};
  z-index: ${({ $isImportantModal: isImportantModal, theme: { zIndex } }) =>
    isImportantModal ? zIndex.importantModal : zIndex.modal};
`;

const BORDER_RADIUS = '24px';

const ModalContent = styled(motion.div)<Pick<ModalProps, 'size' | 'align' | 'variant'>>`
  background-color: ${({ theme: { color } }) => color.modalBackground};
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  width: 100%;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'center')};
  flex: 1;
  position: relative;
  overflow-x: hidden;

  ${({ size }) =>
    size === 'small' &&
    css`
      min-height: 266px;
      margin-top: auto;
      display: flex;
      flex-direction: column;
    `}

  ${({ variant }) =>
    variant === 'highlighted' &&
    css`
      background-color: ${({ theme: { color } }) => color.modalBackgroundHighlighted};
    `}
`;

/**
 *
 * @example
 * import { Modal } from 'components/Modal';
 *
 * <Modal
 *    isOpen={isModalOpen}
 *    onRequestClose={closeModal}>
 *    size="small | fullscreen"
 *    align="center | left"
 *    variant="default | highlighted"
 *   // Modal content
 * </Modal>
 */
export default function Modal({
  className,
  children,
  isOpen,
  lightenBackground = false,
  variant = 'default',
  size,
  align,
  isImportantModal,
  isDismissible,
  onRequestClose,
}: ModalProps): JSX.Element | null {
  const setOnOpenModalCount = useSetAtom(onOpenModalCountAtom);

  useEffect(() => {
    if (isOpen) {
      setOnOpenModalCount((prevOnOpenModalCount) => prevOnOpenModalCount + 1);

      return () => {
        setOnOpenModalCount((prevOnOpenModalCount) => Math.max(0, prevOnOpenModalCount - 1));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          key="modal"
          $isImportantModal={isImportantModal}
          $lightenBackground={lightenBackground}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 0.2 }}
          onClick={isDismissible ? onRequestClose : undefined}
        >
          <ModalContent
            size={size}
            align={align}
            variant={variant}
            variants={{
              hidden: { y: '100%' },
              visible: { y: 0 },
            }}
            transition={{ duration: 0.2 }}
            className={className}
          >
            {children}
          </ModalContent>
        </Overlay>
      )}
    </AnimatePresence>
  );
}
