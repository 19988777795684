import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import { URL_TERMS_AND_CONDITIONALS, URL_PRIVACY_POLICY } from 'constants/url';

const ConfirmationMessage = styled(LocalizedText).attrs({ category: 'caption' })`
  width: 100%;
  text-align: center;
  opacity: 0.4;
`;

const LinkText = styled(Link)`
  text-decoration: underline;
  color: inherit;

  &:hover {
    opacity: 0.5;
  }

  &:visited {
    color: inherit;
  }
`;

export default function DisclaimerSection({ className }: { className?: string }): JSX.Element {
  return (
    <ConfirmationMessage
      className={className}
      translationKey="wallet.connect.disclaimer"
      components={[<LinkText to={URL_TERMS_AND_CONDITIONALS} />, <LinkText to={URL_PRIVACY_POLICY} />]}
    />
  );
}
