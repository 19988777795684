import { CoffeeShop } from '@adam-vault/adam-sdk-coffeedao';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CoffeeShopListModal from 'components/CoffeeShopListModal';
import IconButton from 'components/IconButton';
import { ScannerBackground } from 'components/scanner';
import { ID_QR_CODE_CAMERA_SCANNER_CONTAINER, ID_QR_CODE_IMAGE_SCANNER_CONTAINER } from 'constants/cssSelectors';
import { SPEND_TO_PARAM_KEY } from 'constants/searchParams';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useQrCodeScanner from 'hooks/useQrCodeScanner';
import { gaClassName } from 'utils/gtmEventUtils';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ButtonRowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 20px;
  width: 100%;
  z-index: 10;
`;

const BackButton = styled(IconButton).attrs({
  variant: 'secondary',
  icon: 'back',
  iconSize: '24px',
  buttonSize: '40px',
})``;

const AlbumIconButton = styled(IconButton).attrs({
  variant: 'secondary',
  icon: 'image',
  iconSize: '24px',
  buttonSize: '40px',
})``;

const FileInput = styled.input.attrs({ type: 'file', accept: 'image/*' })`
  display: none;
`;

export default function Scanner(): JSX.Element {
  const [isCoffeeShopListModalOpen, setIsCoffeeShopListModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigateWithLang();

  const { startCamera, stopCamera, scanImage, cameraStatus, QRCodeCameraScanner, QRCodeImageScanner } =
    useQrCodeScanner({
      cameraId: ID_QR_CODE_CAMERA_SCANNER_CONTAINER,
      imageId: ID_QR_CODE_IMAGE_SCANNER_CONTAINER,
    });

  useEffect(() => {
    startCamera();
  }, [startCamera]);

  useEffect(
    () => () => {
      stopCamera();
    },
    [stopCamera]
  );

  const onClickBack = (): void => {
    if (cameraStatus !== 'starting') {
      stopCamera();
      navigate(-1);
    }
  };

  const onClickAlbum = (): void => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleScanQRCodeFromAlbumImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target as HTMLInputElement;
    if (!input.files || input?.files?.length === 0) {
      return;
    }
    scanImage(input.files[0]);
  };

  const onClickScanFail = (): void => {
    setIsCoffeeShopListModalOpen(true);
  };

  const onClickCoffeeShop =
    (shop: CoffeeShop): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      navigate(`/?${SPEND_TO_PARAM_KEY}=${shop.eoaAddress}`);
    };

  return (
    <Container>
      <ScannerBackground onClickScanFail={onClickScanFail} scanFailLabel="scanner.scan-fail-label" />
      <ButtonRowWrapper>
        <BackButton onClick={onClickBack} className={gaClassName('scanner-back')} />
        <AlbumIconButton onClick={onClickAlbum} className={gaClassName('scanner-import')} />
        <FileInput ref={fileInputRef} onChange={handleScanQRCodeFromAlbumImage} />
      </ButtonRowWrapper>
      <QRCodeCameraScanner />
      <QRCodeImageScanner />
      <CoffeeShopListModal
        isOpen={isCoffeeShopListModalOpen}
        onRequestClose={() => setIsCoffeeShopListModalOpen(false)}
        onClickCoffeeShop={onClickCoffeeShop}
        region="hongKong"
      />
    </Container>
  );
}
