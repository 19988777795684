import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  height: 120px;
`;

const EditButton = styled.button`
  flex: 0 0 84px;
  display: grid;
  place-items: center;
  border-radius: 16px;
  background: ${({ theme }) => theme.color.interactive};
  color: ${({ theme }) => theme.color.primary};
  font-size: 60px;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;

  &:disabled {
    opacity: 0.3;
  }
`;

const AmountBlock = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  border-radius: 16px;
  font-size: 60px;
  font-weight: medium;
  border: 1px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.primary};
`;

interface Props {
  amount: string;
  onUpdateAmount: (amount: string) => void;
  balance: string;
  disabled?: boolean;
}

export default function EditAmountBlock({ amount, onUpdateAmount, balance, disabled = false }: Props): JSX.Element {
  const onClickEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const action = e.currentTarget.value;
    switch (action) {
      case 'plus':
        onUpdateAmount((Number(amount) + 0.1).toFixed(1));
        break;
      case 'minus':
        onUpdateAmount((Number(amount) - 0.1).toFixed(1));
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <ActionContainer>
        <EditButton value="minus" onClick={onClickEdit} disabled={amount === '0.1' || disabled}>
          -
        </EditButton>
        <AmountBlock>{amount}</AmountBlock>
        <EditButton value="plus" onClick={onClickEdit} disabled={disabled}>
          +
        </EditButton>
      </ActionContainer>
      <LocalizedText category="caption" color="caption" translationKey="transfer.balance-label" values={{ balance }} />
    </Container>
  );
}
