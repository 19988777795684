import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LocalizedText from 'components/LocalizedText';
import { NAVIGATION_TAB_BAR_HEIGHT, DESKTOP_PADDING_HORIZONTAL_PERCENTAGE, PAGE_MIN_WIDTH } from 'constants/dimension';
import useNavigationBarInfos from 'hooks/useNavigationBarInfos';

const NAVIGATION_TAB_BAR_WIDTH = `${100 - DESKTOP_PADDING_HORIZONTAL_PERCENTAGE * 2}%`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: calc(${NAVIGATION_TAB_BAR_HEIGHT}px + var(--sab));
  background-color: ${({ theme }) => theme.color.navigationBarBackground};
  box-shadow: 0px -8px 12px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: var(--sab);
  border-radius: 24px 24px 0px 0px;

  @media only screen and (min-device-width: 1366px) and (orientation: landscape) {
    width: ${NAVIGATION_TAB_BAR_WIDTH};
    min-width: ${PAGE_MIN_WIDTH}px;
  }
`;

const NavigationTab = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const IconImg = styled.img`
  height: 20px;
`;

const Title = styled(LocalizedText).attrs(() => ({ category: 'body2', variant: 'primary' }))``;

export default function NavigationTabBar(): JSX.Element {
  const navigationTabInfos = useNavigationBarInfos();

  const handleClickTab = (): void => {
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      {navigationTabInfos.map(({ key, iconUrl, title, path, className }) => (
        <NavigationTab
          key={key}
          onClick={handleClickTab}
          to={path}
          target={key === 'shops' ? '_blank' : '_self'}
          className={className}
        >
          <IconImg src={iconUrl} />
          {title && <Title translationKey={title} />}
        </NavigationTab>
      ))}
    </Container>
  );
}
