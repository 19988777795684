import styled, { css } from 'styled-components';
import iconCoin from 'assets/images/icon_coin.svg';
import imageCoffeeDripping from 'assets/images/image_coffee_dripping.png';
import imageCoffeeMaking from 'assets/images/image_coffee_making.png';
import imageCoinWithStars from 'assets/images/image_coin_with_stars.svg';
import Icon from 'components/Icon';
import LocalizedText, { TranslationProps } from 'components/LocalizedText';

interface Props {
  align?: 'center' | 'left';
  image?: 'dripping' | 'making' | 'coin' | 'success';
  className?: string;
}

const ModalCardContainer = styled.div<Pick<Props, 'align' | 'image'>>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'center')};
  text-align: ${({ align }) => align};
  background-color: ${({ theme: { color } }) => color.primary};
  border-radius: 24px;
  padding: 32px 36px;
  margin-bottom: 24px;

  ${({ image }) =>
    Boolean(image) &&
    css`
      margin-top: 100px;
      padding-top: ${image === 'success' ? '32px' : '100px;'};
    `}
`;

const CoffeeMakingImage = styled.img.attrs({ src: imageCoffeeMaking })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -140px;
`;

const CoffeeDrippingImage = styled.img.attrs({ src: imageCoffeeDripping })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -100px;
`;

const CoinImage = styled.img.attrs({ src: iconCoin })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -120px;
`;

const CoinWithStarsImage = styled.img.attrs({ src: imageCoinWithStars })`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -100px;
`;

export default function ModalCard(props: React.PropsWithChildren<Props>): JSX.Element {
  const { align = 'center', image, className, children } = props;

  const getImageElement = (): JSX.Element | null => {
    switch (image) {
      case 'dripping':
        return <CoffeeDrippingImage />;
      case 'making':
        return <CoffeeMakingImage />;
      case 'coin':
        return <CoinImage />;
      case 'success':
        return <CoinWithStarsImage />;
      default:
        return null;
    }
  };

  return (
    <ModalCardContainer align={align} image={image} className={className}>
      {getImageElement()}
      {children}
    </ModalCardContainer>
  );
}

ModalCard.Title = styled(LocalizedText).attrs({ category: 'h1', variant: 'secondary' })``;

ModalCard.Heading = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'h2',
})`
  text-align: inherit;
  white-space: pre-line;
`;

const SuccessTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const SuccessIcon = styled(Icon).attrs(() => ({ icon: 'check', color: 'primary', size: '30px' }))`
  background-color: ${({ theme: { color } }) => color.primaryLight};
  border-radius: 50%;
`;

ModalCard.SuccessTitle = (translationProps: TranslationProps) => (
  <SuccessTitleContainer>
    <ModalCard.Heading {...translationProps} />
    <SuccessIcon />
  </SuccessTitleContainer>
);

ModalCard.Subtitle = styled(LocalizedText).attrs({ category: 'body2' })`
  color: ${({ theme: { color } }) => color.tinted};
  letter-spacing: 5.04px;
  text-transform: uppercase;
`;

ModalCard.Message = styled(LocalizedText).attrs({
  variant: 'secondary',
  category: 'body2',
})`
  text-align: inherit;
  margin-bottom: 32px;
`;

ModalCard.Description = styled(LocalizedText).attrs({ category: 'body2', variant: 'secondary' })`
  text-align: inherit;
  margin-bottom: 24px;
  opacity: 0.4;
`;

ModalCard.Action = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
`;

ModalCard.Footnote = styled(LocalizedText).attrs({ category: 'caption' })`
  padding: 0 32px;
  opacity: 0.4;
  text-align: center;
`;
