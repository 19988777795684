import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// reference: http://localhost:10003/?welcom-reward=true&utm_source=Instagram&utm_medium=CPC&utm_campaign=referral&utm_id=1234
interface UtmParams extends Record<string, string> {
  source: string;
  medium: string;
  campaign: string;
  id: string;
}

const UtmParamsKeys: `utm_${keyof UtmParams}`[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id'];

export default function UTMManager(): null {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (UtmParamsKeys.every((key) => searchParams.has(key))) {
      const [source, medium, campaign, id] = UtmParamsKeys.map((key) => searchParams.get(key) || '');
      window.dataLayer?.push({
        event: 'Event - UTM - Register',
        eventProps: {
          category: 'UTM',
          action: 'Register',
          label: 'utm',
          utm: {
            source,
            medium,
            campaign,
            id,
          },
        },
      });
    }
  }, [location.pathname, location.search]);

  return null;
}
