import styled from 'styled-components';
import PolygonNetworkLogo from 'assets/images/image_polygon.svg';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';
import { DEFAULT_CHAIN_ID } from 'constants/chain';
import { web3Connector } from 'store/web3Atom';

const PolygonNetworkImg = styled.img.attrs({ src: PolygonNetworkLogo })`
  width: 48px;
  height: 48px;
`;

interface Props {
  isOpen: boolean;
  onDisconnect: () => Promise<void>;
}

export default function IncorrectNetworkModal({ isOpen, onDisconnect }: Props): JSX.Element {
  const onClickSwitchNetwork = (): void => {
    web3Connector.switchNetwork(DEFAULT_CHAIN_ID);
  };

  return (
    <SmallModal isOpen={isOpen} isImportantModal>
      <PolygonNetworkImg />
      <SmallModal.Title translationKey="switch-network.title" />
      <SmallModal.Message translationKey="switch-network.description" />
      <SmallModal.Action>
        <Button onClick={onClickSwitchNetwork} variant="secondary">
          <LocalizedText category="body1" weight="medium" translationKey="switch-network.button-switch-network" />
        </Button>
        <Button onClick={onDisconnect} variant="text">
          <LocalizedText category="caption" translationKey="switch-network.button-disconnect" />
        </Button>
      </SmallModal.Action>
    </SmallModal>
  );
}
