import { css } from 'styled-components';
import archFloralWhiteImage from 'assets/decorations/image_arch_floral_white.svg';
import archTealGreenImage from 'assets/decorations/image_arch_teal_green.svg';
import bigStarImage from 'assets/decorations/image_big_star.svg';
import bloomTealGreenBigFilledImage from 'assets/decorations/image_bloom.filled.svg';
import bloomFloralWhiteImage from 'assets/decorations/image_bloom_floral_white.svg';
import bloomTealGreenImage from 'assets/decorations/image_bloom_teal_green.svg';
import bloomTealGreenBigImage from 'assets/decorations/image_bloom_teal_green_big.svg';
import coffeeBubbleImage from 'assets/decorations/image_coffee_bubble.svg';
import coffeeDaoIcon from 'assets/decorations/image_coffeedao_icon.svg';
import coffeeDaoFloralIcon from 'assets/decorations/image_coffeedao_icon_floral.svg';
import coffeeDaoWhiteIcon from 'assets/decorations/image_coffeedao_icon_white.svg';
import coffeeDaoLogo from 'assets/decorations/image_coffeedao_logo.svg';
import coffeeDaoWhiteLogo from 'assets/decorations/image_coffeedao_logo_white.svg';
import coinImage from 'assets/decorations/image_coin.svg';
import doubleStarImage from 'assets/decorations/image_double_star.svg';
import drinkerCardBackgroundPolygon from 'assets/decorations/image_drinker-card-background-polygon.svg';
import drinkerCardBackground from 'assets/decorations/image_drinker-card-background.svg';
import shopCardBackgroundPolygon from 'assets/decorations/image_shop-card-background-polygon.svg';
import shopCardBackground from 'assets/decorations/image_shop-card-background.svg';
import starImage from 'assets/decorations/image_star.svg';
import successModalBackground2 from 'assets/decorations/image_success-modal-background-2.svg';
import successModalBackground from 'assets/decorations/image_success-modal-background.svg';
import successModalIcon from 'assets/decorations/image_success-modal-icon.svg';

export {
  archFloralWhiteImage,
  archTealGreenImage,
  bloomFloralWhiteImage,
  bloomTealGreenImage,
  bloomTealGreenBigImage,
  bloomTealGreenBigFilledImage,
  bigStarImage,
  coffeeBubbleImage,
  coinImage,
  doubleStarImage,
  starImage,
  coffeeDaoIcon,
  coffeeDaoWhiteIcon,
  coffeeDaoLogo,
  coffeeDaoFloralIcon,
  coffeeDaoWhiteLogo,
  successModalBackground,
  successModalBackground2,
  successModalIcon,
  shopCardBackground,
  shopCardBackgroundPolygon,
  drinkerCardBackground,
  drinkerCardBackgroundPolygon,
};

export const pseudoBackgroundCSS = (url: string, width: string, height: string): ReturnType<typeof css> => css`
  width: ${width};
  height: ${height};
  background-image: url(${url});
  background-size: ${width} ${height};
  background-repeat: no-repeat;
`;
