import styled, { css } from 'styled-components';

export const cardCss = css`
  width: 100%;
  border-radius: 24px 24px 24px 0px;
  padding: 24px 16px;
`;

const CardNew = styled.div`
  ${cardCss};
  background-color: ${({ theme: { color } }) => color.interactive};
  color: ${({ theme: { color } }) => color.interactiveFont};
`;

export default CardNew;
