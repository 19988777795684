import jwtDecode from 'jwt-decode';

interface JWT {
  senderAddress: string;
  iat: number;
  exp: number;
}

export function decodeJwt(jwt: string | null | undefined): JWT | undefined {
  if (!jwt) {
    return undefined;
  }
  return jwtDecode<JWT>(jwt);
}

export function checkExp(exp: number | undefined): boolean {
  if (!exp) {
    return true;
  }
  return exp <= Math.floor(Date.now() / 1000);
}

export function isJwtExpired(jwtToken: string | null | undefined): boolean {
  const jwt = decodeJwt(jwtToken);
  return Boolean(jwt?.senderAddress) && checkExp(jwt?.exp);
}
