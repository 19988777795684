import styled from 'styled-components';
import Button from 'components/Button';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';
import { gaClassName } from 'utils/gtmEventUtils';

interface Props {
  isOpen: boolean;
  onRequestClose?: () => void;
  onConfirmToSkipClick?: () => void;
}

const SkipText = styled(LocalizedText)`
  opacity: 0.4;
`;

export default function SkipVerificationModal(props: Props): JSX.Element {
  const { onRequestClose, isOpen, onConfirmToSkipClick } = props;

  return (
    <SmallModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeButtonClassName={gaClassName('claim-modal-cancel-close')}
    >
      <SmallModal.Title translationKey="welcome-reward.skip.title" />
      <SmallModal.Message translationKey="welcome-reward.skip.description" />
      <SmallModal.Action>
        <Button variant="secondary" onClick={onRequestClose} className={gaClassName('claim-modal-cancel-close')}>
          <LocalizedText category="body1" translationKey="welcome-reward.skip.button-cancel" />
        </Button>
        <Button onClick={onConfirmToSkipClick} variant="text" className={gaClassName('claim-modal-confirm-close')}>
          <SkipText category="caption" translationKey="welcome-reward.skip.button-confirm" />
        </Button>
      </SmallModal.Action>
    </SmallModal>
  );
}
