import { SUBSIDY_TRANSACTION_STATUS, SUBSIDY_TRANSACTION_TYPE, User } from '@adam-vault/adam-sdk-coffeedao';
import { atom } from 'jotai';
import { loadable } from 'jotai/utils';
import { loadableAtomWithRefresh } from 'utils/atomUtils';
import { coffeeDaoAtom } from './coffeeDaoAtom';
import { web3Atom } from './web3Atom';

export type SelfInfo = User & { isWelcomeRewardClaiming?: boolean };

export const selfInfoAtom = atom<SelfInfo | null | undefined>(undefined);

export const loadMyselfAtom = loadableAtomWithRefresh(async (get) => {
  const { isConnected } = get(web3Atom);
  const coffeeDao = get(coffeeDaoAtom);

  if (!isConnected || !coffeeDao) {
    return null;
  }

  const selfInfo = await coffeeDao.getMyself();
  return selfInfo;
});

// if user has not claimed welcome reward but mobile is verified,
// check if there is a pending claim transaction
export const isWelcomeRewardClaimingAtom = loadable(
  atom(async (get) => {
    const myselfAtom = get(loadMyselfAtom);
    const coffeeDao = get(coffeeDaoAtom);
    if (
      myselfAtom.state !== 'hasData' ||
      !myselfAtom.data ||
      myselfAtom.data.isWelcomeRewardClaimed ||
      !myselfAtom.data.isMobileVerified ||
      !coffeeDao
    ) {
      return false;
    }

    const { subsidyTransactions } = await coffeeDao.getSubsidyTransactions(99, 0);
    const rewardTransaction = subsidyTransactions.find((tx) => tx.type === SUBSIDY_TRANSACTION_TYPE.REWARD);
    switch (rewardTransaction?.status) {
      case SUBSIDY_TRANSACTION_STATUS.PENDING:
      case SUBSIDY_TRANSACTION_STATUS.COMPLETED:
        return true;
      default:
        return false;
    }
  })
);
