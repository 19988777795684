import { Env } from '@adam-vault/adam-sdk';
import React from 'react';

export default function DebugArea({ children }: React.PropsWithChildren): JSX.Element | null {
  if (process.env.REACT_APP_ENV === Env.PROD) {
    return null;
  }

  return <React.Fragment key={`debug-${Math.random()}`}>{children}</React.Fragment>;
}
