import IconHome from 'assets/images/icon_home.svg';
import IconHomeActive from 'assets/images/icon_home_active.svg';
import IconNotification from 'assets/images/icon_notification.svg';
import IconNotificationActive from 'assets/images/icon_notification_active.svg';
import IconStore from 'assets/images/icon_store.svg';
import IconStoreActive from 'assets/images/icon_store_active.svg';
import IconTransaction from 'assets/images/icon_transaction.svg';
import IconTransactionActive from 'assets/images/icon_transaction_active.svg';
import IconWallet from 'assets/images/icon_wallet.svg';
import IconWalletActive from 'assets/images/icon_wallet_active.svg';
import { TabInfo } from 'types/TabInfo';
import PATHS from './path';

type tabPaths = PATHS.HOME | PATHS.WALLET | PATHS.TRANSACTIONS | PATHS.STORES | PATHS.NOTIFICATIONS;

export const allTabInfos: { [key in tabPaths]: TabInfo } = {
  [PATHS.HOME]: {
    key: 'home',
    defaultIconUrl: IconHome,
    activeIconUrl: IconHomeActive,
    title: 'navigation.home',
    path: PATHS.HOME,
  },
  [PATHS.WALLET]: {
    key: 'wallet',
    defaultIconUrl: IconWallet,
    activeIconUrl: IconWalletActive,
    title: 'navigation.wallet',
    path: PATHS.WALLET,
  },
  [PATHS.TRANSACTIONS]: {
    key: 'transactions',
    defaultIconUrl: IconTransaction,
    activeIconUrl: IconTransactionActive,
    title: 'navigation.transactions',
    path: PATHS.TRANSACTIONS,
  },
  [PATHS.STORES]: {
    key: 'shops',
    defaultIconUrl: IconStore,
    activeIconUrl: IconStoreActive,
    title: 'navigation.shops',
    path: PATHS.STORES,
  },
  [PATHS.NOTIFICATIONS]: {
    key: 'notifications',
    defaultIconUrl: IconNotification,
    activeIconUrl: IconNotificationActive,
    title: 'navigation.notifications',
    path: PATHS.NOTIFICATIONS,
  },
};

export const guestTabInfoList = [
  allTabInfos[PATHS.HOME],
  allTabInfos[PATHS.WALLET],
  allTabInfos[PATHS.TRANSACTIONS],
  allTabInfos[PATHS.STORES],
];

export const coffeeDrinkerTabInfoList = [
  allTabInfos[PATHS.HOME],
  allTabInfos[PATHS.WALLET],
  allTabInfos[PATHS.TRANSACTIONS],
  allTabInfos[PATHS.STORES],
];

export const coffeeShopTabInfoList = [allTabInfos[PATHS.HOME], allTabInfos[PATHS.TRANSACTIONS]];
