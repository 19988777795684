export enum TransactionFilterType {
  ALL = '',
  SPEND = 'spend',
  EARN = 'earn',
  PROCESSING = 'processing',
}

export enum TransactionType {
  WELCOME_REWARD = 'welcomeReward',
  REFERRAL_REWARD = 'referralReward',
  PAYMENT = 'payment',
  CAMPAIGN_GIFT = 'campaignGift',
  REWARD = 'reward',
}
