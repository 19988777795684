import styled from 'styled-components';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import Text from 'components/Text';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.primary};
  width: 100%;
  height: 156px;
  text-align: center;
`;

const EditButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface Props {
  amount: string;
  onEditAmount: () => void;
  estimatedGasFee: string;
  isLoadingEstimatedGasFee: boolean;
}

export default function ConfirmAmountBlock({
  amount,
  onEditAmount,
  estimatedGasFee,
  isLoadingEstimatedGasFee,
}: Props): JSX.Element {
  return (
    <Container>
      <Text category="h1" color="primary">
        {amount} $COFFEE
      </Text>
      {!isLoadingEstimatedGasFee && Boolean(estimatedGasFee) && (
        <LocalizedText
          category="body2"
          color="caption"
          translationKey="transfer.estimated-gas-fee"
          values={{ gasFee: estimatedGasFee }}
        />
      )}
      <EditButton icon="edit" onClick={onEditAmount} buttonColor="transparent" withoutBorder />
    </Container>
  );
}
