export const SPEND_QR_CODE_ID_PARAM_KEY = 'spending';
export const SPEND_TO_PARAM_KEY = 'spend_to';
export const SPEND_CONFIRM_PARAM_KEY = 'confirm';

export const WELCOME_REWARD_PARAM_KEY = 'welcome_reward';
export const REFERRAL_CODE_PARAM_KEY = 'referral_code';

export const CAMPAIGN_PARAM_KEY = 'campaign';

export const TRANSFER_TO_PARAM_KEY = 'transfer_to';

export const QR_CODE_IDENTIFIER_PARAM_KEY = 'qrcode_identifier';

export const TRANSACTION_FILTER_PARAM_KEY = 'transaction_type';
