import LoadingSpinner from 'components/Loading';
import { ModalCard } from 'components/Modal';

export default function WelcomeRewardLoadingBlock(): JSX.Element {
  return (
    <ModalCard image="coin">
      <ModalCard.Heading translationKey="welcome-reward.loading.title" />
      <ModalCard.Message translationKey="welcome-reward.loading.description" />
      <LoadingSpinner isCentered />
    </ModalCard>
  );
}
