import React from 'react';
import { SmallModal } from 'components/Modal';
import { TranslationKey } from 'constants/languages';

interface Props {
  isOpen: boolean;
  title?: TranslationKey;
  description?: TranslationKey;
}

export default function SignatureRequiredModal({
  isOpen,
  title = 'signature-required.title',
  description = 'signature-required.description',
  children,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <SmallModal isOpen={isOpen} isImportantModal>
      <SmallModal.Title translationKey={title} />
      <SmallModal.Message translationKey={description} />
      {children}
    </SmallModal>
  );
}
