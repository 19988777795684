import { useState, useEffect } from 'react';
import { Modal } from 'components/Modal';
import PATHS from 'constants/path';
import { REFERRAL_CODE_PARAM_KEY, WELCOME_REWARD_PARAM_KEY } from 'constants/searchParams';
import { TransactionFilterType } from 'constants/transaction';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSearchParamKeys from 'hooks/useSearchParamKeys';
import useSelfInfo from 'hooks/useSelfInfo';
import { gaClassName } from 'utils/gtmEventUtils';
import AuthenticateBlock from './AuthenticateBlock';
import ClaimHeader from './ClaimHeader';
import { WelcomeRewardStep } from './formStep';
import SkipVerificationModal from './SkipVerificationModal';
import WelcomeRewardContent from './WelcomeRewardContent';

export default function WelcomeRewardManager(): JSX.Element {
  const [isSkipVerificationModalOpen, setIsSkipVerificationModalOpen] = useState(false);
  const [formStep, setFormStep] = useState<WelcomeRewardStep>(WelcomeRewardStep.LOADING);
  const { isLoading: isLoadingSelfInfo, selfInfo } = useSelfInfo();
  const navigate = useNavigateWithLang();
  const { isAuthenticated } = useIsAuthenticated();

  const {
    params: [welcomeRewardModalState],
    clearParams,
  } = useSearchParamKeys(WELCOME_REWARD_PARAM_KEY, REFERRAL_CODE_PARAM_KEY);

  const isWelcomeRewardModalOpen = Boolean(welcomeRewardModalState);
  // const qrCode = useDecodeQRCode(welcomeRewardModalState); // TODO: use this to get the qr code

  const closeWelcomeRewardModal = (): void => {
    clearParams();
  };

  useEffect(() => {
    if (isLoadingSelfInfo || !selfInfo) {
      setFormStep(WelcomeRewardStep.LOADING);
    } else if (selfInfo.isWelcomeRewardClaimed) {
      setFormStep(WelcomeRewardStep.COMPLETE);
    } else if (selfInfo.isWelcomeRewardClaiming) {
      setFormStep(WelcomeRewardStep.CLAIMING_REWARD);
    } else if (selfInfo.isMobileVerified) {
      setFormStep(WelcomeRewardStep.MOBILE_VERIFIED);
    } else {
      setFormStep(WelcomeRewardStep.VERIFY_MOBILE);
    }
  }, [isLoadingSelfInfo, selfInfo]);

  const onRequestClose = (): void => {
    if (formStep === WelcomeRewardStep.VERIFY_MOBILE) {
      setIsSkipVerificationModalOpen(true);
    } else if (formStep === WelcomeRewardStep.COMPLETE && selfInfo && !selfInfo.isWelcomeRewardClaimed) {
      navigate(PATHS.TRANSACTIONS, { state: { defaultTransactionType: TransactionFilterType.PROCESSING } });
    } else {
      closeWelcomeRewardModal();
    }
  };

  const onContinueVerification = (): void => {
    setIsSkipVerificationModalOpen(false);
  };

  const onSkipVerification = (): void => {
    setIsSkipVerificationModalOpen(false);
    closeWelcomeRewardModal();
    if (formStep === WelcomeRewardStep.COMPLETE && selfInfo && !selfInfo.isWelcomeRewardClaimed) {
      navigate(`/${PATHS.TRANSACTIONS}`, { state: { defaultTransactionType: TransactionFilterType.PROCESSING } });
    }
  };

  return (
    <>
      <Modal
        isOpen={isWelcomeRewardModalOpen}
        variant={formStep === WelcomeRewardStep.COMPLETE ? 'highlighted' : 'default'}
      >
        <ClaimHeader
          logo={formStep === WelcomeRewardStep.COMPLETE ? 'secondary' : 'primary'}
          onClose={onRequestClose}
          closeButtonClassName={gaClassName('claim-modal-attempt-close')}
        />
        {isAuthenticated ? (
          <WelcomeRewardContent formStep={formStep} onCloseModal={closeWelcomeRewardModal} />
        ) : (
          <AuthenticateBlock />
        )}
      </Modal>
      <SkipVerificationModal
        isOpen={isSkipVerificationModalOpen}
        onRequestClose={onContinueVerification}
        onConfirmToSkipClick={onSkipVerification}
      />
    </>
  );
}
