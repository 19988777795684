import { WalletType } from '@adam-vault/adam-frontend-shared';
import AppleIcon from 'assets/images/image_logo-apple.svg';
import FacebookIcon from 'assets/images/image_logo-facebook.svg';
import GoogleIcon from 'assets/images/image_logo-google.svg';
import {
  WALLET_TYPE_LOCAL_STORAGE_KEY,
  REDIRECT_ROUTE_LOCAL_STORAGE_KEY,
  LOGIN_PROVIDER_LOCAL_STORAGE_KEY,
} from 'constants/localStorageKey';
import { GTMCustomEvent, sendGTMEvent } from './gtmEventUtils';

export type LoginProvider = (typeof LoginProviders)[number];
export const LoginProviders = ['google', 'facebook', 'apple'] as const;
export const LoginProviderIcons: Record<LoginProvider, string> = {
  google: GoogleIcon,
  facebook: FacebookIcon,
  apple: AppleIcon,
};

export function setWeb3AuthRedirectRoute(): void {
  const redirectRoute = window.location.hash.replace('#', '');
  localStorage.setItem(REDIRECT_ROUTE_LOCAL_STORAGE_KEY, redirectRoute);
}

export function getWeb3AuthRedirectRoute(web3authHashParams: string): string | undefined {
  const redirectRoute = localStorage.getItem(REDIRECT_ROUTE_LOCAL_STORAGE_KEY) || undefined;
  const walletType = localStorage.getItem(WALLET_TYPE_LOCAL_STORAGE_KEY);

  if (redirectRoute) {
    localStorage.removeItem(REDIRECT_ROUTE_LOCAL_STORAGE_KEY);
  }

  if (walletType === WalletType.WEB3AUTH) {
    const hashSearchParams = new URLSearchParams(web3authHashParams);

    // skip reconnecting trial if no open login store found in local storage
    const openloginStore = localStorage.getItem('openlogin_store');
    if (!openloginStore) {
      localStorage.removeItem(WALLET_TYPE_LOCAL_STORAGE_KEY);
      return;
    }
    // continue reconnecting trial and redirect if session found in local storage
    const { sessionId: currentSessionId } = JSON.parse(openloginStore) as { sessionId: string };
    if (redirectRoute && currentSessionId) {
      return redirectRoute;
    }

    if (!/sessionId=.+&sessionNamespace=.*/.test(web3authHashParams)) {
      localStorage.removeItem(WALLET_TYPE_LOCAL_STORAGE_KEY);
      return;
    }

    // continue reconnecting trial if session found in hash
    if (redirectRoute && hashSearchParams.has('sessionId') && hashSearchParams.get('sessionId')) {
      const newSessionId = hashSearchParams.get('sessionId');
      localStorage.setItem('openlogin_store', JSON.stringify({ sessionId: newSessionId }));
      return redirectRoute;
    }

    // skip reconnecting trial if no session found
    localStorage.removeItem(WALLET_TYPE_LOCAL_STORAGE_KEY);
  }
}

export function saveWeb3AuthLoginProvider(loginProvider: string): void {
  sendGTMEvent(GTMCustomEvent.SocialLoginRequested, 'loginProvider', loginProvider);
  localStorage.setItem(LOGIN_PROVIDER_LOCAL_STORAGE_KEY, loginProvider);
}

export function getWeb3AuthLoginProvider(): LoginProvider | null {
  const loginProvider = localStorage.getItem(LOGIN_PROVIDER_LOCAL_STORAGE_KEY);
  return loginProvider as LoginProvider | null;
}

export function sendWeb3AuthLoginProvider(): void {
  const loginProvider = localStorage.getItem(LOGIN_PROVIDER_LOCAL_STORAGE_KEY);
  if (loginProvider) {
    sendGTMEvent(GTMCustomEvent.SocialLoginConnected, 'loginProvider', loginProvider);
    localStorage.removeItem(LOGIN_PROVIDER_LOCAL_STORAGE_KEY);
  }
}
