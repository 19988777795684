import { AmountTextInput, AmountTextInputProps, ControlledRenderProps } from '@adam-vault/adam-frontend-shared';
import { useCallback } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';
import Text from 'components/Text';
import { Token } from 'types/Token';
import InputWrapper from './InputWrapper';

export interface Props<TFieldValues extends FieldValues> extends AmountTextInputProps<TFieldValues>, ErrorMessageProps {
  className?: string;
  token?: Token;
}

const Container = styled.div`
  width: 100%;
`;

const StyledAmountTextInput = styled(AmountTextInput)`
  margin-right: 8px;
  width: 100%;
  height: 22px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};

  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${({ theme: { color } }) => color.textInputFont};

  ::placeholder {
    color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  }
`;

const TokenImg = styled.img`
  margin-right: 8px;
`;

const TokenSymbol = styled(Text)`
  word-break: normal;
  word-wrap: normal;
`;

export default function AmountTextInputField<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>
): JSX.Element {
  const { className, token, errorMessage, value, name, control, ...inputProps } = props;

  const ControlledRender = useCallback(
    ({ field }: ControlledRenderProps<TFieldValues>): JSX.Element => (
      <AmountTextInputField {...inputProps} onChange={field.onChange} onBlur={field.onBlur} value={field.value} />
    ),
    [inputProps]
  );

  if (name && control) {
    return <Controller name={name} control={control} render={ControlledRender} />;
  }

  return (
    <Container className={className}>
      <InputWrapper token={token} hasError={!!errorMessage}>
        {token && <TokenImg src={token.icon} />}
        <StyledAmountTextInput {...inputProps} value={value} />
        {token && <TokenSymbol category="body2">{`$${token.symbol}`}</TokenSymbol>}
      </InputWrapper>
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} contrast />}
    </Container>
  );
}
